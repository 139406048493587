.payment-wrapper {
	position: sticky;
	top: 0;
	padding: 60px 0 0 80px;
	.deadline-coupon-apply,
	.deadline-coupon-wrapper,
	.deadline-coupon-expire {
		@media screen and (max-width: 800px) {
			display: none;
		}
	}
	.desktop-only {
		display: block;
		@media screen and (max-width: 1000px) {
			display: none;
		}
	}

	@media screen and (max-width: 1000px) {
		background: #fcf9f2;
		// padding: 20px;
		padding-bottom: 0;

		border-top: 5px solid rgba(213, 219, 229, 0.5);
	}
	.bottom-bar-payment {
		@media screen and (max-width: 1000px) {
			display: none;
		}
	}
	.mobile-security {
		display: none;
		@media screen and (max-width: 1000px) {
			display: flex;
			// border: none !important;
			// background: none !important;
			gap: 6px;
			margin: 0 !important;
			align-items: center;
			justify-content: center;
			border: 1px solid rgba(245, 200, 114, 0.3) !important;
			background: #f9efde !important;
			margin-bottom: 0;
			position: relative;
			width: calc(100% + 40px);
			left: -20px;
		}
	}
	.rewards-wrapper {
		color: #555;
		font-family: Inter;
		font-size: 16px;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
		margin-bottom: 18px;
		display: flex;
		align-items: center;
		.box {
			display: flex;
			align-items: center;
			justify-content: center;
			cursor: pointer;
			transition: 0.3s linear;
			width: 24px;
			height: 24px;
			flex-shrink: 0;
			border: 2px solid rgba(52, 80, 136, 1);
			margin-right: 12px;
		}
		@media screen and (max-width: 1000px) {
			background: white;
			padding: 12px 0;
			// width: 100%;
			justify-content: center;
			position: relative;
			width: calc(100% + 40px);
			left: -20px;
			margin-bottom: 0;
			overflow: hidden;
		}
	}
	h3 {
		color: #111;
		font-family: Inter;
		font-size: 28px;
		font-style: normal;
		font-weight: 600;
		line-height: normal;
		@media screen and (max-width: 800px) {
			font-size: 24px;
		}
	}
	.terms-condition-wrapper {
		display: flex;
		// flex-direction: column;
		color: var(--text-clr-grey-900);
		font-size: 12px;
		font-style: normal;
		font-weight: 400;
		line-height: 18px;
		gap: 4px;
		align-items: center;
		margin-bottom: 10px;
		.box {
			display: flex;
			align-items: center;
			justify-content: center;
			cursor: pointer;
			transition: 0.3s linear;
			width: 15px;
			height: 15px;
			flex-shrink: 0;
			border: 1px solid rgba(52, 80, 136, 1);
			margin-right: 12px;
		}
		span {
			display: inline-block;
			white-space: pre;
		}
	}
	.security-wrapper {
		color: #555;
		font-family: Inter;
		font-size: 12px;
		font-style: normal;
		font-weight: 400;
		line-height: 150%; /* 18px */
		border-radius: 4px;
		border: 1px solid rgba(245, 200, 114, 0.3);
		background: #f9efde;
		padding: 8px;
		padding-left: 18px;
		border-left: 4px solid #f5c872;
		margin-bottom: 14px;
	}
	.summary-box {
		display: flex;
		flex-direction: column;
		gap: 24px;
		border-radius: 4px;
		border: 1px solid #d5dbe5;
		background: #f9fafb;
		padding: 30px;
		margin-top: 30px;
		margin-bottom: 23px;
		background-color: white;
		box-shadow: 0px 4px 14px 0px rgba(204, 204, 204, 0.25);
		@media screen and (max-width: 1000px) {
			padding: 0px;
			background: none;
			border: 0;
			box-shadow: none;
		}
		.payment-element,
		.payment-element-total {
			display: flex;
			align-items: center;
			justify-content: space-between;
		}
		.payment-element-total {
			border-top: 1px solid #d5dbe5;
			// margin-top: 24px;
			padding-top: 24px;
			.cross-text {
				position: relative;
				border-radius: 10px;
				font-family: Inter;
				font-style: normal;
				font-weight: 400;
				font-size: 14px;
				line-height: 24px;
				display: flex;
				opacity: 0.8;
				-webkit-box-align: center;
				align-items: center;
				color: rgb(0, 0, 0);
				width: fit-content;
				overflow: hidden;
				text-decoration: line-through;
			}
			.payment-title {
				color: #111;
				font-family: Inter;
				font-size: 18px;
				font-style: normal;
				font-weight: 500;
				line-height: normal;
			}
			span {
				display: flex;
				justify-content: flex-end;
				gap: 8px;
				color: #111;
				text-align: right;
				font-family: Inter;
				font-size: 20px;
				font-style: normal;
				font-weight: 700;
				line-height: normal;
			}
		}
	}
	button {
		width: 100%;
		color: #fff;
		font-family: Inter;
		font-size: 16px;
		font-style: normal;
		font-weight: 500;
		line-height: normal;
		border-radius: 4px;
		background: #345088;
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 10px;
		padding: 20px 0;
		&:hover {
			svg {
				animation: shack ease 0.5s;
			}
		}
		&:disabled {
			opacity: 0.5;
		}
	}
}

@keyframes shack {
	0% {
		transform: translateX(0);
	}
	25% {
		transform: translateX(-5px);
	}
	50% {
		transform: translateX(5px);
	}
	75% {
		transform: translateX(-5px);
	}
	100% {
		transform: translateX(0);
	}
}


.ta-commission {
	padding: 10px;
	border-radius: 4px;
	border: 1px solid #b59f32;
	background: #ffffe7;
	border-radius: 4px;
	margin-bottom: 10px;
	color: rgb(160, 136, 0);
	.con-space-between{
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
}
