.home__scroll__header__container {
	.scroll__header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		scroll-behavior: smooth;
		transition: scroll 0.3s ease-in-out;
		@media screen and (max-width: 1280px) {
			padding: 0;
		}

		.scroll__title {
			margin-left: 5px;

			h2 {
				font-size: 30px;
				font-weight: 700;
				line-height: 45px;

				@media screen and (max-width: 700px) {
					font-size: 20px;
					line-height: 45px;
				}

				@media screen and (max-width: 300px) {
					font-size: 14px;
					line-height: 45px;
				}
			}
		}

		.scroll__button {
			z-index: 1;

			.button__one {
				.left__arrow {
					transform: rotate(180deg);
					transition: all 0.3s ease-in-out;
					cursor: pointer;
				}

				.active {
					opacity: 1;

					&:hover {
						transform: rotate(180deg) scaleX(1.2);
					}
				}

				.not__active {
					opacity: 0.3;
				}
			}

			.button__two {
				margin-left: 10px;

				.right__arrow {
					transition: all 0.3s ease-in-out;
					cursor: pointer;
				}

				.active {
					opacity: 1;

					&:hover {
						transform: scaleX(1.2);
					}
				}

				.not__active {
					opacity: 0.3;
				}

				@media screen and (max-width: 300px) {
					margin-left: 6px;
					margin-right: 10px;
				}
			}
		}
	}
}
