@import url(https://fonts.googleapis.com/css?family=Catamaran:100,200,300,regular,500,600,700,800,900);

@import url(https://fonts.googleapis.com/css?family=Inter:100,200,300,regular,500,600,700,800,900,100italic,200italic,300italic,italic,500italic,600italic,700italic,800italic,900italic);

.home__navbar__container {
	position: absolute;
	top: 0;
	width: 100%;
	z-index: 10;
	border: 1px solid rgba(255, 255, 255, 0.1);
	background: #fff;
	.call-us-btn {
		display: none;
		@media screen and (max-width: 800px) {
			display: flex;
			align-items: center;
			justify-content: center;
			gap: 4px;
			svg {
				width: 11px;
				height: 11px;
				path {
					fill: #fff !important;
				}
			}
		}
		color: white;
		mix-blend-mode: difference;
		text-align: center;
		font-family: Catamaran;
		font-size: 14px;
		font-style: normal;
		line-height: 150%; /* 21px */
		text-decoration: none;
		// text-decoration-line: underline;
	}
	@media screen and (max-width: 1250px) {
		z-index: 1;
	}

	.nav__middle__phone {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-bottom: 10px;
		width: 100%;
		padding: 0 16px;

		svg {
			position: absolute;
			right: 30px;
		}

		input {
			display: flex;
			width: inherit;
			padding: 13px 20px;
			border-radius: 100px;
			border: 1px solid #999999;
			caret-color: transparent;
			cursor: pointer;
		}
	}

	.nav__middle__phone {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-bottom: 10px;
		width: 100%;
		padding: 0 16px;

		svg {
			position: absolute;
			right: 30px;
		}

		input {
			display: flex;
			width: inherit;
			padding: 13px 20px;
			border-radius: 100px;
			border: 1px solid #999999;
			caret-color: transparent;
			cursor: pointer;
		}
	}

	.nav__middle__phone {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-bottom: 10px;
		width: 100%;
		padding: 0 16px;

		svg {
			position: absolute;
			right: 30px;
		}

		input {
			display: flex;
			width: inherit;
			padding: 13px 20px;
			border-radius: 100px;
			border: 1px solid #999999;
			caret-color: transparent;
			cursor: pointer;
		}
	}

	.nav__middle {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		max-width: 380px;
		position: relative;
		cursor: pointer;
		transition: opacity 0.5s ease-in-out;
		@media screen and (max-width: 1250px) {
			max-width: 100%;
			padding: 0 16px;
			margin-bottom: 16px;
		}

		.search__nav__button {
			background-color: #2d66a1;
			border-radius: 100%;
			padding: 10px;
			display: flex;
			align-items: center;
			justify-content: center;
			position: absolute;
			right: 10px;
			top: 7px;

			@media screen and (max-width: 1250px) {
				right: 30px;
			}
		}

		input {
			display: flex;
			width: inherit;
			padding: 13px 20px;
			border-radius: 100px;
			border: 1px solid #999999;
			caret-color: transparent;
			cursor: pointer;
		}
	}

	.home__navbar {
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 100%;
		max-width: 1270px;
		margin: 0 auto;
		padding: 25px 16px;

		@media screen and (max-width: 1080px) {
			padding: 25px 16px 0px 16px;
		}

		.nav__right {
			display: flex;
			justify-content: space-between;
			align-items: center;
			gap: 30px;

			.number {
				&:hover {
					text-decoration: underline;
					text-underline-offset: 7px;
				}
			}

			.home_nav {
				&:hover {
					text-decoration: underline;
					text-underline-offset: 7px;
				}
			}

			.nav__link {
				display: flex;
				align-items: center;
				gap: 20px;
				text-decoration: none;
				color: #333333;
				text-align: center;
				font-family: Catamaran;
				font-size: 16px;
				font-style: normal;
				font-weight: 500;
				line-height: 150%;
				cursor: pointer;
				svg {
					fill: #333333;
				}
			}

			.navbar-link-button {
				all: unset;
				display: flex;
				align-items: center;
				justify-content: center;
				padding: 12px 30px;
				// border: 1px solid #2d66a1;
				border-radius: 100px;
				color: #000;
				text-align: right;
				font-family: Catamaran;
				font-size: 14px;
				font-style: normal;
				font-weight: 600;
				line-height: 20px; /* 142.857% */
				cursor: pointer;
				white-space: nowrap;
			}

			.changeButton {
				background-color: #2d66a1;
				color: #fff;
			}

			.navbar__profile__dropdown {
				position: relative;
				width: 34px;
				height: 34px;
				cursor: pointer;

				.profile__user__detail {
					width: 34px;
					height: 34px;

					.user__img {
						width: 34px;
						height: 34px;
						border-radius: 100%;

						img {
							max-width: 100%;
							max-height: 100%;
							border-radius: 100%;
						}
					}
				}
			}
		}
	}
}

.search__animate {
	animation-name: display-hide;
	animation-duration: 0.5s;
	transition: opacity 0.5s ease-in-out;
	opacity: 0;

	@keyframes display-hide {
		from {
			opacity: 1;
			width: 100%;
		}
		to {
			opacity: 0;
			width: 0%;
		}
	}
}

.home__navbar__container__active {
	top: 0;
	z-index: 1000;
}

.home__navbar__overlay {
	position: fixed;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	background: rgba(55, 50, 50, 0.35);
	backdrop-filter: blur(4.5px);
	-webkit-backdrop-filter: blur(4.5px);
	z-index: 100;
}

.new__search__phone__container {
	position: sticky;
	width: 100%;
	z-index: 1000;
	top: 0;
	background-color: #ffffff;
	border-bottom: 1px solid #dddddd;
}

.nav-mid {
	display: flex;
	flex: 1;
	justify-content: space-evenly;
	align-items: center;
	.destination,
	.collections,
	.categories,
	.amenities {
		height: 35px;
		// background: red;
		display: flex;
		justify-content: center;
		align-items: center;
		p {
			color: #000;
			font-family: Catamaran;
			font-size: 16px;
			font-style: normal;
			font-weight: 600;
			line-height: 20px; /* 142.857% */
			cursor: pointer;
			display: flex;
			align-items: center;
			transition: all 0.3s ease-in-out;
		}
	}
	.destination {
		display: flex;
		flex-direction: column;
		position: relative;
		.destination-mapping-wrapper {
			align-items: start;
			position: absolute;
			top: 35px;
			left: -100px;
			padding: 26px 33px;
			border-radius: 16px;
			background: var(--More-Color-White, #fff);
			box-shadow: 0px 24px 32px 4px rgba(167, 174, 186, 0.12);
			animation: slide-up 0.3s ease-in-out;
			transform-origin: center;
			height: auto;
			display: grid;
			grid-template-columns: repeat(5, 1fr);
			ul {
				.destination-mapping-header {
					display: flex;
					flex-direction: column;
					gap: 7px;
					height: min-content;
					float: left;
					flex: 1;
					margin: 10px;
					width: 150px;
					p {
						color: var(--Gray-900, #111b29);
						font-family: Inter;
						font-size: 16px;
						font-style: normal;
						font-weight: 600;
						line-height: 150%; /* 24px */
						cursor: pointer;
					}
					ul {
						display: flex;
						flex-direction: column;
						gap: 7px;
						list-style-type: none;
						li {
							color: var(--Gray-900, #111b29);
							font-family: Inter;
							font-size: 15px;
							font-style: normal;
							font-weight: 400;
							line-height: 150%; /* 24px */
							cursor: pointer;
						}
					}
				}
			}
		}
	}
	.collections {
		display: flex;
		flex-direction: column;
		position: relative;
		.collection-mapping-wrapper {
			align-items: start;
			position: absolute;
			top: 35px;
			left: -100px;
			width: max-content;
			padding: 26px 33px;
			border-radius: 16px;
			background: var(--More-Color-White, #fff);
			box-shadow: 0px 24px 32px 4px rgba(167, 174, 186, 0.12);
			display: grid;
			grid-template-columns: repeat(4, 1fr);
			grid-template-rows: 1fr min-content;
			column-gap: 43px;
			row-gap: 19px;
			animation: slide-up 0.3s ease-in-out;
			transform-origin: center;
			z-index: 1;
			.collection-mapping-header {
				display: flex;
				flex-direction: column;
				gap: 7px;
				p {
					color: var(--Gray-900, #111b29);
					font-family: Inter;
					font-size: 16px;
					font-style: normal;
					font-weight: 600;
					line-height: 150%; /* 24px */
				}
				ul {
					display: flex;
					flex-direction: column;
					gap: 7px;
					list-style-type: none;
					li {
						color: var(--Gray-900, #111b29);
						font-family: Inter;
						font-size: 15px;
						font-style: normal;
						font-weight: 400;
						line-height: 150%; /* 24px */
						cursor: pointer;
					}
				}
			}
		}
	}
	.amenities {
		display: flex;
		flex-direction: column;
		position: relative;
		.amenity-mapping-wrapper {
			align-items: start;
			position: absolute;
			top: 35px;
			left: -100px;
			padding: 10px;
			border-radius: 16px;
			background: var(--More-Color-White, #fff);
			box-shadow: 0px 24px 32px 4px rgba(167, 174, 186, 0.12);
			display: grid;
			grid-template-columns: repeat(2, 1fr);
			grid-template-rows: 1fr min-content;
			column-gap: 43px;
			row-gap: 7px;
			animation: slide-up 0.3s ease-in-out;
			transform-origin: center;
			z-index: 1;
			padding: 26px 33px;
			.amenity-mapping-header {
				p {
					width: max-content;
					color: var(--Gray-900, #111b29);
					font-family: Inter;
					font-size: 15px;
					font-style: normal;
					font-weight: 400;
					line-height: 150%; /* 24px */
					cursor: pointer;
				}
			}
		}
	}

	.categories {
		display: flex;
		flex-direction: column;
		position: relative;
		.category-mapping-wrapper {
			width:max-content;
			align-items: start;
			position: absolute;
			top: 35px;
			left: -100px;
			padding: 10px;
			border-radius: 16px;
			background: var(--More-Color-White, #fff);
			box-shadow: 0px 24px 32px 4px rgba(167, 174, 186, 0.12);
			display: flex;
			flex-direction: column;
			row-gap: 13px;
			animation: slide-up 0.3s ease-in-out;
			transform-origin: center;
			z-index: 1;
			padding: 26px 33px;
			.category-mapping-header {
				display: flex;
				align-items: center;
				gap: 13px;
				cursor: pointer;
				img {
					width: 24px;
					height: 24px;
				}
				.category-mapping-header-text {
					display: flex;
					flex-direction: column;
					h2 {
						color: var(--Gray-900, #111b29);
						font-family: Inter;
						font-size: 16px;
						font-style: normal;
						font-weight: 400;
						line-height: 120%; /* 19.2px */
					}
					p {
						color: #555;
						font-family: Inter;
						font-size: 10px;
						font-style: italic;
						font-weight: 400;
						line-height: 120%; /* 9.6px */
					}
				}
			}
		}
	}
}

@keyframes slide-up {
	0% {
		opacity: 0;
		transform: translateY(20px);
	}
	100% {
		opacity: 1;
		transform: translateY(0px);
	}
}
