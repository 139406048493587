.view__image__container {
	display: flex;
	flex-direction: column;
	padding: 16px;
	width: 100%;
	max-width: 700px;
	background-color: #fff;
	border-radius: 10px;

	@media screen and (max-width: 768px) {
		max-width: 100%;
		position: fixed;
		bottom: 0;
		left: 0;
		right: 0;
		border-radius: 10px 10px 0 0;
	}

	.top__close {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 20px;

		span {
			svg {
				width: 14px;
				height: 14px;
				cursor: pointer;
			}
		}
	}

	.img__container {
		border-radius: 4px;
		width: 100%;
		height: 400px;

		img {
			width: 100%;
			height: 100%;
			border-radius: 4px;
		}
	}
}
