.personal__step__three__container {
	.guest__bottom {
		margin-top: 24px;
		padding-top: 24px;
		// border-top: 1px solid #dddddd;
		display: flex;
		gap: 40px;

		@media screen and (max-width: 768px) {
			flex-direction: column;
			gap: 30px;
		}

		span {
			display: flex;
			gap: 12px;
			cursor: pointer;

			p {
				color: #2d66a1;
				font-size: 16px;
				font-weight: 600;
				line-height: 24px;
				letter-spacing: 0em;
				text-align: left;
				text-decoration: underline;
				text-underline-offset: 4px;

				@media screen and (max-width: 768px) {
					font-size: 14px;
					font-weight: 600;
					line-height: 21px;
				}
			}
		}
	}

	.div__celebrating {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: space-between;
		border: 1px solid #dddddd;
		border-radius: 4px;
		padding: 17px 26px;
		cursor: pointer;
		margin-top: 20px;

		@media screen and (max-width: 768px) {
			padding: 14px 16px;
			font-size: 14px;
			font-weight: 500;
			line-height: 21px;
		}

		p {
			color: #999999;
			font-size: 16px;
			font-weight: 500;
			line-height: 24px;
			letter-spacing: 0em;
			text-align: left;
		}

		.dropdown__container {
			position: absolute;
			display: flex;
			flex-direction: column;
			gap: 4px;
			width: 100%;
			border-radius: 4px;
			padding: 4px;
			background-color: #fff;
			top: 65px;
			left: 0;
			border: 1px solid #dddddd;

			.box {
				padding: 8px 12px;
				border-radius: 4px;
				cursor: pointer;

				&:hover {
					background-color: #f5faff;
				}
			}
		}
	}

	.message__container {
		display: flex;
		flex-direction: column;
		gap: 24px;
		width: 100%;
		margin-top: 20px;

		label {
			color: #999999;
			font-size: 14px;
			font-weight: 500;
			line-height: 21px;
		}

		.hear__about {
			width: 100%;
			display: flex;
			flex-direction: column;
			gap: 6px;
		}
	}

	.request {
		display: flex;
		flex-direction: column;
		gap: 6px;

		textarea {
			width: 100%;
			height: 123px;
			border-radius: 4px;
			border: 1px solid #dddddd;
			padding: 10px 16px;
		}
	}

	.bottom {
		display: flex;
		flex-direction: column;
		gap: 14px;
		margin-bottom: 24px;
		padding-bottom: 24px;
		border-bottom: 1px solid #dddddd;

		h3 {
			color: #333333;
			font-size: 16px;
			font-weight: 500;
			line-height: 24px;

			@media screen and (max-width: 768px) {
				font-size: 16px;
				font-weight: 500;
				line-height: 24px;
			}

			strong {
				color: #666666;
				font-size: 14px;
				font-weight: 400;
				line-height: 21px;
			}
		}

		.travelling__container {
			display: flex;
			gap: 30px;

			@media screen and (max-width: 768px) {
				flex-direction: column;
				gap: 20px;
			}

			.active {
				border: 3px solid #2d66a1;
				border-radius: 40px;
			}

			.travelingWith {
				display: flex;
				align-items: center;
				gap: 14px;
				cursor: pointer;
				padding: 3px 18px 3px 4px;
				transition: all 0.3s ease-in-out;

				.icon__container {
					border-radius: 100%;
					display: flex;
					align-items: center;
					justify-content: center;
					width: max-content;
					border: 1px solid #dddddd;
					width: 30px;
					height: 30px;
				}

				p {
					color: #333333;
					font-size: 12px;
					font-weight: 500;
					line-height: 18px;
				}
			}
		}
	}
}

.guest__details__container {
	display: flex;
	align-items: center;
	gap: 130px;
	margin-bottom: 10px;

	@media screen and (max-width: 768px) {
		gap: 0;
		justify-content: space-between;
	}

	.guest__value,
	.total__guest {
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 10px;
	}

	.total__guest {
		@media screen and (max-width: 768px) {
			display: none;
		}
	}

	.guest__name {
		display: flex;
		align-items: center;
		gap: 30px;
		width: 300px;

		.guest__name__content {
			width: 150px;
			display: flex;
			align-items: center;
			gap: 10px;

			span {
				display: flex;
				flex-direction: column;
				gap: 4px;

				h5 {
					color: #333333;
					font-size: 12px;
					font-weight: 500;
					line-height: 18px;
				}

				p {
					color: #666666;
					font-size: 10px;
					font-weight: 500;
					line-height: 16px;
				}
			}
		}
	}

	.total__guest {
		gap: 5px;
		width: 108px;
		height: 35px;
		background: #edf2ff;
		border-radius: 4px;
		font-weight: 500;
		font-size: 12px;
		line-height: 150%;
		color: var(--text-clr-grey-900);
		text-align: center;
	}
}
