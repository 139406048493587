.featured__scroll-menu {
	width: 100%;
	margin: 0 auto 0 auto;
	max-width: 1260px;
	padding: 20px 0px;

	@media screen and (max-width: 1300px) {
		width: 100%;
		padding: 20px 30px;
		margin-top: 20px;
	}

	@media screen and (max-width: 760px) {
		padding: 0 16px;
	}

	.featured__scroll-menu-container {
		display: flex;
		flex-direction: column;
		gap: 1rem;

		.featured__scroll-header {
			display: flex;
			justify-content: space-between;
			align-items: center;

			@media screen and (max-width: 1280px) {
				padding: 0;
			}

			.featured__scroll-title {
				margin-left: 5px;

				h2 {
					font-size: 30px;
					font-weight: 700;
					line-height: 45px;

					@media screen and (max-width: 700px) {
						font-size: 20px;
						line-height: 45px;
					}

					@media screen and (max-width: 300px) {
						font-size: 14px;
						line-height: 45px;
					}
				}
			}

			.featured__scroll-button {
				z-index: 1;

				.button-two {
					margin-left: 10px;

					@media screen and (max-width: 300px) {
						margin-left: 6px;
						margin-right: 10px;
					}
				}
			}
		}

		.featured__scroll-header-title {
			display: flex;
			align-items: center;
			list-style: none;
			gap: 1rem;
			justify-content: space-between;
			max-width: 880px;
			// margin: 0 auto;
			width: 100%;
			overflow-x: scroll;
			scroll-behavior: smooth;
			border-bottom: 1px #dddddd solid;
			margin-bottom: 20px;

			&::-webkit-scrollbar {
				background: transparent;
				/* make scrollbar transparent */
				-webkit-appearance: none;
				width: 0;
				height: 0;
			}

			span {
				display: flex;
				align-items: center;
				height: 40px;
				padding: 0.2rem 0.8rem;
				cursor: pointer;

				p {
					color: var(--bg-clr-grey-600);
					font-size: 16px;
					line-height: 24px;
					font-weight: 600;
					white-space: nowrap;

					@media screen and (max-width: 700px) {
						font-size: 12px;
						line-height: 18px;
					}

					@media screen and (max-width: 300px) {
						font-size: 10px;
						line-height: 16px;
					}
				}
			}

			.active {
				border-bottom: 3px #2368a2 solid;

				.active-tab {
					color: var(--clr-primary);
				}
			}
		}
	}
}
