@import url(https://fonts.googleapis.com/css?family=Inter:100,200,300,regular,500,600,700,800,900);
.footer-wrapper {
	width: 100%;
	background: #080808;
	color: white;
	.footer-container {
		width: 100%;
		max-width: 1280px;
		margin: 0 auto;
		.footer-content {
			display: flex;
			flex: 1;
			gap: 5rem;
			align-items: flex-start;
			padding: 4.38rem 0;
			@media screen and (max-width: 768px) {
				flex-direction: column;
				justify-content: center;
				align-items: center;
				padding: 4.38rem 1rem;
			}
			.footer-column1 {
				display: flex;
				flex-direction: column;
				gap: 2.42rem;
				@media screen and (max-width: 768px) {
					flex-direction: row;
					align-items: center;
				}
				.footer-socials {
					display: flex;
					flex-direction: column;
					gap: 1.25rem;
					.footer-socials-text {
						padding-top: 1.87rem;
						border-top: 1.5px solid #404040;
						@media screen and (max-width: 768px) {
							border: none;
						}
						a {
							color: rgba(255, 255, 255, 0.6);
							font-family: Inter;
							font-size: 0.75rem;
							font-style: normal;
							font-weight: 500;
							line-height: 150%; /* 1.125rem */
							text-align: center;
							text-decoration: none;
							cursor: pointer;
						}
					}
					.footer-socials-icons {
						display: flex;
						color: white;
						gap: 1rem;
					}
				}
			}
			.footer-column2 {
				display: flex;
				justify-content: space-evenly;
				gap: 2.25rem;
				flex: 1;
				@media screen and (max-width: 768px) {
					display: grid;
					grid-template-columns: repeat(2, 1fr);
				}
				.footer-sub-column1 {
					display: flex;
					flex-direction: column;
					gap: 30px;
					.footer-sub-column1-details {
						display: flex;
						flex-direction: column;
						gap: 1.5rem;
						> p {
							width: max-content;
							color: var(--newui-2021-black-grays-white, #fff);
							font-family: Inter;
							font-size: 0.9375rem;
							font-style: normal;
							font-weight: 500;
							line-height: 150%; /* 1.40625rem */
							letter-spacing: 0.0375rem;
							text-transform: uppercase;
							@media screen and (max-width: 768px) {
								font-size: 0.7rem;
							}
						}
						.footer-sub-column1-details-links {
							display: flex;
							flex-direction: column;
							gap: 1rem;
							a {
								text-decoration: none;
								p {
									color: rgba(255, 255, 255, 0.6);
									font-family: Inter;
									font-size: 0.9375rem;
									font-style: normal;
									font-weight: 400;
									line-height: 150%; /* 1.40625rem */
									@media screen and (max-width: 768px) {
										font-size: 0.7rem;
									}
								}
							}
						}
					}
				}
			}
		}
	}
}
