.personal-details-wrapper {
	border-radius: 4px;
	border: 1px solid #d5dbe5;
	background: #f9fafb;
	padding: 24px 20px;
	gap: 20px;
	display: flex;
	flex-direction: column;
	.auto-gst {
		display: flex;
		// margin-left: auto;
		align-items: center;
		gap: 10px;
		.toggle_button_container .toggle_button {
			top: 3px;
			.toggler:after {
				bottom: 2.5px;
			}
		}
	}
	.gst-wrapper {
		display: flex;
		flex-direction: row !important;
		align-items: center;
		gap: 6px !important;
		span {
			color: #555;
			font-family: Inter;
			font-size: 12px;
			font-style: normal;
			font-weight: 400;
			line-height: normal;
		}
		.box {
			display: flex;
			align-items: center;
			justify-content: center;
			cursor: pointer;
			transition: 0.3s linear;
			width: 15px;
			height: 15px;
			flex-shrink: 0;
			border: 1px solid rgba(52, 80, 136, 1);
			margin-right: 12px;
		}
	}
	.details-container {
		display: flex;
		flex-direction: column;
		gap: 6px;
		flex: 0.5;
		span {
			color: #555;
			font-family: Inter;
			font-size: 12px;
			font-style: normal;
			font-weight: 400;
			line-height: normal;
		}
		b {
			color: #111;
			font-family: Inter;
			font-size: 16px;
			font-style: normal;
			font-weight: 500;
			line-height: normal;
		}
	}
	.veify-otp-wrapper {
		display: flex;
		flex-direction: column;
		p {
			color: #333;
			font-family: Inter;
			font-size: 10px;
			font-style: normal;
			font-weight: 400;
			line-height: 150%; /* 15px */
			background: #f8f0e1;
			padding: 8px 12px;
		}
		.otp-wrapper {
			display: flex;
			margin-top: 10px;
			button {
				border-radius: 4px;
				background: #345088;
				color: #fff;
				font-family: Inter;
				font-size: 14px;
				font-style: normal;
				font-weight: 500;
				line-height: normal;
				padding: 16px 0;
				flex: 0.5;
				display: block;
				margin-left: auto;
				border: 0;
				outline: none;
			}
			input {
				border-radius: 4px;
				border: 1px solid #dfe3eb;
				background: #fff;
				color: #111;
				font-family: Inter;
				font-size: 14px;
				font-style: normal;
				font-weight: 500;
				line-height: normal;
				width: 52px !important;
				height: 49px;
				display: flex;
				align-items: center;
				justify-content: center;
			}
		}
	}
	.personsal-details-title {
		color: #111;
		font-family: Inter;
		font-size: 16px;
		font-style: normal;
		font-weight: 600;
		line-height: normal;
	}
	.mob-verified {
		color: #14a53c;
		font-family: Inter;
		font-size: 12px;
		font-style: normal;
		font-weight: 700;
		line-height: normal;
		letter-spacing: 0.6px;
		text-transform: uppercase;
		flex: 1;
		display: flex;
		align-items: center;
		margin-left: 24px;
		margin-top: 21px;
		gap: 6px;
		@media screen and (max-width: 1000px) {
			margin: 0;
		}
	}
	.personal-row {
		display: flex;
		gap: 2px;
		@media screen and (max-width: 1000px) {
			flex-direction: column;
			gap: 20px;
		}
		button {
			flex: 1;
			padding: 16px 0;
			height: fit-content;
			align-self: flex-end;
			color: #fff;
			font-family: Inter;
			font-size: 14px;
			font-style: normal;
			font-weight: 500;
			line-height: normal;
			border-radius: 4px;
			background: #345088;
			border: none;
			outline: none;
		}
	}
	.personal-input {
		display: flex;
		flex-direction: column;
		gap: 6px;
		flex: 1;
		span {
			color: #888;
			font-family: Inter;
			font-size: 12px;
			font-style: normal;
			font-weight: 500;
			line-height: normal;
		}
		input {
			color: #111;
			font-family: Inter;
			font-size: 14px;
			font-style: normal;
			font-weight: 500;
			line-height: normal;
			border-radius: 4px;
			border: 1px solid #dfe3eb;
			background: #fff;
			padding: 16px 20px;
		}
	}
	.gst-submit-btn {
		flex: 0.5 !important;
		&:disabled {
			opacity: 0.5;
		}
		@media screen and (max-width: 1000px) {
			width: 100%;
		}
	}
}
