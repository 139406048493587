.phoneLoadState {
	display: flex;
	align-items: center;
	justify-content: center;

	.phoneLoad__state {
		width: 100vw;
		max-width: 470px;
		height: 400px;
		background-color: var(--bg-primary);
		z-index: 1000;
		border-radius: 10px;
		padding: 5px;

		@media screen and (max-width: 500px) {
			background-color: var(--bg-white);
		}

		.close-icon {
			display: flex;
			padding: 10px;
			width: 100%;
			justify-content: flex-end;

			span {
				width: 20px;
				cursor: pointer;
			}
		}

		.phoneLoad__state-container {
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;
			gap: 2rem;
			margin-top: 55px;

			@media screen and (max-width: 500px) {
				margin-top: 100px;
				background-color: var(--bg-white);
			}

			.phoneLoad__state-text {
				text-align: center;
				height: 100px;
				width: 80%;

				p {
					color: var(--text-clr-grey-900);
					color: #fff;

					font-size: 24px;
					font-style: normal;
					font-weight: 700;
					line-height: 36px;
					text-align: center;

					@media screen and (max-width: 500px) {
						color: var(--text-clr-grey-900);
					}
				}
			}
		}
	}
}