.bottom__container__wrapper {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	background-color: #fff;
	width: 100%;
	max-width: 1280px;
	margin: 0 auto;
	height: 100%;
	z-index: 4;

	@media screen and (max-width: 1090px) {
		flex-direction: column;
	}

	.bottom__content__container {
		display: flex;

		@media screen and (max-width: 1090px) {
			flex-direction: column;
		}
	}

	.bottom__card__wrapper {
		display: flex;
		flex-direction: column;
		background: #00306b;
		padding-bottom: 20px;

		.social__icons {
			display: flex;
			justify-content: center;
			gap: 30px;

			span {
				cursor: pointer;
			}
		}

		h4 {
			text-align: center;
		}
	}

	.bottom__card__container {
		display: flex;
		gap: 12px;
		padding: 24px 0 48px 24px;
		overflow-x: scroll;

		.bottom__card {
			display: flex;
			flex-direction: column;
			gap: 8px;
			border-radius: 10px;
			background: rgba(255, 255, 255, 0.2);
			min-width: 208px;
			padding: 12px;

			.top__heading {
				display: flex;
				gap: 10px;

				.icon__container {
					min-width: 17px;
					height: 17px;
					border-radius: 100%;
					background-color: #fff;
					display: flex;
					align-items: center;
					justify-content: center;

					svg {
						width: 12px;
						height: 12px;
					}
				}
				h4 {
					color: #fff;
					font-size: 11.455px;
					font-style: normal;
					font-weight: 600;
					line-height: 150%;
				}
			}
			.bottom__subtitle {
				color: #fff;
				font-size: 10.023px;
				font-style: normal;
				font-weight: 500;
				line-height: 150%;

				a {
					text-decoration: none;
					color: #ffffff;
					font-weight: 500;
					line-height: 21px;
					text-decoration: underline;
					text-underline-offset: 3px;
					margin-inline: 3px;
				}
			}
		}
	}

	.left__bottom__content {
		width: 38%;

		@media screen and (max-width: 1090px) {
			width: 100%;
		}
	}

	.right__bottom__content {
		width: 62%;
		margin: 0 0 40px 100px;

		@media screen and (max-width: 1090px) {
			margin: 0 !important;
			width: auto;
		}
	}

	.right__center {
		margin: 60px auto;
		max-width: 600px;

		@media screen and (max-width: 1090px) {
			margin: 0 !important;
			margin: 40px auto 60px auto !important;
			width: 100%;
		}
	}
}
