.emailSent {
	display: flex;
	align-items: center;
	justify-content: center;

	.emailSent-container {
		max-width: 450px;
		width: 100vw;
		z-index: 1000;
		background-color: var(--bg-white);
		border-radius: 10px;
		height: 300px;

		@media screen and (max-width: 500px) {
			height: 100vh;
			padding: 40px 16px;
		}

		.emailSent__close-icon {
			display: flex;
			width: 100%;
			justify-content: flex-end;
			padding: 20px 20px 20px 20px;

			span {
				cursor: pointer;
			}
		}

		.emailSent__sub-container {
			display: flex;
			width: 410px;
			margin: 0 auto;
			flex-direction: column;
			padding: 15px 10px 5px 15px;
			border-radius: 4px;
			background-color: #edf2ff;

			@media screen and (max-width: 500px) {
				width: 100%;
			}

			.emailSent__title {
				display: flex;
				gap: 0.7rem;
				margin-bottom: 15px;

				.emailSent__subject {
					width: 100%;

					.emailSent__subject-title {
						font-size: 24px;
						font-weight: 600;
						color: var(--text-clr-grey-900);
						margin-bottom: 10px;
					}

					.emailSent__subject-subtitle {
						font-size: 12px;
						width: 100%;
					}
				}
			}
		}
	}
}