.filter__category__wrapper {
	display: flex;
	flex-direction: column;
	gap: 16px;
	justify-content: space-between;

	.filter-item {
		display: flex;
		align-items: center;
		justify-content: space-between;

		p {
			color: #666666;
			font-size: 14px;
			font-weight: 600;
			line-height: 21px;
			white-space: nowrap;
		}

		input {
			-webkit-appearance: none;
			width: 20px;
			height: 20px;
			border: 2px solid var(--brd-clr-grey-400);
			border-radius: 2px;
			vertical-align: sub;
			left: 0;
			outline: none;
			transition: all 0.2s;
			cursor: pointer;

			&:checked {
				background-color: var(--bg-primary);
				border: none;
				background-image: url("../../../../../../assets/icons/svg/white-tick.svg");
				background-size: 16px;
				background-repeat: no-repeat;
				background-position: center;
			}
		}
	}
}
