@import url(https://fonts.googleapis.com/css?family=Inter:100,200,300,regular,500,600,700,800,900);
.addon-details-wrapper {
	width: 100%;
	height: 100%;
	border-radius: 0.625rem 0.625rem 0 0;
	border: 1px solid #ddd;
	background: #fff;
	position: relative;
	@media screen and (max-width: 800px) {
		top: -50px;
		height: auto;
		left: 0;
		// width: 100vw;
	}
	.addon-details-content {
		width: 100%;
		height: 100%;
		// padding: 10px;
		@media screen and (max-width: 800px) {
			height: auto;
		}
		.adddon-gallery-slide {
			width: 100%;
			position: absolute;
			display: flex;
			justify-content: center;
			top: 50%;
			right: 0px;
			gap: 50vw;
			@media screen and (max-width: 800px) {
				top: -10%;
			}
			span {
				width: 1rem;
				height: 1rem;
				cursor: pointer;
				svg {
					width: 2rem;
					height: 2rem;
					background: white;
					border-radius: 100%;
					padding: 5px;
				}
			}
		}
		.addon-gallery-details {
			display: flex;
			width: 36.25rem;
			height: 28.875rem;
			@media screen and (max-width: 800px) {
				width: 90vw;
				height: auto;
			}
			.gallery-slide-img {
				width: 36.25rem;
				height: 28.875rem;
				display: flex;
				flex-direction: column;
				@media screen and (max-width: 800px) {
					width: 90vw;
					height: 30rem;
				}

				&.gallery-slide-img-hidden {
					display: none;
				}
				img {
					width: 100%;
					height: 123px;
					border-top-right-radius: 0.625rem;
					border-top-left-radius: 0.625rem;
					object-fit: cover;
				}
				.addon-gallery-price {
					width: 100%;
					display: flex;
					justify-content: space-between;
					padding: 1.5rem 2.5rem 1.25rem 2.5rem;
					align-items: center;
					p:nth-child(1) {
						color: var(--newui-2021-black-grays-dark-text, #333);
						font-family: Inter;
						font-size: 1.25rem;
						font-style: normal;
						font-weight: 600;
						line-height: 150%; /* 1.875rem */
						@media screen and (max-width: 800px) {
							font-size: 0.75rem;
						}
					}
					p:nth-child(2) {
						color: var(--newui-2021-black-grays-dark-text, #333);
						font-family: Inter;
						font-size: 0.875rem;
						font-style: normal;
						font-weight: 600;
						line-height: 150%; /* 1.3125rem */
						strong {
							color: #666;
							font-family: Inter;
							font-size: 0.75rem;
							font-style: normal;
							font-weight: 500;
							line-height: 150%;
						}
					}
				}
				.addon-gallery-description {
					padding: 0 2.5rem 1.5rem 2.5rem;
					@media screen and (max-width: 800px) {
						padding: 0 2.5rem 0.5rem 2.5rem;
					}
					p {
						color: #333;
						font-family: Inter;
						font-size: 0.75rem;
						font-style: normal;
						font-weight: 500;
						line-height: 180%; /* 1.35rem */
					}
				}
				.addon-gallery-notes {
					padding: 1rem 2.5rem 1.5rem 2.5rem;
					@media screen and (max-width: 800px) {
						// padding: 1rem 2.5rem 0.5rem 2.5rem;
						background: white;
					}
					p {
						color: #333;
						font-family: Inter;
						font-size: 0.7rem;
						font-style: normal;
						font-weight: 500;
						line-height: 180%;
						display: flex;
						gap: 10px;
						align-items: center;
						svg {
							width: 1.5rem;
						}
					}
				}
				.addon-galler-btn-rack {
					display: flex;
					gap: 1rem;
					border-bottom-left-radius: 0.625rem;
					border-bottom-right-radius: 0.625rem;
					border-top: 1px solid #ddd;
					background: #fff;
					padding: 1.25rem 2.5rem;
					button:nth-child(1) {
						display: flex;
						width: 8.6875rem;
						height: 3rem;
						padding: 0.6875rem 0.9375rem;
						justify-content: center;
						align-items: center;
						gap: 0.375rem;
						border-radius: 6.25rem;
						border: 2px solid var(--newui-2021-black-grays-light-gray, #ddd);
						background: #fff;
						color: var(--newui-2021-black-grays-dark-text, #333);
						font-family: Inter;
						font-size: 0.875rem;
						font-style: normal;
						font-weight: 600;
						line-height: 150%; /* 1.3125rem */
						outline: none;
					}
					button:nth-child(2) {
						width: 21.5625rem;
						height: 3rem;
						flex-shrink: 0;
						border-radius: 6.25rem;
						background: #2d66a1;
						color: #fff;
						text-align: center;
						font-family: Inter;
						font-size: 0.875rem;
						font-style: normal;
						font-weight: 600;
						line-height: 150%; /* 1.3125rem */
						outline: none;
						border: none;
						display: flex;
						justify-content: center;
						align-items: center;
						&:disabled {
							opacity: 0.8;
							cursor: not-allowed;
						}
						@media screen and (max-width: 800px) {
							width: 8.6875rem;
						}
					}
				}
			}
		}
	}
}
