.FinishPhoneSignup {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	.react-tel-input {
		.form-control {
			width: -webkit-fill-available;
			height: 50px;
		}
	}

	.form__input {
		display: flex;
		flex-direction: column;
		justify-content: center;
		gap: 0.8rem;
		margin-bottom: 1.2rem;

		// @media screen and (max-width: 500px) {
		// 	justify-content: flex-start;
		// }

		.otp-box {
			display: flex;
			justify-content: flex-start;
			gap: 1rem;

			.otp-field {
				min-width: 60px;
				height: 48px;
				border-radius: 4px;
				outline: none;
				border: none;
				border: 1px solid var(--brd-clr-grey-400);

				font-weight: 400;
				font-size: 20px;
				text-align: center;

				&:focus-within {
					border: 1px solid var(--brd-clr-primary);
				}
			}
		}

		// .input__input {
		// 	input {
		// 		border-radius: 4px;
		// 		border: 1px #dddddd solid;
		// 		background-color: var(--bg-white);
		// 	}
		// }
	}

	.finishPhone__container {
		position: relative;
		max-width: 470px;
		width: 100vw;
		z-index: 1000;
		background-color: var(--bg-white);
		border-radius: 10px;
		padding: 20px;

		@media screen and (max-width: 500px) {
			margin-top: 20px;
			height: auto;
			max-height: 90vh;
			padding: 25px 16px;
		}

		.finishPhone__close-icon {
			display: flex;
			width: 100%;
			justify-content: space-between;
			align-items: center;

			.finishPhone__header-logo {
				width: 100%;
				display: flex;
				justify-content: center;
				align-items: center;
			}

			span {
				width: max-content;
			}
		}

		.finishPhone__sub-container {
			position: relative;
			display: flex;
			flex-direction: column;
			margin-top: 20px;
			margin-bottom: 20px;
			padding: 20px 20px 30px 20px;

			@media screen and (max-width: 500px) {
				padding: 0 0;
				margin: 0 0;
				height: 30vh;
			}

			div {
				.finishPhone__header {
					display: flex;
					flex-direction: column;
					gap: 10px;

					.finishPhone__header-title {
						margin-bottom: 20px;

						p {
							font-size: 22px;
							font-weight: 700;
							color: var(--text-clr-grey-900);
						}
					}
				}

				.finish__error {
					color: #ff0000;
					font-size: 14px;
					font-weight: 500;
					text-align: left;
				}
			}
		}

		.finish__character {
			position: absolute;
			bottom: -24px;
			left: 40px;
			width: 300px;
			padding: 5px;

			@media screen and (max-width: 500px) {
				bottom: 50px;
			}
		}
	}
}

.finish__button {
	@media screen and (max-width: 500px) {
		position: fixed;
		bottom: auto;
		left: 16px;
		right: 16px;
	}
}

.finish__space {
	margin-bottom: 15px;
}

.finish__button {
	margin-top: 10px;
}
