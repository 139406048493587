.register__wrapper-box {
	width: 100vw;
	background: white;
	max-width: 500px;
	border-radius: 10px;
	z-index: 1000;
}

.register__header-2 {
	padding: 22px 30px 0 32px;
	border-bottom: #dddddd 1.5px solid;
	width: 100%;

	@media screen and (max-width: 768px) {
		
	}

	.register__header--top {
		display: flex;
		justify-content: space-between;
		margin-bottom: 30px;
		position: relative;

		.register__header-logo {
			width: 100%;
			position: relative;

			.register__header-back-btn {
				all: unset;
				position: absolute;
				top: 50%;
				left: 0;
				transform: translate(0, -50%);
			}

			.register__logo--container {
				width: 100%;
				height: 100%;
				display: flex;
				justify-content: center;
				align-items: center;
			}
		}
	}

	.register__wrapper-close {
		position: absolute;
		top: 0;
		right: 0;
		cursor: pointer;

		.svg-container {
			cursor: pointer;
		}
	}
}

.register__header-title {
	position: relative;
	display: flex;
	gap: 10px;
	margin-left: 10px;

	span {
		padding: 5px 15px;
		cursor: pointer;

		p {
			color: var(--bg-clr-grey-600);
			font-size: 14px;
		}
	}

	.active {
		border-bottom: 3px #2368a2 solid;

		.active-tab {
			color: var(--clr-primary);
		}
	}
}
