.dropdown_btn {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	cursor: pointer;
	outline: none;
	font-weight: 500;
	padding: 12px 30px;
	border: 1px solid #9999;
	border-radius: 5px;

	.selected_values {
		display: flex;
		align-items: center;
		gap: 10px;
	}
}
.dropdown_content {
	min-height: 50px;
	cursor: pointer;
	outline: none;
	font-weight: 500;
	border: 1px solid #9999;
	border-radius: 5px;
	margin-top: 10px;
	padding: 5px;

	.dropdown_item {
		padding: 5px 30px;
		display: flex;
		align-items: center;
		gap: 5px;

		&:hover {
			background: #f5f5f5;
		}
	}
}
