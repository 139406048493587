.modal__container,
.modal__overlay {
	width: 100vw;
	height: 100vh;
	position: fixed;
	overflow-y: auto;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 999999;
	isolation: isolate;

}

.modal__overlay {
	background: rgba(55, 50, 50, 0.25);
	backdrop-filter: blur(4.5px);
	-webkit-filter: blur(15px);
	-moz-filter: blur(15px);
	-o-filter: blur(15px);
	-ms-filter: blur(15px);
	filter: blur(15px);
	-webkit-backdrop-filter: blur(4.5px);
	z-index: -50;
}
