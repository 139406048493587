.have__query__login__container {
	display: flex;
	flex-direction: column;
	gap: 24px;
	width: 100%;
	max-width: 600px;
	margin: 60px auto;

	@media screen and (max-width: 786px) {
		padding: 0 16px;
	}

	h2 {
		color: #333333;
		font-size: 24px;
		font-weight: 700;
		line-height: 36px;
	}

	.btn__continue {
		all: unset;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 14px 29px;
		cursor: pointer;
		border-radius: 100px;
		background: #2d66a1;
		color: #ffffff;
		font-size: 14px;
		font-weight: 600;
		line-height: 21px;
		width: max-content;

		@media screen and (max-width: 768px) {
			padding: 10px 20px !important;
			font-size: 12px;
			font-weight: 600;
			line-height: 16px;
			white-space: nowrap;
		}
	}
}
