.universalURL_main_error_screen__container-page {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fefefe;
}

.universalURL_main_error_screen__container-content {
  width: 100%;
  max-width: 800px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 1rem;
  padding: 3rem 4rem 2rem 4rem;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  box-shadow: 0 0 1rem 0.5rem rgba(0, 0, 0, 0.1);
}

.universalURL_main_error_screen__para-main {
  font-weight: 500;
  font-size: 1.5rem;
  color: var(--text-clr-grey-900);
}

.universalURL_main_error_screen__heading-main {
  font-weight: 700;
  font-size: 2rem;
  line-height: 1.5;
  margin-bottom: 0.5rem;
}

.universalURL_main_error_screen__para-secondary {
  font-weight: 500;
  font-size: 1rem;
  color: var(--bg-clr-grey-600);
}

.universalURL_main_error_screen__link-go-home {
  all: unset;
  width: max-content;
  margin-left: auto;
  padding: 0.75rem 1.5rem;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.5;
  color: var(--clr-white);
  background-color: var(--bg-primary);
  border-radius: 5px;
  outline: 1px solid #2368a2;
  cursor: pointer;

  &:hover,
  &:focus {
    color: var(--clr-primary);
    background-color: var(--bg-white);
  }
}