.review__bottom__bar__container {
	background-color: #ffffff;
	padding: 40px 0 !important;
	z-index: 10;

	@media screen and (max-width: 768px) {
		position: fixed;
		bottom: 0;
		left: 0;
		right: 0;
		border-top: 1px solid #dddddd;
		padding: 20px 16px !important;
	}

	.review__bottom__container {
		width: 100%;
		max-width: 1280px;
		margin: 0 auto;
	}

	.buttons__1 {
		width: 100%;
		display: flex;
		justify-content: space-between;

		.btn__continue {
			all: unset;
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 14px 29px;
			cursor: pointer;
			border-radius: 100px;
			background: #2d66a1;
			color: #ffffff;
			font-size: 14px;
			font-weight: 600;
			line-height: 21px;

			@media screen and (max-width: 768px) {
				padding: 12px 20px;
			}
		}
	}

	.buttons {
		display: flex;
		justify-content: space-between;

		.btn__skip {
			all: unset;
			display: flex;
			align-items: center;
			justify-content: center;
			cursor: pointer;
			border-radius: 100px;
			color: #666666;
			font-size: 14px;
			font-weight: 600;
			line-height: 21px;
			text-decoration: underline;
			text-underline-offset: 4px;

			@media screen and (max-width: 768px) {
				padding: 10px 0 !important;
				font-size: 12px;
				font-weight: 600;
				line-height: 16px;
				white-space: nowrap;
			}
		}

		.btn__prev {
			all: unset;
			display: flex;
			align-items: center;
			justify-content: center;
			border: 2px solid #cccccc;
			padding: 14px 29px;
			cursor: pointer;
			border-radius: 100px;
			color: #666666;
			font-size: 14px;
			font-weight: 600;
			line-height: 21px;

			@media screen and (max-width: 768px) {
				padding: 10px 20px !important;
				font-size: 12px;
				font-weight: 600;
				line-height: 16px;
				white-space: nowrap;
			}
		}

		.btn__continue {
			all: unset;
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 14px 29px;
			cursor: pointer;
			border-radius: 100px;
			background: #2d66a1;
			color: #ffffff;
			font-size: 14px;
			font-weight: 600;
			line-height: 21px;

			@media screen and (max-width: 768px) {
				padding: 10px 20px !important;
				font-size: 12px;
				font-weight: 600;
				line-height: 16px;
				white-space: nowrap;
			}
		}

		.right {
			display: flex;
			gap: 30px;
		}
	}
}
