.deadline-sticky-bar {
	position: fixed;
	transition: ease-in-out;
	top: 0;
	width: 100%;
	background: rgb(121, 9, 64);
	background: linear-gradient(
		90deg,
		rgba(121, 9, 64, 1) 35%,
		rgba(255, 0, 91, 1) 100%
	);
	color: white;
	left: 0;
	z-index: 999999999;
	isolation: isolate;
	padding: 10px;
	padding-top: 5px;
	font-family: var(--ff-body);
	text-align: center;
	.mob-br {
		display: none;

		@media screen and (max-width: 800px) {
			display: block;
		}
	}
	@media screen and (max-width: 800px) {
		font-size: 10px;
	}
}

.move-up-bar {
	animation: moveUp 0.3s ease-in-out;
	top: -50px;
}

@keyframes moveUp {
	0% {
		top: 0;
	}
	100% {
		top: -40px;
		@media screen and (max-width: 800px) {
			top: -50px;
		}
	}
}
