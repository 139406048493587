.list__home__step__two__container {
	width: 100%;
	max-width: 600px;
	margin: 0 auto;

	@media screen and (max-width: 768px) {
		max-width: 100%;
		padding: 0 16px;
		margin-bottom: 60px;
	}

	h1 {
		color: #333333;
		font-size: 24px;
		font-weight: 700;
		line-height: 36px;
	}

	.uploaded__image__container {
		display: flex;
		flex-direction: column;
		gap: 16px;

		.content {
			display: flex;
			gap: 40px;
			padding-bottom: 16px;
			border-bottom: 1px solid #dddddd;

			&:first-child {
				margin-top: 16px;
			}
			&:last-child {
				border-bottom: none;
			}

			.left {
				display: flex;
				flex-direction: column;

				@media screen and (max-width: 500px) {
					width: 50%;
				}

				.left__top {
					display: flex;
					align-items: center;
					gap: 8px;

					p {
						color: #999999;
						font-size: 14px;
						font-weight: 500;
						line-height: 21px;

						@media screen and (max-width: 500px) {
							width: 100%;
							white-space: nowrap;
							text-overflow: ellipsis;
							overflow: hidden;
						}
					}
				}

				.upload {
					font-size: 12px;
					font-weight: 500;
					line-height: 18px;
					color: #11bf0e;
				}
			}

			.mid {
				color: #666666;
				font-size: 14px;
				font-weight: 600;
				line-height: 21px;
				text-decoration: underline;
				text-underline-offset: 4px;
				cursor: pointer;
				white-space: nowrap;

				@media screen and (max-width: 768px) {
					font-size: 12px;
					font-weight: 600;
					line-height: 18px;
				}
			}

			.right {
				cursor: pointer;
			}
		}
	}

	.upload__btn {
		all: unset;
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 100px;
		border: 2px solid #cccccc;
		width: 120px;
		padding: 8px 20px;
		cursor: pointer;

		label {
			color: #666666;
			font-size: 14px;
			font-weight: 600;
			line-height: 21px;
			white-space: nowrap;
			cursor: pointer;
		}

		input {
			position: absolute;
			inset: 0;
			opacity: 0;
			cursor: pointer;

			&::-webkit-file-upload-button {
				cursor: pointer;
			}
		}
	}

	.form__input {
		border: 1px solid #dddddd;
		padding: 17px 26px;
		border-radius: 4px;
		color: #333333;
		font-size: 16px;
		font-weight: 500;
		line-height: 24px;

		@media screen and (max-width: 768px) {
			padding: 13px 15px;
			font-size: 14px;
			line-height: 21px;
		}
	}

	.dropdown__container {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 18px 26px;
		color: #999999;
		font-size: 16px;
		font-weight: 500;
		line-height: 24px;
		border: 1px solid #dddddd;
		cursor: pointer;
	}
}
