@import url("https://fonts.googleapis.com/css2?family=Catamaran:wght@100;200;300;400;500;600;700;800;900&display=swap");

.existing-wrapper-parent {
}
.existing-wrapper {
	display: flex;
	align-items: center;
	justify-content: center;
	.existing-cover {
		overflow-x: auto;
		padding-top: 80px;
		padding-left: 80px;
		padding-right: 40px;
		padding-bottom: 150px;
		width: -webkit-fill-available;
		@media screen and (max-width: 1090px) {
			padding: 24px 10px;
		}
	}
}

.tell-issue-wrapper {
	display: flex;
	flex-direction: column;
	gap: 10px;
	margin: auto;
	padding-bottom: 20px;
	// width: 80%;
	width: 100%;
	max-width: 700px;
	@media screen and (max-width: 900px) {
		margin: auto;
		width: 90%;
	}
	h3 {
		font-family: "Catamaran";
		font-style: normal;
		font-weight: 700;
		font-size: 24px;
		line-height: 150%;
		color: #333333;
		margin-bottom: 30px;
		display: flex;
		align-items: center;
		gap: 24px;
		svg {
			cursor: pointer;
		}
		@media screen and (max-width: 1090px) {
			font-size: 16px;
			gap: 14px;
			svg {
				height: 10px;
			}
		}
	}
	.back-step {
		font-family: "Catamaran";
		font-style: normal;
		font-weight: 500;
		font-size: 16px;
		line-height: 150%;
		text-decoration-line: underline;
		color: #2d66a1;
		cursor: pointer;
		margin-top: 16px;
	}
	hr {
		width: 100%;
		margin-top: 30px;
		margin-bottom: 40px;
	}
	span {
		font-family: "Catamaran";
		font-style: normal;
		font-weight: 500;
		font-size: 14px;
		line-height: 150%;
		color: #999999;
		max-width: 719px;
		text-align: left;
		width: 100%;
	}
	select,
	textarea {
		padding: 17px 26px;
		width: 100%;
		border: 1px solid #dddddd;
		border-radius: 4px;
		color: #333333;
		font-size: 16px;
		font-weight: 500;
		line-height: 24px;
		outline: none;
		max-width: 719px;
		margin-bottom: 24px;
	}
	textarea {
		height: 123px;
		resize: vertical;
		width: 100% !important;
	}
}

.choose-booking-wrapper {
	display: flex;
	flex-direction: column;
	gap: 10px;
	padding-bottom: 40px;
	width: 100%;
	max-width: 700px;
	margin: auto;
	@media screen and (max-width: 900px) {
		width: 90%;
	}
	.booking-card-wrapper {
		display: flex;
		background: #ffffff;
		border: 1px solid #dddddd;
		border-radius: 20px;
		max-width: 700px;
		width: 100%;
		cursor: pointer;
		position: relative;
		padding: 30px 24px;
		gap: 16px;
		transition: 0.3s ease-in;
		&:hover {
			background: rgba(45, 102, 161, 0.1);
			border: 2px solid #2d66a1;
		}
		@media screen and (max-width: 900px) {
			flex-direction: column;
		}
		#active-tick {
			position: absolute;
			top: 24px;
			right: 24px;
		}
		img {
			width: 120px;
			height: 120px;
			object-fit: cover;
		}
		.booking-price-span {
			font-family: "Catamaran";
			font-style: normal;
			font-weight: 500;
			font-size: 14px;
			line-height: 150%;
			color: #666666;
		}
		.booking-price {
			font-family: "Catamaran";
			font-style: normal;
			font-weight: 600;
			font-size: 20px;
			line-height: 150%;
			color: #333333;
			display: block;
			text-align: right;
		}
		.booking-title {
			font-family: "Catamaran";
			font-style: normal;
			font-weight: 600;
			font-size: 20px;
			line-height: 150%;
			color: #2d66a1;
		}
		.booking-location {
			font-family: "Catamaran";
			font-style: normal;
			font-weight: 500;
			font-size: 16px;
			line-height: 150%;
			color: #666666;
		}
		.booking-dates {
			font-family: "Catamaran";
			font-style: normal;
			font-weight: 500;
			font-size: 14px;
			line-height: 150%;
			color: #333333;
		}
		.booking-bottom {
			display: flex;
			justify-content: space-between;
			align-items: end;

			.booking-guest-wrapper {
				display: flex;
				gap: 40px;
			}
		}
		.booking-details {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			width: 100%;
		}
	}
	.active-card {
		background: rgba(45, 102, 161, 0.1);
		border: 2px solid #2d66a1;
		border-radius: 20px;
	}
	h3 {
		font-family: "Catamaran";
		font-style: normal;
		font-weight: 700;
		font-size: 24px;
		line-height: 150%;
		color: #333333;
		text-align: left;
		margin-bottom: 30px;
		display: flex;
		align-items: center;
		gap: 24px;

		svg {
			cursor: pointer;
		}
		@media screen and (max-width: 1090px) {
			font-size: 16px;
			gap: 14px;
			svg {
				height: 10px;
			}
		}
	}
	.booking-card {
		cursor: pointer;
	}

	.active-card {
		.mybooking__card {
			background: rgba(45, 102, 161, 0.1);
			border: 2px solid #2d66a1;
			border-radius: 20px;
		}
	}
}
.form-done {
	gap: 12px;
	@media screen and (max-width: 900px) {
		display: flex;
		align-items: center;

		justify-content: center;
		flex-direction: column;
		width: 90%;
		margin: auto;
	}
	svg {
		margin-bottom: 50px;
	}
	h4 {
		font-family: "Catamaran";
		font-style: normal;
		font-weight: 700;
		font-size: 24px;
		line-height: 150%;
		color: #2d66a1;
		margin-bottom: 10px;
	}
	p {
		font-family: "Catamaran";
		font-style: normal;
		font-weight: 500;
		font-size: 20px;
		line-height: 150%;
		color: #333333;
		max-width: 700px;
		margin-bottom: 24px;
		@media screen and (max-width: 900px) {
			text-align: center;
		}
	}
	a {
		background: #2d66a1;
		border-radius: 100px;
		padding: 16px 69px;
		font-family: "Catamaran";
		font-style: normal;
		font-weight: 600;
		font-size: 14px;
		line-height: 150%;
		color: #ffffff;
		outline: none;
	}
}

.existing-booking-wrapper {
	width: 100%;
	max-width: 700px;
	margin: auto;
	.save-btn {
		display: flex;
		justify-content: end;
		gap: 17px;
		align-items: center;
		.text-underline {
			font-family: "Catamaran";
			font-style: normal;
			font-weight: 600;
			font-size: 14px;
			line-height: 150%;
			text-align: center;
			text-decoration-line: underline;
			color: #666666;
			cursor: pointer;
		}
		button {
			outline: none;

			border: none;
			background: #2d66a1;
			border-radius: 100px;
			font-family: "Catamaran";
			font-style: normal;
			font-weight: 600;
			font-size: 14px;
			line-height: 150%;
			text-align: center;
			color: #ffffff;
			padding: 16px 36px;
			&:disabled {
				cursor: not-allowed;
				filter: opacity(0.5);
			}
		}
	}
}

.booking-select-wrapper {
	display: flex;
	gap: 48px;
	align-items: center;
	@media screen and (max-width: 900px) {
		flex-direction: column;
		gap: 10px;
	}
	.img-cover {
		gap: 15px !important;
		@media screen and (max-width: 900px) {
			align-items: center !important;
			justify-content: left !important;
		}
	}
	.data-cover {
		display: flex;
		gap: 48px;
		align-items: center;

		@media screen and (max-width: 900px) {
			width: 100%;
			justify-content: space-between;
			align-items: end;
		}
	}
	img {
		width: 60px;
		height: 60px;
		border-radius: 10px;
	}
	.booking-select-guest {
		display: flex;
		gap: 10px;
		position: relative;
		&::before,
		&::after {
			position: absolute;
			height: 60px;
			width: 1px;
			content: "";
			background-color: #ddd;
			top: -17px;
			@media screen and (max-width: 1090px) {
				display: none;
			}
		}
		&::before {
			left: -24px;
		}
		&::after {
			right: -24px;
		}
		.booking-select-calender,
		.booking-select-total {
			align-items: center;
			display: flex;
			gap: 6.4px;
			font-family: "Catamaran";
			font-style: normal;
			font-weight: 500;
			font-size: 14px;
			line-height: 150%;
			/* identical to box height, or 21px */

			color: #333333;

			white-space: pre;
		}
	}
	.booking-select-price {
		flex-direction: column;
		display: flex;
		align-items: end;
		font-family: "Catamaran";
		font-style: normal;
		font-weight: 600;
		font-size: 16px;
		line-height: 150%;
	}
	.booking-select-title {
		font-family: "Catamaran";
		font-style: normal;
		font-weight: 600;
		font-size: 16px;
		line-height: 150%;
		color: #333333;
	}
	.booking-select-location {
		font-family: "Catamaran";
		font-style: normal;
		font-weight: 500;
		font-size: 14px;
		line-height: 150%;
		color: #666666;
	}
}
