.search__range__slider__container_new {
	width: 100%;

	.slider__range {
		width: 100%;
		z-index: 2;
		background-color: var(--bg-primary);
		height: 4px;
	}

	.slider__track,
	.slider__range {
		width: 100%;
		border-radius: 3px;
		height: 4px;
		position: absolute;
	}

	.slider__content {
		width: 100%;
		margin-top: 20px;

		.slider__container {
			position: relative;
			width: 100%;

			.slider__track {
				border-bottom: 3px solid #dddddd;
				width: 100%;
				z-index: 1;
			}
		}
	}

	.slider__value {
		width: 100%;
		display: flex;
		gap: 10px;

		.slider__left__value,
		.slider__right__value {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			gap: 8px;
			padding: 12px 0;
			padding-left: 10px;
			width: 100%;
			background: var(--bg-white);
			border: 1px solid var(--brd-clr-grey-400);
			text-align: center;
			border-radius: 6px;

			input {
				width: 100%;
				color: #333333;
				font-size: 18px;
				font-weight: 500;
				line-height: 17px;
				letter-spacing: 0em;
				text-align: left;
			}

			label {
				color: #999999;
				font-size: 12px;
				font-weight: 500;
				line-height: 14px;
			}

			.val {
				display: flex;
				color: #333333;
				font-size: 18px;
				font-weight: 500;
				line-height: 17px;
				letter-spacing: 0em;
				text-align: left;
			}
		}
	}

	.thumb {
		width: 100%;
		pointer-events: none;
		position: absolute;
		height: 0;
		outline: none;
	}

	.thumb__zindex-3 {
		z-index: 3;
	}

	.thumb__zindex-4 {
		z-index: 4;
	}

	/* Removing the default appearance */
	.thumb,
	.thumb::-webkit-slider-thumb {
		-webkit-appearance: none;
		-webkit-tap-highlight-color: transparent;
	}

	/* For Chrome browsers */
	.thumb::-webkit-slider-thumb {
		background-color: var(--bg-white);
		border: 3px solid var(--brd-clr-primary);
		border-radius: 50%;
		cursor: pointer;
		height: 24px;
		width: 24px;
		margin-top: 4px;
		pointer-events: all;
		position: relative;
	}

	/* For Firefox browsers */
	.thumb::-moz-range-thumb {
		background-color: #f1f5f7;
		border: none;
		border-radius: 50%;
		box-shadow: 0 0 1px 1px #ced4da;
		cursor: pointer;
		height: 24px;
		width: 24px;
		margin-top: 4px;
		pointer-events: all;
		position: relative;
	}
}
