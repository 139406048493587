.user__dropdown-box {
	position: absolute;
	display: flex;
	flex-direction: column;
	gap: 5px;
	right: -20px;
	top: 70px;
	width: 268px;
	padding: 24px 12px 18px 12px;
	border-radius: 0 0 10px 10px;
	background-color: rgb(255, 255, 255);
	box-shadow: 0px 4px 15px 0px #33333366;
	z-index: 1000;

	.dropdown-link {
		display: flex;
		align-items: center;
		gap: 16px;
		padding: 7px 32px;
		border-radius: 4px;
		text-decoration: none;

		svg {
			width: 20px;
			height: 20px;
		}

		.svg-wishlist {
			fill: #666666;
		}

		p {
			color: var(--text-clr-grey-900);
			font-size: 16px;
			font-weight: 500;
			line-height: 24px;
		}

		&:hover {
			background-color: #edf2ff;
			border-radius: 4px;
		}
	}

	hr {
		margin: 5px 0;
		border: 1px #dddddd solid;
	}
	.btn-logout {
		all: unset;
		display: flex;
		align-items: center;
		gap: 16px;
		padding: 7px 32px;
		color: var(--clr-black);
		font-size: 1rem;
		font-weight: 500;
		line-height: 1.5;
		cursor: pointer;

		&:hover {
			background-color: #edf2ff;
			border-radius: 4px;
		}
	}
}
