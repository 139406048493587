.homeguest__popup-wrapper {
	position: absolute;
	top: 70px;
	left: 0;
	z-index: 10;
	padding: 9px 24px;
	background: var(--bg-white);
	border: 1px solid #f2f2f2;
	box-sizing: border-box;
	box-shadow: 0px 0px 50px 4px rgba(221, 221, 221, 0.35);
	border-radius: 10px;
	width: 316px;

	.guest-elements {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin: 15px 0;

		h3 {
			font-style: normal;
			font-weight: 500;
			font-size: 16px;
			line-height: 150%;
			color: var(--text-clr-grey-900);
		}

		.guest-counter {
			display: flex;
			gap: 4px;

			button {
				display: flex;
				align-items: center;
				justify-content: center;
				background: var(--bg-white);
				border: 1px solid var(--brd-clr-grey-400);
				box-sizing: border-box;
				border-radius: 4px;
				width: 35px;
				height: 35px;

				svg {
					min-width: 12px !important;
					min-height: 12px !important;
				}
			}

			.disable {
				background: #f2f2f2;
				border: 1px solid #f2f2f2;
			}

			.counter {
				font-style: normal;
				font-weight: 500;
				font-size: 16px;
				line-height: 150%;
				color: var(--text-clr-grey-900);
				margin: 0 8px;
			}

			span {
				text-align: center;
				flex: 1;
				align-items: center;
				justify-content: center;
				display: flex;
			}
		}
	}
}
