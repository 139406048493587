@import url(https://fonts.googleapis.com/css?family=Inter:100,200,300,regular,500,600,700,800,900);
.booking-experience-card-wrapper {
	width: 15.5rem;
	height: 10.25rem;
	border-radius: 0.25rem;
	border: 1px solid #eee;
	background: #fff;
	.booking-experience-card-content {
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
		gap: 0.75rem;
		.booking-experience-card-image {
			width: 15.5rem;
			height: 5rem;
			border-radius: 0.25rem 0.25rem 0rem 0rem;
			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
				border-radius: 0.25rem 0.25rem 0rem 0rem;
			}
		}
		.booking-experience-card-bottom {
			display: flex;
			flex-direction: column;
			gap: 1.25rem;
			padding: 0 0.75rem;
			.booking-experience-card-title {
				p {
					color: var(--newui-2021-black-grays-dark-text, #333);
					font-family: Inter;
					font-size: 0.875rem;
					font-style: normal;
					font-weight: 600;
					line-height: 140%;
					align-self: stretch;
				}
			}
			.booking-experience-card-price {
				display: flex;
				width: 100%;
				justify-content: space-between;
				p {
					color: var(--newui-2021-black-grays-dark-text, #333);
					font-family: Inter;
					font-size: 0.75rem;
					font-style: normal;
					font-weight: 600;
					line-height: 150%; /* 1.125rem */
				}
				button {
					width: 2.6875rem;
					height: 1.25rem;
					border-radius: 0.125rem;
					border: 1px solid #345088;
					background: #fff;
					outline: none;
					color: #345088;
					font-family: Inter;
					font-size: 0.625rem;
					font-style: normal;
					font-weight: 600;
					line-height: normal;
				}
			}
		}
	}
}
