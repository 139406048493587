.left__contact__container {
	position: relative;
	display: flex;
	align-items: flex-start;
	flex-direction: column;
	gap: 80px;
	background: #00306b;
	padding: 80px;

	@media screen and (max-width: 1090px) {
		padding: 20px 0;
	}

	.bg__icon {
		position: absolute;
		left: 0;
		top: 240px;
	}

	.top {
		display: flex;
		flex-direction: column;
		gap: 16px;

		@media screen and (max-width: 1090px) {
			width: 100%;
		}

		@media screen and (max-width: 786px) {
			padding: 0 30px;
		}

		h1 {
			color: #ffffff;
			font-size: 40px;
			font-weight: 400;
			line-height: 60px;

			@media screen and (max-width: 1090px) {
				font-size: 24px;
				font-weight: 400;
				line-height: 36px;
				text-align: center;
			}
		}

		p {
			color: #ffffff;
			font-size: 20px;
			font-weight: 500;
			line-height: 30px;

			@media screen and (max-width: 1090px) {
				font-size: 12px;
				font-weight: 400;
				line-height: 18px;
				text-align: center;
			}
		}
	}

	.bottom {
		display: flex;
		flex-direction: column;
		gap: 24px;

		@media screen and (max-width: 1090px) {
			display: none;
		}

		.card {
			display: flex;
			flex-direction: column;
			gap: 12px;

			.top__heading {
				display: flex;
				align-items: center;

				.icon__container {
					display: flex;
					align-items: center;
					justify-content: center;
					width: 24px;
					height: 24px;
					border-radius: 100%;
					background-color: #fff;
					margin-right: 10px;

					svg {
						width: 14px;
						height: 14px;
					}

					svg path {
						fill: #00306b;
					}
				}

				h4 {
					color: #ffffff;
					font-size: 16px;
					font-weight: 600;
					line-height: 24px;
					white-space: nowrap;
					// margin-left: 10px;
				}
			}

			.bottom__subtitle {
				display: flex;

				p {
					color: #ffffff;
					font-size: 14px;
					font-weight: 500;
					line-height: 21px;

					a {
						text-decoration: none;
						color: #ffffff;
						font-size: 14px;
						font-weight: 500;
						line-height: 21px;
						text-decoration: underline;
						text-underline-offset: 3px;
						margin-inline: 3px;
					}
				}
			}
		}

		.social__icons {
			margin-top: 40px;
			display: flex;
			gap: 30px;

			span {
				cursor: pointer;
			}
		}
	}
}
