.content__container {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;

	.content__wrapper {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		width: 100%;
		padding: 90px 10px 20px 10px;

		@media screen and (max-width: 768px) {
			padding: 24px 30px;
		}

		h1 {
			color: #333333;
			font-size: 24px;
			font-weight: 600;
			line-height: 36px;

			@media screen and (max-width: 768px) {
				font-size: 16px;
				font-weight: 700;
				line-height: 24px;
				text-align: center;
			}
		}
	}

	.content__box__container {
		display: flex;
		flex-wrap: wrap;
		gap: 24px;
		margin-top: 40px;
		width: 100%;
		flex-grow: 1;

		@media screen and (max-width: 768px) {
			margin-top: 20px;
		}

		.box {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			gap: 30px;
			height: 200px;
			padding: 8px;
			border: 1px solid #dddddd;
			border-radius: 10px;
			margin: 0 auto;
			text-align: center;
			cursor: pointer;
			background-color: #fff;
			transition: all 0.3 ease-in;
			font-size: 16px;
			font-weight: 500;
			line-height: 24px;
			flex: 1 1 200px;

			@media screen and (max-width: 768px) {
				width: 134px;
				height: 134px;
				flex: 1 1 134px;
				font-size: 12px;
				font-weight: 500;
				line-height: 18px;
				gap: 12px;

				svg {
					width: 25px;
					height: 25px;
				}
			}

			&:hover {
				background: rgba(45, 102, 161, 0.1);
				border: 2px solid #2d66a1;
				color: #2d66a1;
			}
		}
	}

	.note {
		display: flex;
		flex-direction: column;
		gap: 8px;
		margin-top: 50px;
		margin-bottom: 50px;

		h4 {
			color: #333333;
			font-size: 16px;
			font-weight: 600;
			line-height: 29px;
			letter-spacing: 0em;
			text-align: center;
		}

		.note__container {
			padding: 30px 24px;
			color: #333333;
			font-size: 16px;
			font-weight: 500;
			line-height: 29px;
			width: 460px;
			background: #f2f2f2;
			border-radius: 20px;
		}
	}
}
