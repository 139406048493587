@import url(https://fonts.googleapis.com/css?family=Inter:100,200,300,regular,500,600,700,800,900);
.claim-success-wrapper {
	width: 360px;
	height: 250px;
	flex-shrink: 0;
	background: #f2f2f2;
	border-radius: 10px;
	position: relative;
	@media screen and (max-width:800px) {
		width: 90vw;
	}
	.claim-success-content {
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		gap: 20px;
		.claim-success-header {
			width: 100%;
			p {
				color: #333;
				text-align: center;
				font-family: Inter;
				font-size: 16px;
				font-style: normal;
				font-weight: 600;
				line-height: 150%; /* 24px */
			}
		}
		.claim-success-logo {
			svg {
				width: 48px;
				height: 48px;
				padding: 3px;
				background: green;
				border-radius: 50%;
			}
		}
		.claim-success-code {
			display: flex;
			width: fit-content;
			height: 30px;
			justify-content: center;
			align-items: center;
			flex-shrink: 0;
			border-radius: 3px;
			border: 1px dashed #8e8e8e;
			p {
				color: #000;
				text-align: center;
				font-family: Inter;
				font-size: 20px;
				font-style: normal;
				font-weight: 700;
				line-height: 150%; /* 30px */
			}
		}
		.claim-success-copy-btn {
			display: flex;
			flex-direction: column;
			align-items: center;
			gap: 5px;
			button {
				display: flex;
				width: 108px;
				height: 28px;
				padding: 10px 12px;
				justify-content: center;
				align-items: center;
				gap: 8px;
				flex-shrink: 0;
				border-radius: 10px;
				background: #232323;
				color: #fff;
				font-family: Inter;
				font-size: 12px;
				font-style: normal;
				font-weight: 500;
				line-height: 150%; /* 18px */
				outline: none;
				border: none;
			}
		}
	}
	.claim-success-close {
		position: absolute;
		top: 1rem;
		right: 1rem;
		cursor: pointer;
	}
}
