@import url(https://fonts.googleapis.com/css?family=Inter:100,200,300,regular,500,600,700,800,900);

.booking-experience-cal-wrapper {
	width: 25rem;
	min-height: 20.375rem;
	height: auto;
	flex-shrink: 0;
	border-radius: 1.25rem;
	background: #fff;
	position: relative;
	padding-bottom: 40px;

	@media screen and (max-width: 800px) {
		position: fixed;
		bottom: 0;
		right: 0;
		width: 100vw;
		border-bottom-left-radius: 0px;
		border-bottom-right-radius: 0px;
	}

	.booking-experience-cal-content {
		width: 100%;
		height: 100%;

		.booking-experience-cal-title {
			width: 100%;
			display: flex;
			justify-content: center;
			border-bottom: 1px solid #ddd;

			p {
				padding: 1.25rem 0;
				color: var(--newui-2021-black-grays-dark-text, #333);
				text-align: center;
				font-family: Inter;
				font-size: 1rem;
				font-style: normal;
				font-weight: 600;
				line-height: 150%;
			}
		}

		.booking-experience-cal-price {
			padding: 1.5rem 0;

			p {
				&:nth-child(1) {
					color: var(--Primary, #333);
					text-align: center;
					font-variant-numeric: lining-nums tabular-nums;
					font-family: Inter;
					font-size: 2rem;
					font-style: normal;
					font-weight: 600;
					line-height: 150%;
					/* 3rem */
				}

				&:nth-child(2) {
					color: #888;
					text-align: center;
					font-family: Inter;
					font-size: 0.75rem;
					font-style: normal;
					font-weight: 500;
					line-height: 150%;
					/* 1.125rem */
				}
			}
		}

		.booking-experience-cal-qty {
			width: 100%;
			display: flex;
			justify-content: space-between;
			padding: 1.5rem 2.5rem;
			align-items: center;

			p {
				color: var(--Primary, #333);
				font-family: Inter;
				font-size: 1rem;
				font-style: normal;
				font-weight: 500;
				line-height: 140%;
				/* 1.4rem */
			}

			.booking-experience-cal-value {
				display: flex;
				align-items: center;
				gap: 1rem;

				button {
					outline: none;
					background: transparent;
					border: none;
					font-size: 1.5rem;
				}
			}
		}

		.booking-experience-cal-duration {
			width: 100%;
			display: flex;
			justify-content: space-between;
			padding: 1.5rem 2.5rem;
			align-items: center;

			p {
				color: var(--Primary, #333);
				font-family: Inter;
				font-size: 1rem;
				font-style: normal;
				font-weight: 500;
				line-height: 140%;
				/* 1.4rem */
			}

			.booking-experience-cal-days {
				display: flex;
				gap: 0.5rem;

				button {
					display: flex;
					padding: 0.375rem 0.5rem;
					justify-content: center;
					align-items: center;
					gap: 0.625rem;
					border-radius: 6.25rem;
					background: #f7f7f7;
					color: #666;
					text-align: center;
					font-family: Inter;
					font-size: 0.625rem;
					font-style: normal;
					font-weight: 500;
					line-height: 140%;
					/* 0.875rem */
					outline: none;
					border: none;

					&.clicked {
						border-radius: 6.25rem;
						border: 2px solid #2d66a1;
						background: #ebf5ff;
						color: #2d66a1;
					}
				}
			}
		}

		.booking-experience-cal-btn {
			width: 100%;
			display: flex;
			justify-content: center;
			// padding-top: 2rem;
			// padding-bottom: 2.5rem;
			align-items: center;
			gap: 0.5rem;

			button {
				&:nth-child(1) {
					display: flex;
					justify-content: center;
					align-items: center;
					gap: 0.5rem;
					border-radius: 0.25rem;
					padding: 0.875rem 3.125rem;
					color: #555;
					text-align: center;
					font-family: Inter;
					font-size: 0.875rem;
					font-style: normal;
					font-weight: 600;
					line-height: 140%;
					outline: none;
					border: none;

					@media screen and (max-width: 800px) {
						display: none;
					}
				}

				&:nth-child(2) {
					display: flex;
					justify-content: center;
					align-items: center;
					gap: 0.5rem;
					border-radius: 0.25rem;
					background: #345088;
					padding: 0.875rem 3.125rem;
					color: #fff;
					text-align: center;
					font-family: Inter;
					font-size: 0.875rem;
					font-style: normal;
					font-weight: 600;
					line-height: 140%;
					outline: none;
					border: none;
					&:disabled{
						opacity: 0.7;
					}
					@media screen and (max-width: 800px) {
						width: 90%;
					}
				}
			}
		}
	}

	.booking-experience-cal-cross {
		position: absolute;
		top: 1rem;
		right: 1rem;
		cursor: pointer;
	}
}