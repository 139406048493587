.booking-details-wrapper {
	@media screen and (max-width: 800px) {
		padding: 20px;
	}
	.room-details-wrapper {
		display: flex;
		margin-top: 24px;
		gap: 12px;
	}
	.room-detail-card {
		display: flex;
		gap: 6px;
		align-items: center;
		color: #333;
		font-family: var(--ff-body);
		font-size: 12px;
		font-style: normal;
		font-weight: 500;
		padding: 6px 8px;
		border-radius: 24px;
		background: #e4e4e4;
	}
	.booking-title {
		color: #111;
		font-family: var(--ff-body);
		font-size: 28px;
		font-style: normal;
		font-weight: 600;
		line-height: normal;
		margin-bottom: 30px;
		@media screen and (max-width: 800px) {
			font-size: 24px;
		}
	}
	.booking-detail-box {
		border-radius: 4px;
		border: 1px solid #dfe3eb;
		background: #f9fafb;
		padding: 20px;
		width: 100%;

		@media screen and (max-width: 800px) {
			background: none;
			padding: 0;
			border: none;
		}
		.villa-details {
			gap: 16px;
			display: flex;
			img {
				width: 122px;
				height: 85px;
				flex-shrink: 0;
				object-fit: cover;
				border-radius: 4px;
			}
			h3 {
				color: #111;
				font-family: var(--ff-body);
				font-size: 18px;
				font-style: normal;
				font-weight: 600;
				line-height: normal;
			}
			p {
				margin: 0;
				margin-top: 6px;
				color: #555;
				font-family: var(--ff-body);
				font-size: 14px;
				font-style: normal;
				font-weight: 400;
				line-height: 140%; /* 19.6px */
			}
		}
	}
}
