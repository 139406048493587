.primaryButton {
	margin-top: 10px;
	width: 100%;
	border-radius: 4px;
	background: var(--gradient-primary);
	color: white;
	outline: none;
	border: none;
	cursor: pointer;
	font-size: 14px;
	padding: 18px;

	&:hover,
	&:focus {
		background: #1b4aca;
	}

	&:disabled {
		opacity: 0.5;

		&:hover {
			background: var(--gradient-primary);
		}
	}
}