.toggle_button_container {
	position: relative;

	p {
		position: absolute;
		top: -20px;
		left: 50px;
		font-weight: 500;
		font-size: 14px;
		line-height: 150%;
		color: var(--text-clr-grey-900);
	}

	.toggle_button {
		position: relative;
		display: inline-block;
		top: -20px;
		width: 42px;
		height: 20px;

		input {
			display: none;
		}

		.toggler {
			position: absolute;
			cursor: pointer;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			transition: 0.2s all ease-in-out;
			border-radius: 34px;
			border: 2px solid #999999;
		}

		.toggler:after {
			position: absolute;
			transition: 0.2s all ease-in-out;
			content: "";
			height: 12px;
			width: 12px;
			left: 5px;
			bottom: 2px;
			background-color: #999999;
			border-radius: 50%;
		}

		input:checked+.toggler:after {
			transform: translateX(18px);
			background-color: var(--bg-primary);
		}

		input:checked+.toggler {
			border: 2px solid var(--brd-clr-primary);
		}
	}
}