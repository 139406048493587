.filter__popup__container {
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	width: 100%;
	height: max-content;
	background-color: #fff;
	border-radius: 10px 10px 0 0;

	.top {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 18px 16px;
		border-bottom: 1px solid #dddddd;

		h3 {
			color: #333333;
			font-size: 16px;
			font-weight: 600;
			line-height: 24px;
		}

		.close {
			svg {
				width: 10px;
				height: 10px;
			}
		}
	}

	.middle {
		padding: 16px 20px;
		width: 100%;
		overflow-y: scroll;
		height: max-content;
		padding-bottom: 90px;

		.search__range__slider__container {
			height: 100px;
		}
	}

	.bottom {
		position: fixed;
		display: flex;
		gap: 10px;
		padding: 16px;
		box-shadow: 0px -1px 20px 1px #0000001a;
		width: 100%;
		bottom: 0;
		z-index: 100;
		background-color: #fff;

		button {
			all: unset;
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 14px;
			font-weight: 600;
			line-height: 21px;
			width: 50%;
			padding: 13px 0;
			border-radius: 4px;
		}

		.clear {
			color: #666666;
		}
		.save {
			color: #ffffff;
			background: #2d66a1;
		}
	}
}
