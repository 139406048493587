.deadline-coupon-wrapper {
	color: #eb3c3d;
	font-family: Inter;
	font-size: 14px;
	font-style: normal;
	font-weight: 600;
	line-height: 150%; /* 21px */
	display: flex;
	gap: 8px;
	align-items: center;
	justify-content: center;
	border-radius: 4px;
	border: 2px solid #fa4b4b;
	box-shadow: 1px 1px 10px 0px rgba(0, 0, 0, 0.15);
	margin-bottom: 23px;
	padding: 7px 0;
	width: 100%;
	cursor: pointer;
	@media screen and (max-width: 1000px) {
		margin: 20px;
		width: calc(100% - 40px);
	}
}
.extend-req-note {
	border-radius: 4px;
	border: 1px solid rgba(61, 180, 51, 0.3);
	background: #effbee;
	color: #555;
	font-family: Inter;
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%; /* 18px */
	border-left: 5px solid #3db433;
	padding: 8px;
	padding-left: 18px;
	margin-bottom: 20px;
	@media screen and (max-width: 1000px) {
		margin: 20px;
		width: calc(100% - 40px);
	}
}
.deadline-coupon-expire {
	display: flex;
	align-items: center;
	gap: 10px;
	margin-bottom: 23px;
	@media screen and (max-width: 1000px) {
		margin: 20px;
	}
	.cross {
		width: 20px;
		height: 20px;
		flex-shrink: 0;
		color: white;
		background: #fa4b4b;
		border-radius: 50%;
		display: flex;
		align-items: center;
		justify-content: center;
		font-family: Inter;
		font-size: 14px;
		font-style: normal;
		font-weight: 600;
	}
	span {
		color: #fa4b4b;
		font-family: Inter;
		font-size: 14px;
		font-style: normal;
		font-weight: 600;
		line-height: 150%; /* 21px */
		display: flex;
		justify-content: space-between;
		width: -webkit-fill-available;
		button {
			color: #345088;
			text-align: right;
			font-family: Inter;
			font-size: 14px;
			font-style: normal;
			font-weight: 700;
			line-height: normal;
			text-decoration-line: underline;
			border: 0;
			background: none;
			outline: none;
			padding: 0 !important;
			width: fit-content !important;
		}
		.extended-req {
			justify-content: center;
			gap: 5px;
			color: #666;
			text-align: right;
			font-family: Inter;
			font-size: 14px;
			font-style: normal;
			font-weight: 500;
			line-height: normal;
			align-items: center;
			width: fit-content;
			white-space: pre;
		}
	}
}
.info-deadline {
	color: #666;
	font-family: Inter;
	font-size: 12px;
	font-style: normal;
	font-weight: 500;
	line-height: 150%; /* 18px */
	display: flex;
	align-items: center;
	gap: 4px;
	@media screen and (max-width: 1000px) {
		margin-left: 20px;
	}
}

.info-mobile {
	@media screen and (max-width: 1000px) {
		display: none;
	}
}
.deadline-coupon-apply {
	border-radius: 4px;
	background: #f9d7d8;
	display: flex;
	margin-bottom: 23px;
	overflow: hidden;
	position: relative;
	@media screen and (max-width: 1000px) {
		margin: 20px;

		border-bottom: 1px solid #d5dbe5;
	}
	&::after {
		position: absolute;
		content: "";
		width: 15px;
		height: 15px;
		background: #f9fafb;
		top: calc(50% - 7.5px);
		left: -7.5px;
		border-radius: 50%;
	}
	&::before {
		position: absolute;
		content: "";
		width: 15px;
		height: 15px;
		background: #f9fafb;
		right: -7.5px;

		top: calc(50% - 7.5px);
		border-radius: 50%;
	}
	.deadline-details {
		padding: 8px 10px;
		color: #333;
		font-family: Inter;
		font-size: 14px;
		font-style: normal;
		font-weight: 500;
		line-height: 150%; /* 21px */
		flex-direction: column;
		height: 100%;
		justify-content: space-between;
		.top-detail {
			display: flex;
			div {
				flex: 0.8;
			}
			b {
				white-space: pre;
			}
			span {
				flex: 0.2;
				display: block;
				margin-left: 40px;
				cursor: pointer;
				color: #345088;
				font-family: Inter;
				font-size: 14px;
				font-style: normal;
				font-weight: 600;
				line-height: 150%; /* 18px */
				text-align: end;
			}
		}
		.bottom-detail {
			color: #333;
			font-family: Inter;
			font-size: 12px;
			font-style: normal;
			font-weight: 500;
			line-height: 150%; /* 18px */
			margin-top: 10px;
			align-items: center;
			gap: 4px;
			display: flex;
			justify-content: flex-end;
			@media screen and (max-width: 1000px) {
				margin-top: 10px;
			}
			span {
				color: #333;
				text-align: right;
				font-family: Inter;
				font-size: 14px;
				font-style: normal;
				font-weight: 700;
				line-height: 150%; /* 21px */
				letter-spacing: 1.4px;
				border-radius: 12px;
				background: #fff;
				padding: 1px 12px;
			}
		}
	}
	.deadline-percentage {
		background: #fa4b4b;
		color: #fff;
		position: relative;
		font-family: Inter;
		font-size: 16px;
		font-style: normal;
		font-weight: 800;
		line-height: 150%; /* 24px */
		padding: 20px;
		width: 30%;
		svg {
			position: absolute;
			right: 0;
			bottom: 0;
		}
	}
}
