.choose_payment {
	margin-top: 10px;
	.font-caption {
		@media screen and (max-width: 800px) {
			display: none;
		}
	}
}
.new-choose-payment-wrapper {
	display: flex;
	align-items: center;
	justify-content: space-between;
	// background-color: #f2f2f2;
	// height: 40px;
	// padding: 0 16px;
	@media screen and (max-width: 800px) {
		display: flex;
	}
	.booking__choose-payment {
		display: flex;
		// padding: 30px 30px 40px 30px;
		flex-direction: column;
		// gap: 20px;
		@media screen and (max-width: 800px) {
			flex-direction: column;
			padding: 30px 16px 16px 16px;
		}

		.payment-option-wrapper {
			border-radius: 10px;
			// border: 2px solid #ddd;
			opacity: 0.75;
			background: #fff;
			position: relative;
			display: flex;
			flex-direction: column;
			padding: 20px;
			justify-content: space-between;
			cursor: pointer;
			flex: 1;
			padding-left: 54px;

			&::before {
				position: absolute;
				top: 20px;
				left: 15px;
				content: "";
				width: 21px;
				height: 21px;
				flex-shrink: 0;
				border: 1px solid rgba(136, 136, 136, 1);
				border-radius: 50%;
			}
			.payment-title {
				color: #111;
				font-family: Inter;
				font-size: 14px;
				font-style: normal;
				font-weight: 600;
				line-height: normal;
				margin-bottom: 10px;
			}
			p {
				color: #111;
				font-family: Inter;
				font-size: 10px;
				font-style: normal;
				font-weight: 400;
				line-height: 150%; /* 15px */
				margin-bottom: 24px;
				width: 80%;
				@media screen and (max-width: 800px) {
					width: 60%;
				}
			}
			span {
				color: #14a53c;
				font-family: Inter;
				font-size: 10px;
				font-style: normal;
				font-weight: 600;
				line-height: normal; /* 15px */
				background: #e6f8eb;
				padding: 1px 2px;
			}
			.payment-price {
				color: #111;
				font-family: Inter;
				font-size: 14px;
				font-style: normal;
				font-weight: 700;
				line-height: normal;
				display: flex;
				align-items: center;
				gap: 10px;
			}
			a {
				color: var(--newui-2021-primary-blue-user-primary-blue-user, #3564e2);
				font-family: var(--ff-body);
				font-size: 12px;
				font-style: normal;
				font-weight: 600;
				line-height: 150%; /* 18px */
				text-decoration-line: underline;
			}
			svg {
				position: absolute;
				bottom: 0;
				right: 0;
			}
		}
		.payment-active {
			border-radius: 10px;
			border: 3px solid #345088;
			background: #f4f7fa;
			opacity: 1;
			&::before {
				background-image: none;
			}
			&::after {
				position: absolute;
				top: 20px;
				left: 15px;
				background-image: url("../../../assets/blue-circle.svg");
				content: "";
				width: 21px;
				height: 21px;
				flex-shrink: 0;
			}
		}
	}
}
