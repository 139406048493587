.travel__agent__option__page__container {
	display: flex;
	flex-direction: column;
	gap: 24px;
	width: 100%;
	max-width: 600px;
	margin: 80px auto;

	@media screen and (max-width: 786px) {
		padding: 0 16px;
	}

	h2 {
		color: #333333;
		font-size: 24px;
		font-weight: 700 !important;
		line-height: 36px;

		@media screen and (max-width: 768px) {
			font-size: 20px !important;
			font-weight: 500;
			line-height: 26px;
		}
	}

	h3 {
		color: #333333;
		font-size: 16px;
		font-weight: 600;
		line-height: 36px;
	}

	.options__container {
		display: flex;
		flex-direction: column;
		gap: 24px;

		.tab {
			display: flex;
			align-items: center;
			gap: 15px;
			border-radius: 100%;
			cursor: pointer;

			.border {
				width: 20px;
				height: 20px;
				padding: 3.35px;
				border: 1.82px solid #999999;
				border-radius: 100%;

				.active {
					display: flex;
					background: #2d66a1;
					width: 100%;
					height: 100%;
					border-radius: 100%;
					transition: all 0.3s ease-in-out;
				}
			}

			label {
				color: #333333;
				font-size: 16px;
				font-weight: 500;
				line-height: 24px;
				cursor: pointer;

				@media screen and (max-width: 768px) {
					font-size: 14px;
					font-weight: 500;
					line-height: 21px;
				}
			}
		}
	}

	.bottom {
		display: flex;
		justify-content: space-between;
		margin-top: 20px;

		.btn__continue {
			all: unset;
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 14px 29px;
			cursor: pointer;
			border-radius: 100px;
			background: #2d66a1;
			color: #ffffff;
			font-size: 14px;
			font-weight: 600;
			line-height: 21px;

			@media screen and (max-width: 768px) {
				padding: 10px 20px !important;
				font-size: 12px;
				font-weight: 600;
				line-height: 16px;
				white-space: nowrap;
			}
		}
	}
}
