.ask-booking-popup {
	z-index: 100;
	display: flex;
	flex-direction: column;
	width: fit-content;
	gap: 10px;
	border-radius: 8px;
	border: 1px solid #d5dbe5;
	background: #fff;
	padding: 30px 24px;
	max-height: 90vh;
	overflow-y: auto;
	position: relative;
	width: 500px;
	&::-webkit-scrollbar {
		width: 5px;
	}

	/* Track */
	&::-webkit-scrollbar-track {
		background: #f1f1f1;
	}

	/* Handle */
	&::-webkit-scrollbar-thumb {
		background: #888;
	}

	/* Handle on hover */
	&::-webkit-scrollbar-thumb:hover {
		background: #555;
	}
	@media screen and (max-width: 1000px) {
		position: fixed;
		bottom: 0;
		left: 0;
		width: 100%;
	}

	label {
		display: flex;
		align-items: center;
		gap: 10px;
		color: #555;
		font-family: Inter;
		font-size: 12px;
		font-style: normal;
		font-weight: 400;
		line-height: 140%; /* 16.8px */
		margin: 10px 0;
		cursor: pointer;
	}
	.room-counter {
		display: flex;
		align-items: center;
		// justify-content: ;
		gap: 20px;
		.counter-wrapper {
			display: flex;
			align-items: center;
			gap: 10px;
			button {
				width: 30px;
				height: 30px;
				font-weight: bold;
				background: none;
				border: 1px solid black;
				border-radius: 4px;
				display: flex;
				align-items: center;
				justify-content: center;
				color: black;
				font-size: 14px;
				&:disabled {
					opacity: 0.5;
					cursor: not-allowed;
				}
			}
		}
	}
	.btn-wrapper {
		display: flex;
		width: 100%;
		justify-content: center;
		align-items: center;
		padding-top: 20px;
		border-top: 1px solid #ddd;
		position: sticky;
		bottom: 0;
		left: 0;
		background: white;

		button {
			font-family: var(--ff-body);
			font-size: 16px;
			font-style: normal;
			font-weight: 600;
			line-height: normal;
			color: #335bad;
			text-decoration: underline;
			padding: 15px 77px;
			background: none;
			border: none;
			&:disabled {
				opacity: 0.5;
				cursor: not-allowed;
			}
			@media screen and (max-width: 800px) {
				flex: 1;
				padding: 15px 0;
			}
		}
		.blue-btn {
			color: white;
			border-radius: 4px;
			background: #335bad;
			text-decoration: none;
		}
	}
	h3 {
		color: #111;
		font-family: Inter;
		font-size: 18px;
		font-style: normal;
		font-weight: 600;
		line-height: normal;
	}
	p {
		color: #555;
		font-family: Inter;
		font-size: 12px;
		font-style: normal;
		font-weight: 400;
		line-height: 140%; /* 16.8px */
	}
	h3 {
		font-size: 18px;
	}
	p {
		margin-bottom: 30px;
	}
	.ask-me-input {
		font-family: Inter;
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		line-height: 150%;
		border-radius: 4px;
		border: 1px solid #ccc;
		background: #fff;
		padding: 18px 20px;
		width: 100%;
		outline: none;
	}
	.form-control {
		width: 100%;
		height: 40px;
	}
	textarea {
		// margin-bottom: 200px;
		font-family: Inter;
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		line-height: 150%;
		border-radius: 4px;
		border: 1px solid #ccc;
		background: #fff;
		padding: 18px 20px;
		resize: none;
		flex-shrink: 0;
		outline: none;
	}
}
.ask-help-wrapper {
	border-radius: 4px;
	border: 1px solid rgba(213, 219, 229, 0.5);
	background: linear-gradient(
		94deg,
		rgba(217, 230, 255, 0.5) 1.56%,
		rgba(168, 192, 238, 0.5) 100%
	);
	display: flex;
	padding: 20px;
	margin-top: 24px;
	align-items: center;
	justify-content: space-between;

	span {
		color: #111;
		font-family: Inter;
		font-size: 16px;
		font-style: normal;
		font-weight: 400;
		line-height: 150%; /* 24px */
	}
	button {
		color: #fff;
		font-family: Inter;
		font-size: 12px;
		font-style: normal;
		font-weight: 600;
		line-height: 150%; /* 18px */
		padding: 8px 20px;
		width: fit-content;
		background-color: rgb(45, 102, 161);
		border-radius: 4px;
		outline: none;
		border: 0;
	}
}
.xseries-checkbox {
	filter: invert(1);
}

.redirection-popup {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	img {
		height: 200px;
		object-fit: contain;
	}
	p {
		text-align: center;
	}
	a {
		border-radius: 4px;
		padding: 11px 15px;
		background: #2d66a1;
		color: #fff;
		font-family: Inter;
		font-size: 14px;
		font-style: normal;
		font-weight: 600;
		line-height: 150%; /* 21px */
		text-decoration: none;
		width: 80%;
		text-align: center;
	}
}
