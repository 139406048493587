.booking__coupon {
	width: 580px;
	background: var(--bg-white);
	border-radius: 10px;

	@media screen and (max-width: 900px) {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		width: 100%;
		border-radius: 0px;
	}

	.booking__coupon-container {
		width: 100%;
		height: 100%;

		.booking__coupon-top {
			display: flex;
			flex-direction: column;
			gap: 30px;
			padding: 40px 30px 24px 30px;

			@media screen and (max-width: 900px) {
				gap: 20px;
				padding: 26px 16px 20px 16px;
			}

			.coupon-top {
				display: flex;
				align-items: center;
				justify-content: space-between;

				p {
					color: var(--text-clr-grey-900);
					font-size: 24px;
					font-style: normal;
					font-weight: 700;
					line-height: 36px;
				}

				span {
					svg {
						width: 16px;
						height: 16px;
						cursor: pointer;
					}
				}
			}

			.coupon-bottom {
				position: relative;
				display: flex;
				align-items: center;
				width: 100%;

				input {
					height: 60px;
					width: 100%;
					border: 1px #dddddd solid;
					padding: 4px 4px 4px 21px;
					border-radius: 4px;

					@media screen and (max-width: 900px) {
						height: 48px;
					}
				}

				input[type="placeholder"] {
					color: #999999;
					font-size: 16px;
					font-style: normal;
					font-weight: 500;
					line-height: 24px;
				}

				button {
					color: var(--clr-white);
					position: absolute;
					margin-right: 4px;
					border-radius: 4px;
					right: 0;
					height: 90%;
					width: 132px;
					background: var(--gradient-primary);
					outline: none;
					border: none;

					// text
					font-size: 16px;
					font-style: normal;
					font-weight: 600;
					line-height: 24px;

					&:hover {
						background-color: var(--bg-primary);
					}
				}
			}
		}

		.booking__coupon-content {
			width: 100%;
			max-height: 600px;
			padding-bottom: 2rem;
			overflow-y: scroll;
			background-color: #f2f2f2;

			@media screen and (max-width: 900px) {
				max-height: none;
			}

			.booking__coupon-middle {
				margin-top: 10px;
				padding: 11px 29px;

				@media screen and (max-width: 900px) {
					padding: 11px 16px;
				}

				p {
					font-weight: 700;
					font-size: 20px;
					line-height: 1.5;
					color: #000000;
				}
			}

			.booking__coupon-bottom {
				overflow-y: auto;
				overflow-x: hidden;

				@media screen and (max-width: 900px) {
					// height: 75vh;
				}
			}
		}
	}
}
