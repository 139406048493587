.booking__coupon-card {
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 10px;

	.coupon-card {
		width: 90%;
		margin: auto;
		display: flex;
		gap: 24px;
		border-bottom: 1px #dddddd solid;
		padding: 16px 24px 16px 0;
		background-color: #fff;
		border-radius: 10px;

		@media screen and (max-width: 900px) {
			// border-bottom: 6px #dddddd solid;
			padding: 22px 16px;
		}

		.card-img {
			padding-left: 10px;
			border-left: 4px solid #2D66A1;
			width: 40px;
			height: 40px;

			svg {
				width: 40px;
				height: 40px;
			}

			@media screen and (max-width: 900px) {
				width: 30px;
				height: 30px;

				svg {
					width: 30px;
					height: 30px;
				}
			}


			img {
				width: 100%;
				height: 100%;
				border-radius: 50%;
			}
		}

		.card-details {
			display: flex;
			flex-direction: column;
			gap: 10px;
			width: 100%;

			.details {
				display: flex;
				flex-direction: column;
				gap: 6px;

				.title {
					color: var(--text-clr-grey-900);
					font-size: 16px;
					font-style: normal;
					font-weight: 600;
					line-height: 24px;

					@media screen and (max-width: 900px) {
						font-size: 12px;
						line-height: 18px;
					}
				}

				.subtitle {
					color: var(--bg-clr-grey-600);
					font-size: 12px;
					font-style: normal;
					font-weight: 500;
					line-height: 18px;

					@media screen and (max-width: 900px) {
						font-size: 12px;
						line-height: 18px;
					}
				}

				a {
					color: var(--text-clr-grey-900);
					text-decoration: none;
					font-size: 10px;
					font-style: normal;
					font-weight: 600;
					line-height: 15px;

					@media screen and (max-width: 900px) {
						font-size: 14px;
						line-height: 21px;
					}

					&:hover {
						text-decoration: underline;
						text-underline-offset: 3px;
					}
				}

				.coupon_code {
					width: max-content;
					position: relative;
					display: flex;
					justify-content: center;
					align-items: center;
					color: var(--text-clr-grey-900);
					padding: 7px 12px;
					font-size: 14px;
					font-style: normal;
					font-weight: 600;
					line-height: 21px;
					border-radius: 4px;
					letter-spacing: 2px;

					svg {
						position: absolute;
						top: -5px;
						right: 10px;
					}
				}

				.applied {
					border: 1px #11bf0e dashed;
					background: #e7f9e7;
				}

				.not-applied {
					border: 1px #2368a2 dashed;
					background: #edf2ff;
				}

				.save-upto {
					display: flex;
					align-items: center;
					font-weight: 600;
					font-size: 14px;
					line-height: 1.5;
					color: #2D66A1;
				}

				.not-applicable {
					font-style: italic;
					color: #999999;
				}
			}

			.apply {
				padding-top: 10px;
				display: flex;
				justify-content: space-between;
				width: 100%;
				border-top: 1px dashed #CCCCCC;

				.save-upto {
					display: flex;
					align-items: center;
					font-weight: 600;
					font-size: 14px;
					line-height: 1.5;
					color: #2D66A1;
				}

				button {
					padding: 8px 24px;
					outline: none;
					border: none;
					font-size: 14px;
					font-weight: 600;
					line-height: 1.5;
					background: transparent;
					border-radius: 5px;

					@media screen and (max-width: 900px) {
						padding: 6px 10px;
					}

					&:disabled {
						border: 2px red solid;
						color: red;
					}
				}

				.active {
					color: var(--clr-primary);
					border: 2px solid var(--brd-clr-primary);

					@media screen and (max-width: 900px) {
						border: none;
					}
				}

				.not-active {
					color: #f2f2f2;
					border: 2px #dddddd solid;

					@media screen and (max-width: 900px) {
						color: #999999;
						border: none;
					}
				}
			}
		}
	}

	.unavailable {
		.card-img {
			padding-left: 16px;
			border-left: none;
		}
	}
}