.featured__one {
	display: flex;
	max-width: 1250px;
	width: 100%;

	.featured__one-container {
		display: flex;
		max-width: 1250px;
		width: 100%;
		overflow-x: scroll;
		align-items: flex-start;
		overflow-y: hidden;
		gap: 1rem;
		scroll-behavior: smooth;
		margin-bottom: 40px;
		padding: 0.5rem 0.8rem;

		@media screen and (max-width: 700px) {
			margin-bottom: 0;
			padding: 0.5rem 0.6rem;
		}

		&::-webkit-scrollbar {
			background: transparent; /* make scrollbar transparent */
			-webkit-appearance: none;
			width: 0;
			height: 0;
		}
	}
}
