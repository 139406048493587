.add-referral-code__container {
	width: 100vw;
	max-width: 450px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background-color: var(--bg-white);
	border-radius: 10px;
	padding: 24px 16px;

	@media screen and (max-width: 500px) {
		padding: 40px 0 80px 0;
		max-width: 100%;
	}
}

.add-referral-code__header-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	gap: 2px;

	h3 {
		font-size: 24px;
		font-weight: 700;
		color: var(--text-clr-grey-900);
	}

	p {
		font-size: 14px;
		font-weight: 500;
		color: var(--bg-clr-grey-600);
		line-height: 25px;
	}
}

.add-referral-code__body-container {
	margin-top: 2rem;
	width: 100%;
	padding: 0 20px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 4px;
}

.add-referral-code__btn-skip {
	all: unset;
	cursor: pointer;
	margin-top: 10px;
	width: max-content;
	font-size: 12px;
	color: var(--bg-clr-grey-600);
}
