.booking-meal-wrapper {
	display: flex;
	border-radius: 4px;
	border: 1px solid #d5dbe5;
	background: #fff;
	@media screen and (max-width: 1000px) {
		border-bottom: 5px solid #d5dbe5;
	}
	@media screen and (max-width: 1000px) {
		flex-direction: column;
	}
	.mob-meal-container {
		display: none;
		flex-direction: column;

		@media screen and (max-width: 1000px) {
			display: flex;
			margin-top: 55px;
			justify-content: space-between;
		}
	}
	.meal-container {
		display: flex;
		flex-direction: column;
		span {
			margin-top: 10px;
			display: block;
			@media screen and (max-width: 1000px) {
				display: none;
			}
		}
	}
	.meal-price-mob {
		display: flex;
		justify-content: space-between;
		align-items: center;
		color: #111;
		font-family: Inter;
		font-size: 12px;
		font-style: normal;
		font-weight: 600;
		line-height: normal;
		width: 100%;

		button {
			color: #345088;
			font-family: Inter;
			font-size: 12px;
			font-style: normal;
			font-weight: 600;
			line-height: normal;
			border-radius: 2px;
			border: 1px solid #345088;
			background: #fff;
			padding: 7px 6px;
			display: block;
			width: 100%;
		}
		.meal-added {
			color: #129536;
			font-family: Inter;
			font-style: normal;
			font-weight: 600;
			line-height: normal;
			display: flex;
			align-items: center;
			justify-content: center;
			border-radius: 2px;
			border: 1px solid rgba(18, 149, 54, 0.7);
			background: #e7fbec;
			svg {
				path {
					stroke: rgba(18, 149, 54, 1);
					fill: rgba(18, 149, 54, 1);
				}
			}
		}
	}
	.meal-price {
		display: flex;
		justify-content: space-between;
		align-items: center;
		color: #111;
		font-family: Inter;
		font-size: 12px;
		font-style: normal;
		font-weight: 600;
		line-height: normal;
		@media screen and (max-width: 1000px) {
			display: none;
		}

		button {
			color: #345088;
			font-family: Inter;
			font-size: 12px;
			font-style: normal;
			font-weight: 600;
			line-height: normal;
			border-radius: 2px;
			border: 1px solid #345088;
			background: #fff;
			padding: 7px 6px;
			margin-left: auto;
			display: block;
		}
		.meal-added {
			color: #129536;
			font-family: Inter;
			font-style: normal;
			font-weight: 600;
			line-height: normal;
			display: flex;
			align-items: center;
			border-radius: 2px;
			border: 1px solid rgba(18, 149, 54, 0.7);
			background: #e7fbec;
			svg {
				path {
					stroke: rgba(18, 149, 54, 1);
					fill: rgba(18, 149, 54, 1);
				}
			}
		}
	}
	.meal-left {
		flex: 0.8;
		padding: 20px;

		@media screen and (max-width: 1000px) {
			flex: 1;
			display: flex;
			gap: 16px;
		}
		.meal-title {
			color: #111;
			font-family: Inter;
			font-size: 16px;
			font-style: normal;
			font-weight: 600;
			line-height: normal;
			margin-bottom: 14px;
			@media screen and (max-width: 1000px) {
				font-size: 24px;
				margin-bottom: 30px;
			}
		}
		span {
			color: #555;
			font-family: Inter;
			font-size: 12px;
			font-style: normal;
			font-weight: 500;
			line-height: normal;
		}
	}
	.meal-right {
		flex: 1.2;
		border-radius: 0px 4px 4px 0px;
		background: #f4f7fa;
		padding: 20px 30px;

		@media screen and (max-width: 1000px) {
			flex: 1;
		}

		.title {
			color: #111;
			font-family: Inter;
			font-size: 12px;
			font-style: normal;
			font-weight: 600;
			line-height: 150%; /* 18px */
			margin-bottom: 8px;
			display: flex;
			align-items: center;
			justify-content: space-between;
		}
		p {
			color: #555;
			font-family: Inter;
			font-size: 12px;
			font-style: normal;
			font-weight: 400;
		}
		.readmore-btn {
			position: relative;
			cursor: pointer;
			margin-left: auto;
			display: block;
			right: 0;
			bottom: 0;
			text-align: end;
			color: #555;
			font-family: Inter;
			font-size: 12px;
			font-style: normal;
			font-weight: 600;
			line-height: 150%;
			text-decoration-line: underline;
			border: 0;
			background: none;
		}
		.more {
			-webkit-box-orient: vertical;
			-webkit-line-clamp: 5;
			line-height: 1.5em;
			height: 7.5em;
			overflow: hidden;
			text-overflow: ellipsis;
			display: -webkit-box;
		}
	}
	img {
		width: 170px;
		height: 70px;
		flex-shrink: 0;
		border-radius: 4px;
	}
}
