.cancellation-container {
	padding: 20px;
	display: flex;
	flex-direction: column;
	gap: 20px;
	.policy-link-btn {
		color: #345088;
		font-family: Inter;
		font-size: 14px;
		font-style: normal;
		font-weight: 600;
		line-height: normal;
		text-decoration-line: underline;
	}
	.cancellation-card {
		display: flex;
		align-items: center;
		.cancellation-text {
			margin-left: 20px;
			color: #555;
			font-family: Inter;
			font-size: 14px;
			font-style: normal;
			font-weight: 400;
			line-height: normal;
		}
	}
	.date-card-wrapper {
		flex-shrink: 0;
		display: flex;
		flex-direction: column;
		border: 2px solid rgba(85, 85, 85, 1);
		border-radius: 2px;
		position: relative;
		&::after {
			content: "";
			position: absolute;
			height: 20.5px;
			bottom: -22px;
			left: 50%;
			width: 1px;
			background: #888;
		}
		span {
			display: flex;
			align-items: center;
			justify-content: center;
			color: #555;
			font-family: Inter;
			font-size: 9px;
			font-style: normal;
			font-weight: 400;
			line-height: normal;
			width: 50px;
			height: 15px;
		}
		p {
			display: flex;
			align-items: center;
			justify-content: center;
			font-family: Inter;
			color: #000;
			font-family: Inter;
			font-size: 16px;
			font-style: normal;
			font-weight: 400;
			line-height: normal;
			width: 50px;
			height: 35px;
		}
	}
	.full-refund {
		p {
			background-color: rgba(241, 251, 246, 1);
		}
		span {
			background-color: rgba(217, 236, 226, 1);
		}
	}
	.part-refund {
		span {
			background-color: rgba(252, 236, 200, 1);
		}
		p {
			background-color: rgba(255, 249, 235, 1);
		}
	}
	.no-refund {
		&::after {
			content: "";
			position: absolute;
			height: 0;
		}
		span {
			background-color: #fcdbc8;
		}
		p {
			background-color: #fff1e9;
		}
	}
}
