.registerHeader__form--header {
	display: flex;
	flex-direction: column;
	gap: 3px;
	margin-bottom: 5px;

	.registerHeader__header--title {

		font-size: 24px;
		line-height: 36px;
		font-weight: 700;
		color: var(--text-clr-grey-900);

		@media screen and (max-width: 500px) {

			font-size: 24px;
			font-weight: 700;
		}
	}

	.registerHeader__space-h {
		height: 3px;
	}

	.registerHeader__header--subtitle {
		color: var(--bg-clr-grey-600);

		line-height: 21px;
		font-size: 14px;
		font-weight: 600;
		color: var(--bg-clr-grey-600);

		@media screen and (max-width: 500px) {

			line-height: 21px;
			font-size: 14px;
		}
	}
}