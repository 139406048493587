.other__issue__two__container {
	display: flex;
	flex-direction: column;
	gap: 24px;
	width: 100%;
	max-width: 600px;
	margin: 0 auto;

	@media screen and (max-width: 768px) {
		max-width: 100%;
		padding: 0 16px;
	}

	h2 {
		color: #333333;
		font-size: 24px;
		font-weight: 700;
		line-height: 36px;

		@media screen and (max-width: 768px) {
			font-size: 16px;
			font-weight: 700;
			line-height: 24px;
		}
	}

	.write__issue {
		display: flex;
		flex-direction: column;
		gap: 6px;

		label {
			color: #999999;
			font-size: 14px;
			font-weight: 500;
			line-height: 21px;
		}

		textarea {
			width: 70%;
			border: 1px solid #dddddd;
			font-size: 16px;
			font-weight: 500;
			line-height: 24px;
			border-radius: 4px;
			padding: 17px 25px;
			height: 123px;

			@media screen and (max-width: 768px) {
				width: 100%;
				height: 133px;
				font-size: 14px;
				font-weight: 500;
				line-height: 21px;
			}
		}
	}
}
