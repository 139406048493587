.remember__checkbox {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;

	.remember__checkbox-input {
		margin-top: 10px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		max-width: 120px;
		width: 100%;

		label {
			display: flex;
			justify-content: flex-start;
			align-items: center;
			gap: 10px;

			input {
				cursor: pointer;
			}

			p {
				font-size: 14px;
				white-space: nowrap;
				color: var(--text-clr-grey-900);
				font-weight: 600;
			}
		}
	}

	.remember__forgot {
		cursor: pointer;

		p {
			font-size: 14px;
			color: var(--clr-primary);

			&:hover {
				text-decoration: underline;
				text-underline-offset: 2px;
			}
		}
	}
}