// Breakpoints
$breakpoint-mobile: 767px;
$breakpoint-ipad: 900px;
$ultrawide-max-width: 1650px;

// Mixins
@mixin hideBars {
	overflow-x: scroll;
	overflow-y: hidden;
	-ms-overflow-style: none;
	scrollbar-width: none;

	&::-webkit-scrollbar {
		display: none;
	}
}

@mixin blue-thumb-scrollbar {
	&::-webkit-scrollbar {
		height: 2px;
		width: 2px;
	}

	&::-webkit-scrollbar-track {
		background-color: var(--bg-clr-grey-400);
	}

	&::-webkit-scrollbar-thumb {
		background-color: var(--btn-clr-primary-500);
	}
}

@mixin full-width($horizontalPadding: 16px, $position: relative) {
	position: $position;
	margin-left: -$horizontalPadding;
	padding: 0 $horizontalPadding;
	min-width: calc(100% + #{$horizontalPadding} * 2);
}

//typography
h1 {
	font-family: var(--ff-heading);
}

h2 {
	font-family: var(--ff-heading);
	letter-spacing: 1px;
}

h3 {
	font-family: var(--ff-body);
}

h4 {
	font-family: var(--ff-body);
}

p,
a {
	font-family: var(--ff-body);
	line-height: 1.5;
}

.font-heading {
	font-family: var(--ff-heading);
}

.font-body {
	font-family: var(--ff-body);
}

.font-caption {
	font-family: var(--ff-caption);
}

.font-weight-500 {
	font-weight: 500;
}

.spacing-1 {
	letter-spacing: 1px;
}

.clr-blue {
	color: var(--clr-primary) !important;
}

.clr-grey {
	color: var(--text-clr-grey-600) !important;
}

.clr-black {
	color: var(--text-clr-grey-900) !important;
}

.clr-white {
	color: var(--clr-white) !important;
}

.text-12px-light {
	font-weight: 500;
	font-size: 12px;
}

.text-12px {
	font-weight: 600;
	font-size: 12px;
}

.text-12px-bold {
	font-weight: 700;
	font-size: 12px;
}

.text-xsm-light {
	font-weight: 500;
	font-size: 14px;
}

.text-xsm {
	font-weight: 600;
	font-size: 14px;
	line-height: 150%;
}

.text-xsm-bold {
	font-weight: 700;
	font-size: 14px;
}

.text-sm-light {
	font-weight: 500;
	font-size: 16px;
}

.text-sm {
	font-weight: 600;
	font-size: 16px;
}

.text-sm-bold {
	font-weight: 700;
	font-size: 16px;
}

.text-sm-bold-ex {
	font-weight: 700;
	font-size: 16px;
}

.sub-heading-light {
	font-weight: 600;
	font-size: 20px;
	color: var(--text-clr-900);
}

.sub-heading {
	font-weight: 600;
	font-size: 20px;
	color: var(--text-clr-900);
}

.sub-heading-bold {
	font-weight: 700;
	font-size: 20px;
	color: var(--text-clr-900);
}

// listings
@media screen and (max-width: $breakpoint-mobile) {
	.listing__outer-wrapper {
		.text-xsm,
		.text-xsm-light,
		.text-xsm-bold {
			font-size: 12px;
		}

		.text-sm,
		.text-sm-light,
		.text-sm-bold {
			font-size: 14px;
		}

		h2 {
			font-size: 24px;
		}

		h3 {
			font-size: 18px;
		}

		.sub-heading,
		.sub-heading-light,
		.sub-heading-bold {
			font-size: 16px;
		}
	}
}

// booking price component
@media screen and (max-width: $breakpoint-mobile) {
	.booking__price {
		.text-xsm,
		.text-xsm-light,
		.text-xsm-bold {
			font-size: 14px;
		}

		.text-12px {
			font-weight: 600;
			font-size: 10px;
		}

		.text-sm,
		.text-sm-light,
		.text-sm-bold {
			font-size: 14px;
		}

		h2 {
			font-size: 24px;
		}

		h3 {
			font-size: 20px;
		}

		.sub-heading {
			font-size: 16px;
		}

		.sub-heading-bold {
			font-size: 16px;
		}

		.sub-heading-light {
			font-size: 16px;
			font-weight: 500;
		}
	}
}

// booking detail component
@media screen and (max-width: $breakpoint-mobile) {
	.bookingphone__details-detail {
		.text-xsm,
		.text-xsm-light,
		.text-xsm-bold {
			font-size: 14px;
		}

		.text-12px {
			font-weight: 600;
			font-size: 10px;
		}

		.text-sm,
		.text-sm-light,
		.text-sm-bold {
			font-size: 14px;
		}

		h2 {
			font-size: 24px;
		}

		h3 {
			font-size: 20px;
		}

		.sub-heading {
			font-size: 16px;
		}

		.sub-heading-bold {
			font-size: 16px;
		}

		.sub-heading-light {
			font-size: 16px;
			font-weight: 500;
		}
	}
}
