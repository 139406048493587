.featured__card {
	display: flex;
	flex-direction: column;
	width: 400px;
	min-width: 400px;
	background: white;
	border-radius: 10px;
	border: 1px #dddddd solid;
	cursor: pointer;
	transition: transform 350ms ease-in-out;

	&:hover,
	&:focus {
		@media screen and (min-width: 500px) {
			transform: scale(1.03);
		}
	}

	@media screen and (max-width: 500px) {
		min-width: 292px;
	}

	.featured__card-img {
		position: relative;
		background: #f2f2f2;
		width: 100%;
		border-radius: 10px 10px 0 0;
		height: 228px;

		@media screen and (max-width: 500px) {
			height: 175px;
		}

		.featured-card-heartIcon {
			position: absolute;
			z-index: 1;
			top: 10px;
			right: 10px;

			span {
				display: flex;
				align-items: center;
				justify-content: center;
				gap: 8px;
				width: 86px;
				height: 36px;
				border-radius: 35px;
				background-color: var(--bg-white);

				@media screen and (max-width: 500px) {
					width: 77px;
					height: 30px;
				}

				p {
					color: var(--text-clr-grey-900);

					font-size: 14px;
					font-style: normal;
					font-weight: 600;
					line-height: 21px;
					letter-spacing: 0em;

					@media screen and (max-width: 500px) {
						font-size: 12px;
						line-height: 18px;
					}
				}
			}
		}

		.featured-card-signature {
			position: relative;

			.icon-signature {
				position: absolute;
				inset: 0 0 0 -15px;
				z-index: 1;

				@media screen and (max-width: 500px) {
					inset: 2px 0 0 -30px;
				}
			}

			.icon-rare {
				position: absolute;
				right: -9px;
				bottom: -345px;
				z-index: 1;

				@media screen and (max-width: 500px) {
					display: none;
				}
			}

			.icon-sale {
				position: absolute;
				left: 8px;
				bottom: -220px;
				z-index: 1;

				@media screen and (max-width: 500px) {
					bottom: -167px;
				}

				.sale {
					background: linear-gradient(135.77deg, #ff3f5d 3.42%, #ff8267 96.57%);
					font-size: 10px;
					font-weight: 500;
					line-height: 12px;
					letter-spacing: 0.1em;
					text-align: left;
					border-radius: 4px;
					color: #ffffff;
					padding: 6px;

					@media screen and (max-width: 500px) {
						padding: 4px;
						font-size: 8px;
						line-height: 10px;
					}
				}
			}

			.icon-newHome {
				position: absolute;
				inset: 170px 0 0 -15px;
				z-index: 1;

				@media screen and (max-width: 500px) {
					inset: 130px 0 0 -15px;
				}
			}

			.icon-avail {
				position: absolute;
				right: 10px;
				bottom: -235px;
				z-index: 1;

				.avail {
					border-radius: 35px;
					background-color: #e7f9e7;
					color: #11bf0e;
					padding: 5px 12px;
					font-size: 10px;
					font-weight: 600;
					line-height: 15px;
					border: 1px solid rgba(17, 191, 14, 0.2);
				}

				.not-avail {
					border-radius: 35px;
					background-color: #fff4f4;
					color: #fa4b4b;
					padding: 5px 12px;
					font-size: 10px;
					font-weight: 600;
					line-height: 15px;
					border: 1px solid rgba(250, 75, 75, 0.2);
				}

				@media screen and (max-width: 500px) {
					right: 10px;
					bottom: -185px;
				}
			}
		}

		img {
			border-radius: 10px 10px 0px 0px;
			max-width: 100%;
			max-height: 228px;
			object-fit: cover;

			@media screen and (max-width: 500px) {
				max-height: 175px;
			}

			-webkit-user-select: none;
			/* Safari */
			-ms-user-select: none;
			/* IE 10 and IE 11 */
			user-select: none;
			/* Standard syntax */
		}

		// width: 100%;
		.slick-prev {
			opacity: 0;
			left: 6px;
			z-index: 1;
			height: 48px;
			width: 48px;

			@media screen and (max-width: 767px) {
				opacity: 0 !important;
			}
		}

		.slick-next {
			opacity: 0;
			right: 8px;
			height: 48px;
			width: 48px;

			@media screen and (max-width: 767px) {
				opacity: 0 !important;
			}
		}
	}

	.card-link {
		text-decoration: none !important;
		color: var(--text-clr-grey-900);

		.featured__card-middle {
			display: flex;
			justify-content: space-between;
			align-items: center;
			gap: 0.5rem;
			position: relative;

			.featured__card-context-container {
				display: flex;
				flex-direction: column;
				gap: 0.5rem;
				padding: 0 20px;

				.featured__card-title {
					margin-top: 10px;

					h2 {
						width: 100%;
						max-width: 320px;
						color: var(--text-clr-grey-900);
						white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis;
						font-size: 24px;
						font-weight: 700;
						line-height: 38px;
					}

					@media screen and (max-width: 600px) {
						font-size: 20px;
						line-height: 30px;
					}

					@media screen and (max-width: 500px) {
						font-size: 14px;
						line-height: 24px;
						max-width: 260px;
					}
				}

				.featured__card-location {
					display: flex;
					gap: 0.4rem;

					span {
						svg {
							width: 9px;
							height: 13px;
						}
					}

					p {
						font-size: 14px;
						font-weight: 600;
						line-height: 21px;
						color: var(--clr-primary);

						@media screen and (max-width: 600px) {
							font-size: 12px;
						}

						@media screen and (max-width: 300px) {
							font-size: 10px;
						}
					}
				}

				.featured__card-benefits {
					display: flex;
					align-items: center;
					gap: 0.5rem;
					margin: 0.4rem 0 0 0;
					height: 40px;

					@media screen and (max-width: 500px) {
						height: 30px;
					}

					@media screen and (max-width: 300px) {
						margin: 0.1rem 0 0 0;
					}

					div {
						display: flex;
						align-items: center;
						justify-content: center;
						padding: 10px;
						border-radius: 100%;

						svg {
							width: 20px;
							height: 20px;

							@media screen and (max-width: 500px) {
								height: 15px;
								width: 15px;
							}
						}
					}

					.breakfast-icon {
						border-radius: 35px;
						height: 30px;
						background: rgba(237, 242, 255, 0.9);
						padding: 0 8px 0 0;

						.icon {
							display: flex;
							align-items: center;
							justify-content: center;
							padding: 4px;
							border-radius: 100%;
							border: 1px solid #2d66a1;

							svg {
								width: 18px;
								height: 18px;

								@media screen and (max-width: 500px) {
									height: 15px;
									width: 15px;
								}
							}
						}

						p {
							font-size: 12px !important;
							font-weight: 600;
							line-height: 15px;
							margin-left: 5px;

							@media screen and (max-width: 500px) {
								font-size: 7px;
								font-weight: 600;
								line-height: 10px;
								margin-left: 3px;
							}
						}
					}
				}

				.featured__card-type {
					display: flex;

					div {
						padding: 0 0.4rem;

						p {
							font-size: 10px;
							font-weight: 500;
							line-height: 15px;
							color: var(--bg-clr-grey-600);
						}
					}

					div:nth-of-type(1) {
						padding: 0 0.4rem 0 0;
					}

					div:nth-of-type(2) {
						border-left: 1px #dddddd solid;
						border-right: 1px #dddddd solid;
					}
				}

				.featured__card-reviews {
					display: flex;
					align-items: center;
					gap: 0.5rem;
					margin: 0.8rem 0;

					@media screen and (max-width: 300px) {
						margin: 0.4rem 0;
					}

					.reviews-rating {
						p {
							color: var(--text-clr-grey-900);

							font-size: 14px;
							font-weight: 700;
							line-height: 21px;

							@media screen and (max-width: 600px) {
								font-size: 12px;
								line-height: 14px;
							}

							@media screen and (max-width: 300px) {
								line-height: 10px;
								font-size: 8px;
							}
						}
					}

					.reviews-stars {
						padding-right: 10px;
						border-right: 1px #dddddd solid;
					}

					.no-reviews {
						padding: 0 5px;

						p {
							color: var(--bg-clr-grey-600);

							font-size: 12px;
							font-weight: 500;
							line-height: 21px;

							@media screen and (max-width: 600px) {
								font-size: 12px;
								line-height: 14px;
							}

							@media screen and (max-width: 300px) {
								line-height: 10px;
								font-size: 8px;
							}
						}
					}
				}
			}

			.featured__card-ta-container {
				display: flex;
				flex-direction: column;
				gap: 0.5rem;
				position: absolute;
				top: 50%;
				right: 0;

				@media screen and (max-width: 767px) {
					top: 55%;
				}

				.featured__card-ta-user {
					padding: 10px 16px;
					background: linear-gradient(131.5deg, #ebf3ff 1.85%, #d8e0ff 100%);
					border-radius: 10px 0px 0px 10px;

					.featured__card-ta-user-para {
						font-weight: 600;
						font-size: 10px;
						line-height: 1.5;
						color: var(--text-clr-grey-600);
					}

					.featured__card-ta-user-price {
						font-weight: 600;
						font-size: 14px;
						line-height: 1.5;
						color: var(--text-clr-grey-900);
					}
				}
			}

			.featured__card-free-cancellation {
				display: flex;
				flex-direction: column;
				gap: 0.5rem;
				position: absolute;
				top: 50%;
				right: -2.5%;
			}
		}

		.featured__card-bottom {
			border-top: 1px #dddddd solid;
			padding: 10px 16px;

			.featured__card-button {
				display: flex;
				justify-content: space-between;
				align-items: center;

				div {
					display: flex;
					align-items: center;

					@media screen and (max-width: 700px) {
						flex-direction: column;
						order: 1;
						align-items: flex-start;
					}

					div {
						display: flex;
						flex-direction: column;
						align-items: flex-start;

						p {
							color: var(--text-clr-grey-600);
							font-size: 8px;
							line-height: unset;
							font-weight: 600;
						}

						h2 {
							display: flex;
							align-items: center;
							gap: 10px;
							font-size: 16px;
							font-weight: 700;
							line-height: 24px;
							letter-spacing: 0;

							color: var(--text-clr-grey-900);

							@media screen and (max-width: 700px) {
								font-size: 14px;
								line-height: 1.5;
							}

							@media screen and (max-width: 300px) {
								font-size: 12px;
								line-height: 1.5;
							}
						}
					}

					.featured__card-discount {
						margin-left: 10px;
						position: relative;
						font-size: 14px;
						top: -5px;
						font-weight: 700;
						line-height: 24px;

						color: var(--bg-clr-grey-600);

						hr {
							position: absolute;
							width: 100%;
							top: 50%;
							border: none;
							height: 0;
							border-top: 1px solid #666666;
						}

						@media screen and (max-width: 700px) {
							margin-left: 0px;
							font-size: 12px;
							line-height: 14px;
						}

						@media screen and (max-width: 300px) {
							line-height: 12px;
							font-size: 10px;
						}
					}

					span {
						font-size: 10px;
						font-weight: 500;
						line-height: 15px;
					}
				}

				.link-button {
					background-color: var(--bg-white);
					border-radius: 4px;
					border: 2px solid var(--brd-clr-primary);
					color: var(--clr-primary);
					font-family: var(--ff-body);
					font-size: 14px;
					font-weight: 600;
					padding: 0.8rem 2rem;

					@media screen and (max-width: 700px) {
						padding: 0.6rem 1.2rem;
					}

					&:hover {
						background: var(--gradient-primary);
						color: white;
						border: 2px white solid;
					}
				}
			}
		}
	}

	.button__bar {
		position: absolute;
		top: 200px;
		left: 29%;
		display: inline-block;
		vertical-align: middle;
		margin: auto;
		padding: 0;
		border-radius: 40px;
		width: 40%;
		text-align: center;

		@media screen and (max-width: 500px) {
			left: 90px;
			top: 150px;
		}
	}
}

.slick-arrow {
	opacity: 0;
	transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-webkit-transition: all 0.3s ease-in-out;

	&:hover {
		opacity: 1;
	}
}

// react-slick

.slick-slider {
	&:hover {
		.slick-arrow {
			opacity: 1;
		}
	}
}

// .slick-dots {
//   top: 300px; // play with the number of pixels to position it as you want
// }

// react-slick dots

.button__bar li {
	list-style: none;
	cursor: pointer;
	display: inline-block;
	margin: 0 3px;
	padding: 0;
}

.button__bar li button {
	border: none;
	background: var(--bg-white);
	opacity: 0.6;
	color: transparent;
	cursor: pointer;
	display: block;
	height: 7px;
	width: 7px;
	border-radius: 7.5px;
}

.button__bar li.slick-active button {
	background-color: var(--bg-white);
	opacity: 1;
	transform: scale(1.2);
}

.button__bar li button:hover,
.button__bar li button:focus {
	background: var(--bg-white);
	outline: 0;
}

.slick-slide img {
	display: block;
	margin: auto;
	z-index: -100;

	@media screen and (max-width: 500px) {
		min-height: 175px;
	}
}

[data-title] {
	font-size: 30px;
	/*optional styling*/
	position: relative;
}

[data-title]:hover::before {
	content: attr(data-title);
	position: absolute;
	bottom: -26px;
	display: inline-block;
	padding: 3px 6px;
	border-radius: 2px;
	background: #000;
	color: #fff;
	font-size: 12px;
	font-family: sans-serif;
	white-space: nowrap;
}

[data-title]:hover::after {
	content: "";
	position: absolute;
	bottom: -10px;
	left: 8px;
	display: inline-block;
	color: #fff;
	border: 8px solid transparent;
	border-bottom: 8px solid #000;
}
