.personal__step__two__container {
	width: 100%;
	// max-width: 600px;
	margin: 0 auto;

	@media screen and (max-width: 768px) {
		max-width: 100%;
		padding: 0 16px;
		margin-bottom: 50px;
	}

	h1 {
		color: #333333;
		font-size: 24px;
		font-weight: 700;
		line-height: 36px;
		text-align: left;

		@media screen and (max-width: 768px) {
			font-size: 16px;
			font-weight: 700;
			line-height: 24px;
		}
	}

	.steps {
		display: flex;
		gap: 20px;
		border-bottom: 1px solid #dddddd;
		margin-top: 20px;

		.tabs {
			padding: 10px;
			color: #666666;
			font-size: 16px;
			font-weight: 600;
			line-height: 24px;
			cursor: pointer;

			@media screen and (max-width: 768px) {
				font-size: 12px;
				font-weight: 700;
				line-height: 18px;
				padding: 6px;
			}
		}

		.tabs__active {
			color: #2d66a1;
			border-bottom: 3px solid #2d66a1;
		}
	}

	.bottom__steps__container {
		margin-top: 24px;
		width: 100%;
		max-width: 720px;
	}
}
