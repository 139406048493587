.home__navbar__search__container {
	width: 100%;
	margin-bottom: 10px;
	position: relative;
	cursor: pointer;

	@media screen and (max-width: 1080px) {
		padding: 10px 16px 0 16px;
	}

	.navbar__search {
		width: 100%;
		margin: 0 auto;
		background-color: transparent;
		height: 100%;

		input::placeholder {
			color: #333333;
			font-weight: 400;
		}

		.search__container {
			display: flex;
			justify-content: space-between;
			width: 90%;
			max-width: max-content;
			margin: 0 auto;
			border: 1px solid #dddddd;
			background: #f2f2f2;
			// background: #ffffff;
			padding: 14px 10px;
			border-radius: 40px;
			transition: border 0.3s ease-in-out;
			box-shadow: 0 1px 2px rgb(0 0 0 / 8%), 0 4px 12px rgb(0 0 0 / 5%);
			transition: box-shadow 0.3s ease;

			&:hover {
				box-shadow: 1px 3px 4px rgb(0 0 0 / 8%), 0 4px 12px rgb(0 0 0 / 5%);
			}

			@media screen and (max-width: 1080px) {
				width: 100%;
				max-width: 100%;
			}

			&:has(input:focus) {
				border: 2px solid #2d66a1;
			}

			.add__guest__text {
				color: #333;
				width: 100%;
				font-size: 14px;
				font-weight: 400;
				line-height: 21px;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				text-align: center;

				@media screen and (max-width: 768px) {
					font-size: 12px;
					font-weight: 600;
					line-height: 17px;
				}
			}

			.guest__text__active {
				font-weight: 600;
			}

			.left {
				width: 100%;
				display: flex;

				@media screen and (max-width: 768px) {
					justify-content: space-between;
				}
			}

			.block {
				span {
					color: #666666;
					font-size: 12px;
					font-weight: 500;
					line-height: 18px;
					white-space: nowrap;

					@media screen and (max-width: 768px) {
						font-size: 10px;
						font-weight: 500;
						line-height: 14px;
					}
				}
			}

			.search__input {
				position: relative;
				display: flex;
				flex-direction: column;
				gap: 2px;
				width: max-content;
				padding-left: 10px;

				input {
					outline: none;
					border: none;
					color: #333;
					font-size: 14px;
					font-weight: 600;
					line-height: 21px;
					background: none;

					@media screen and (max-width: 768px) {
						font-size: 12px;
						font-weight: 600;
						line-height: 17px;
						white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis;
					}
				}

				.location-popup-wrapper {
					min-width: 480px;
				}
			}

			.search__date {
				width: max-content;
				padding: 0 12px;
				border-inline: 1px solid #dddddd;
				margin-inline: 12px;

				.search__checkin__checkout {
					display: flex;
					align-items: center;
					justify-content: center;
					white-space: nowrap;

					.line {
						display: flex;
						align-items: center;
						justify-content: center;
						width: 10%;
					}

					.add__date {
						display: flex;
						align-items: flex-start;
						flex-direction: column;
						gap: 2px;
						width: 100%;

						input {
							outline: none;
							width: 100%;
							border: none;
							font-size: 14px;
							font-weight: 600;
							line-height: 21px;
							background: none;
							cursor: pointer;
							caret-color: transparent;

							@media screen and (max-width: 768px) {
								font-size: 12px;
								font-weight: 600;
								line-height: 17px;
								// white-space: nowrap;
								// overflow: hidden;
								// text-overflow: ellipsis;
							}
						}
					}
				}
			}

			.search__guest {
				position: relative;
				display: flex;
				align-items: flex-start;
				flex-direction: column;
				gap: 2px;
				width: max-content;
				cursor: pointer;
				padding-right: 10px;

				@media screen and (max-width: 768px) {
					padding-left: 8px;
				}
			}
			.btn__search {
				all: unset;
				display: flex;
				align-items: center;
				justify-content: center;
				padding: 12px;
				border-radius: 50px;
				background-color: #2d66a1;
				cursor: pointer;
				color: #fff;
				font-size: 16px;
				font-weight: 500;
				line-height: 18px;

				svg {
					fill: #fff;
					height: 10px;
				}
			}
		}
	}
}

// .navbar__reanimate {
// 	animation-name: hide-seek;
// 	animation-duration: 5s;
// 	transition: all 5s ease-in;

// 	.navbar__search {
// 		animation-name: hide-seek;
// 		animation-duration: 5s;
// 		transition: all 5 ease-in;

// 		.search__container {
// 			animation-name: width;
// 			animation-duration: 5s;
// 			transition: all 5 ease-in;

// 			@keyframes width {
// 				from {
// 					width: 100%;
// 				}
// 				to {
// 					width: 60%;
// 				}
// 			}
// 		}
// 	}

// 	@keyframes hide-seek {
// 		from {
// 			height: 0%;
// 			top: 80px;
// 		}
// 		to {
// 			height: 10%;
// 			top: 0;
// 		}
// 	}
// }

.home__navbar__search__container__hide {
	animation-name: hide-seek;
	animation-duration: 0.5s;
	transition: all 0.5 ease-in;
	opacity: 0;
}
