.want__to__register__container {
	width: 100%;
	max-width: 600px;
	margin: 60px auto;

	.top {
		margin-bottom: 10px;

		h2 {
		}

		@media screen and (max-width: 768px) {
			margin-bottom: 0;
		}
	}

	.bottom {
		@media screen and (max-width: 768px) {
			padding-top: 0;
		}
	}

	h1 {
		font-size: 16px;
		font-weight: 700;
		line-height: 24px;
	}

	.bottom__btn {
		display: flex;
		justify-content: space-between;
		margin-top: 20px;

		.btn__continue {
			all: unset;
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 14px 29px;
			cursor: pointer;
			border-radius: 100px;
			background: #2d66a1;
			color: #ffffff;
			font-size: 14px;
			font-weight: 600;
			line-height: 21px;
			width: max-content;

			@media screen and (max-width: 768px) {
				padding: 10px 20px !important;
				font-size: 12px;
				font-weight: 600;
				line-height: 16px;
				white-space: nowrap;
			}
		}
	}

	.search__villa {
		display: flex;
		flex-direction: column;
		gap: 6px;
		width: 100%;

		.small__box__container {
			display: flex;
			gap: 8px;

			.small__box {
				padding: 8px 12px;
				border-radius: 4px;
				font-size: 12px;
				font-weight: 600;
				line-height: 18px;
				background: #edf2ff;
				cursor: pointer;

				svg {
					width: 10px;
					height: 10px;
					margin-left: 8px;
				}
			}
		}

		label {
			color: #999999;
			font-size: 14px;
			font-weight: 500;
			line-height: 21px;

			@media screen and (max-width: 768px) {
			}
		}

		.span {
			position: relative;

			svg {
				top: 20px;
				position: absolute;
				left: 20px;

				@media screen and (max-width: 768px) {
					width: 15px;
					height: 15px;
					top: 16px;
				}
			}

			input {
				width: 100%;
				padding: 17px 26px 17px 52px;
				border-radius: 4px;
				border: 1px solid #dddddd;
				color: #333333;
				font-size: 16px;
				font-weight: 400;
				line-height: 24px;
				letter-spacing: 0em;
				text-align: left;

				@media screen and (max-width: 768px) {
					padding: 13px 15px 13px 43px;
					font-size: 14px;
					font-weight: 500;
					line-height: 21px;
				}
			}
		}
	}
}
