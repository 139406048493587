@import url(https://fonts.googleapis.com/css?family=Inter:100,200,300,regular,500,600,700,800,900);
.claim-friend-coupon-wrapper {
	width: 100%;
	min-height: 70vh;
	height: auto;
	max-height: auto;
	display: flex;
	align-items: center;
	@media screen and (max-width: 800px) {
		padding: 10px;
	}
	.claim-friend-coupon-content {
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		.claim-friend-coupon-header {
			margin-bottom: 60px;
			h3 {
				width: 496px;
				color: var(--newui-2021-black-grays-dark-text, #333);
				text-align: center;
				font-family: Inter;
				font-size: 24px;
				font-style: normal;
				font-weight: 700;
				line-height: 150%; /* 36px */
				@media screen and (max-width:800px) {
					width: 90vw;
				}
			}
		}
		.claim-friend-coupon-details {
			width: 100%;
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
			align-items: center;
			gap: 80px;
			.coupon-card-wrapper {
				width: 411px;
				height: 221px;
				border-radius: 10px;
				border: 1px solid var(--Border-Extra-Dark, #ccc);
				background: #fff;
				.coupon-card-content {
					width: 100%;
					height: 100%;
					display: flex;
					flex-direction: column;
					gap: 20px;
					.coupon-card-header {
						border-radius: 10px 10px 0px 0px;
						border: 1px solid var(--Border-Extra-Dark, #ccc);
						background: #efefef;

						h3 {
							color: #333;
							font-family: Inter;
							font-size: 20px;
							font-style: normal;
							font-weight: 700;
							line-height: 150%; /* 30px */
							padding: 13px 23px;
						}
					}
					.coupon-card-mid {
						width: 100%;
						display: flex;
						justify-content: center;
						p {
							width: 214px;
							color: #888;
							text-align: center;
							font-family: Inter;
							font-size: 14px;
							font-style: normal;
							font-weight: 500;
							line-height: 150%; /* 21px */
						}
					}
					.coupon-card-bottom {
						margin: 0 23px;
						height: 60px;
						border-radius: 29.5px;
						border: 1px dashed #999;
						display: flex;
						justify-content: space-between;
						align-items: center;
						padding-left: 30px;
						padding-right: 5px;
						p {
							color: #000;
							text-align: center;
							font-family: Inter;
							font-size: 20px;
							font-style: normal;
							font-weight: 700;
							line-height: 150%; /* 30px */
						}
						button {
							display: flex;
							width: 100px;
							height: 50px;
							padding: 11px 20px;
							justify-content: center;
							align-items: center;
							gap: 8px;
							flex-shrink: 0;
							border-radius: 1000px;
							background: var(--Primary, #2368a2);
							color: #fff;
							text-align: center;
							font-family: Inter;
							font-size: 16px;
							font-style: normal;
							font-weight: 600;
							line-height: 150%; /* 24px */
							outline: none;
							border: none;
						}
					}
				}
			}
		}
	}
}
