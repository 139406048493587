.navbar {
	position: absolute;
	display: flex;
	justify-content: center;
	// border-bottom: 1px rgba(255, 255, 255, 0.1) solid;

	height: 90px;
	width: 100%;
	z-index: 5;

	&-wrapper {
		display: flex;
		justify-content: space-between;
		height: 90px;
		align-items: center;
		width: 100%;
		max-width: 1270px;

		@media screen and (max-width: 1260px) {
			padding: 0;
		}

		.navbar-logo {
			@media screen and (max-width: 750px) {
				margin: 0 auto;
			}
		}

		.navbar__destination-search {
			display: flex;
			justify-content: space-between;
			align-items: center;
			gap: 40px;
			border-radius: 4px;
			padding: 10px 10px;
			background-color: #f9f9f9;
			border: 1px #dddddd solid;

			p {
				color: var(--text-clr-grey-400);
				font-size: 14px;
				font-weight: 500;
			}
		}

		.navbar__premium-links {
			display: flex;
			height: 100%;

			.navbar__premium-signature {
				display: flex;
				align-items: center;
				justify-content: center;
				width: 200px;
				height: 100%;
				border-left: 1px rgba(255, 255, 255, 0.3) solid;
				cursor: pointer;

				&:hover {
					background: #183e3f;
				}
			}

			.navbar__premium-xseries {
				display: flex;
				align-items: center;
				justify-content: center;
				width: 200px;
				height: 100%;
				border-left: 1px rgba(255, 255, 255, 0.3) solid;
				border-right: 1px rgba(255, 255, 255, 0.3) solid;
				cursor: pointer;

				&:hover {
					background-color: var(--bg-black);
				}
			}
		}

		.navbar__links-box {
			display: flex;
			align-items: center;
			gap: 2.2rem;
			height: 100%;

			.navbar-link {
				position: relative;
				cursor: pointer;
				height: 100%;

				.nav-link {
					font-weight: 400;
					font-size: 14px;
					font-family: var(--ff-body);
					font-style: normal;
					display: flex;
					align-items: center;
					justify-content: center;
					// width: 140px;
					// gap: 0.5rem;
					// padding: 0.3rem 0rem;
					height: 30px;
					margin-top: 30px;
					transition: 0.3s;
					border: 2px solid transparent;

					&:hover {
						border-bottom: 2px solid #fba919;
					}

					p {
						text-decoration: none;
						color: #fff;
						font-weight: 600;
						font-size: 14px;
					}
				}

				a {
					text-decoration: none;
					color: #fff;
					font-weight: 600;
					font-size: 14px;
				}
			}

			.navbar-link-button {
				all: unset;
				display: flex;
				align-items: center;
				justify-content: center;

				width: 150px;
				height: 48px;
				background: #ffffff;
				border-radius: 30px;
				color: #111111;
				font-weight: 500;
				font-size: 14px;
				font-family: var(--ff-body);
				font-style: normal;
				cursor: pointer;
				transition: 0.3s;

				&:hover {
					color: #ffffff;
					background: #2368a2;
				}
			}

			.changeButton {
				background: #ffffff;
				color: #2368a2;
				border: 1px solid transparent;
				border: 1px #2368a2 solid;

				&:hover {
					// border: 1px #2368a2 solid;
					color: #ffffff;
					background: #2368a2;
				}
			}

			.navbar-profile-dropdown {
				position: relative;
				display: flex;
				align-items: center;

				.profile__user-detail {
					display: flex;
					align-items: center;
					width: 170px;
					gap: 10px;
					cursor: pointer;
					padding: 24px 10px;
					border-bottom: 5px solid transparent;

					.user-img {
						width: 38px;
						height: 38px;
						border-radius: 100%;

						img {
							width: 100%;
							height: 100%;
							border-radius: inherit;
						}
					}

					.user-detail {
						display: flex;
						flex-direction: column;
						gap: 2px;

						h5 {
							color: #fff;
							font-size: 14px;
							font-weight: 700;
							line-height: 21px;
							white-space: nowrap;
						}

						p {
							color: #fff;
							font-size: 10px;
							font-weight: 500;
							line-height: 15px;
						}
					}
				}

				.user__dropdown-box {
					position: absolute;
					display: flex;
					flex-direction: column;
					gap: 5px;
					left: -98px;
					top: 100%;
					width: 268px;
					padding: 24px 12px 18px 12px;
					border-radius: 0 0 10px 10px;
					background-color: rgb(255, 255, 255);
					box-shadow: 0px 4px 15px 0px #33333366;
					z-index: 1000;

					.dropdown-link {
						display: flex;
						align-items: center;
						gap: 16px;
						padding: 7px 32px;
						border-radius: 4px;
						text-decoration: none;

						svg {
							width: 20px;
							height: 20px;
						}

						.svg-wishlist {
							fill: #666666;
						}

						p {
							color: var(--text-clr-grey-900);
							font-size: 16px;
							font-weight: 500;
							line-height: 24px;
						}

						&:hover {
							background-color: #edf2ff;
							border-radius: 4px;
						}
					}

					hr {
						margin: 5px 0;
						border: 1px #dddddd solid;
					}

					.btn-logout {
						all: unset;
						display: flex;
						align-items: center;
						gap: 16px;
						padding: 7px 32px;
						color: var(--clr-black);
						font-size: 1rem;
						font-weight: 500;
						line-height: 1.5;
						cursor: pointer;

						&:hover {
							background-color: #edf2ff;
							border-radius: 4px;
						}
					}
				}
			}
		}
	}
}