.marketing-feature-wrapper {
	display: flex;
	flex-direction: column;
	gap: 50px;
	.hero-wrapper {
		height: 100vh;
		width: 100%;
		background: linear-gradient(
				0deg,
				rgba(0, 0, 0, 0.4) 0%,
				rgba(0, 0, 0, 0.4) 100%
			),
			url("https://ik.imagekit.io/5tgxhsqev/saffronstays-media/tr:q-25,f-webp/image/upload/rnqaffys5vylvdhqnsmv")
				lightgray 50% / cover no-repeat;

		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		#logo {
			position: absolute;
			left: 5%;
			top: 5%;
			@media screen and (max-width: 800px) {
				transform: translate(-50%, -50%) scale(0.8);
				left: 50%;
			}
		}
		h1 {
			color: #fff;
			text-align: center;
			font-family: Catamaran;
			font-size: 60px;
			font-style: normal;
			font-weight: 500;
			@media screen and (max-width: 800px) {
				width: 90%;
				font-size: 48px;
				line-height: 60px;
			}
		}
		p {
			color: #fff;
			text-align: center;
			font-family: Inter;
			font-size: 24px;
			font-style: normal;
			font-weight: 400;
			line-height: normal;
			margin-top: 17px;
			@media screen and (max-width: 800px) {
				width: 90%;
				font-size: 18px;
			}
		}
		button {
			color: var(--Primary, #3d2b3d);
			text-align: center;
			font-family: Inter;
			font-size: 14px;
			font-style: normal;
			font-weight: 600;
			line-height: 150%; /* 21px */
			border-radius: 36px;
			background: #fef7ea;
			padding: 16px 40px;
			margin-top: 74px;
			border: none;
			outline: none;
		}
	}
}

.lead-feature-form-wrapper {
	display: grid;
	grid-template-columns: 1fr 1fr;
	width: 100%;
	max-width: 900px;
	border-radius: 15px;
	background: #fff;
	padding: 10px 15px;
	height: 100vh;
	max-height: 80vh;
	overflow-y: auto;
	gap: 100px;

	@media screen and (max-width: 800px) {
		grid-template-columns: 1fr;
		width: 100vw;
		border-radius: 15px 15px 0 0;
		bottom: 0;
		position: absolute;
		left: 0;
		overflow-y: auto;
	}
	.form-wrapper {
		width: 100%;
		padding-left: 65px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		@media screen and (max-width: 800px) {
			padding: 0;
			margin-bottom: 50px;
			align-items: center;
		}
		.progress-bar-uploading {
			display: flex;
			align-items: center;
			width: 100%;
			height: 4px;
			background: rgba(128, 128, 128, 0.589);
			.progress-bar {
				display: flex;
				height: 100%;
				background: #fba919;
				border-radius: 2px;
				transition: 0.3s linear;
			}
		}
		.boarding-pass-confirm {
			display: flex;
			align-items: center;
		}
		.boarding-pass-preview {
			display: flex;
			width: 100%;
			gap: 10px;
			text-decoration: none;
			color: grey;
			img {
				width: 50px;
				height: 50px;
				object-fit: cover;
				flex-shrink: 0;
				display: block !important;
				border-radius: 4px;
			}
			.boarding-pass-container {
				width: 100%;
				display: flex;
				align-items: center;
				justify-content: space-between;
			}
		}
		.boarding-pass-wrapper {
			border-radius: 15px;
			border: 1px dashed #d3d3d3;
			padding: 10px;
			display: flex;
			align-items: center;
			justify-content: center;
			gap: 6px;
			flex-direction: column;
			cursor: pointer;
			span {
				color: #000;
				font-family: Inter;
				font-size: 12px;
				font-style: normal;
				font-weight: 400;
				line-height: normal;
				b {
					color: #3d58db;
				}
			}
			.input-title {
				color: #333;
				font-family: Inter;
				font-size: 14px;
				font-style: normal;
				font-weight: 400;
				line-height: normal;
				margin-top: 20px;
			}
			.input-sub-title {
				color: rgba(51, 51, 51, 0.6);
				font-family: Inter;
				font-size: 10px;
				font-style: normal;
				font-weight: 400;
				line-height: normal;
			}
		}
		p {
			color: rgba(51, 51, 51, 0.6);
			font-family: Inter;
			font-size: 12px;
			font-style: normal;
			font-weight: 400;
			line-height: normal;
		}
		h2 {
			color: #000;
			font-family: Catamaran;
			font-size: 30px;
			font-style: normal;
			font-weight: 500;
			line-height: normal;
			margin-top: 16px;
		}
		p {
			color: #333;
			font-family: Inter;
			font-size: 16px;
			font-style: normal;
			font-weight: 400;
			line-height: normal;
			margin-top: 7px;
			margin-bottom: 16px;
		}
		form {
			display: flex;
			flex-direction: column;
			border-top: 1px solid rgba(136, 136, 136, 0.37);
			gap: 16px;
			padding-top: 16px;
			@media screen and (max-width: 800px) {
				width: 100%;
			}
		}
		.form-input-wrapper-row {
			display: flex;
			gap: 10px;
		}
		.form-input-wrapper {
			display: flex;
			flex-direction: column;
			gap: 8px;
			width: 100%;
			span {
				color: #000;
				font-family: Roboto;
				font-size: 12px;
				font-style: normal;
				font-weight: 400;
				line-height: normal;
			}
			input {
				width: 100%;
				border-radius: 5px;
				border: 1px solid #888;
				font-family: Inter;
				font-size: 12px;
				font-style: normal;
				font-weight: 500;
				line-height: normal;
				padding: 12px;
				-webkit-appearance: none;
				background: none;
			}
		}
		button {
			color: #000;
			font-family: Inter;
			font-size: 14px;
			font-style: normal;
			font-weight: 600;
			line-height: normal;
			border-radius: 5px;
			background: #fba919;
			width: 100%;
			padding: 12px 0;
			outline: none;
			border: 0;
			&:disabled {
				opacity: 0.6;
				cursor: not-allowed;
			}
		}
	}
	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
		border-radius: 15px;
		@media screen and (max-width: 800px) {
			display: none;
		}
	}
}
