.phone__all__filters__container {
	width: 100%;
	display: flex;
	gap: 16px;
	overflow-y: scroll;

	.sort__popup__container {
		position: relative;
		box-shadow: none;
		z-index: 2;
		border: none;
		top: 0;
		width: 100%;
	}

	.filter__category__wrapper {
		display: flex;
		flex-direction: column;
		width: 100%;
		overflow-y: scroll;
		height: 300px;
	}
}
