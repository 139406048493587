.guest-details-wrapper {
	border-radius: 4px;
	border: 1px solid #d5dbe5;
	background: #fff;
	@media screen and (max-width: 800px) {
		border-top: 5px solid rgba(213, 219, 229, 0.5);
		border-left: 0;
		border-right: 0;
		border-bottom: 5px solid rgba(213, 219, 229, 0.5);
	}
	.guest-detail-box {
		padding: 26px 20px;
		border-bottom: 1px solid #d5dbe5;
		display: flex;
		// align-items: center;
		gap: 20px;

		@media screen and (max-width: 800px) {
			// padding: 26px 0;
		}
		&:last-child {
			border: none;
		}
		.bold-text {
			color: #111;
			font-family: Inter;
			font-size: 16px;
			font-style: normal;
			font-weight: 600;
			line-height: normal;
			letter-spacing: 0.8px;
		}
		h3 {
			opacity: 0.5;
			margin: 0;
			margin-right: 8px;
			color: #111;
			font-family: var(--ff-body);
			font-size: 16px;
			font-style: normal;
			font-weight: 400;
			line-height: normal;
		}
		h5 {
			margin: 0;
			color: #111;
			font-family: var(--ff-body);
			font-size: 9px;
			font-style: normal;
			font-weight: 700;
			line-height: normal;
			letter-spacing: 0.18px;
			text-transform: uppercase;
			margin-left: -16px;
			border-radius: 1px;
			background: #c4f3c0;
		}
		svg {
			margin-top: 4px;
		}
		button {
			display: flex;
			align-self: baseline;
			margin-left: auto;
			background: none;
			outline: none;
			border: none;
			color: #345088;
			text-align: right;
			font-family: var(--ff-body);
			font-size: 14px;
			font-style: normal;
			font-weight: 600;
			line-height: normal;
			text-decoration-line: underline;
		}
		span {
			color: #111;
			font-family: var(--ff-body);
			font-size: 16px;
			font-style: normal;
			font-weight: 600;
			line-height: normal;
			display: inline-block;
			margin-right: 6px;
			width: fit-content;
		}
		p {
			margin-top: 6px;
			color: #555;
			font-family: var(--ff-body);
			font-size: 12px;
			font-style: normal;
			font-weight: 400;
			line-height: normal;
		}
	}
	.coupon-box {
		align-items: center;
		.discount-cover {
			display: flex;
			align-items: center;
			h3 {
				color: #111;
				opacity: 1;
				font-family: Inter;
				font-size: 16px;
				font-style: normal;
				font-weight: 600;
				line-height: normal;
				letter-spacing: 0.8px;
			}
			h5 {
				margin: 0;
				color: #129536;
				text-align: center;
				font-family: Inter;
				font-size: 12px;
				font-style: normal;
				font-weight: 500;
				line-height: 138%; /* 16.56px */
				padding: 5.5px 14px;
				display: flex;
				align-items: center;
				gap: 8px;
				text-transform: none;
				b {
					font-size: 20px;
				}
			}
			@media screen and (max-width: 800px) {
				flex-direction: column;
				align-items: baseline;
				gap: 10px;
				h5 {
					margin: 0;
					width: fit-content;
					margin-bottom: 4px;
				}
			}
		}
		button {
			align-self: center;
		}
		svg {
			margin: 0;
		}
	}
}

.checkin-time-wrapper {
	display: flex;
	flex-direction: column;
	gap: 10px;
	h3 {
		color: #111;
		font-family: Inter;
		font-size: 18px;
		font-style: normal;
		font-weight: 600;
		line-height: normal;
	}
	p {
		color: #555;
		font-family: Inter;
		font-size: 12px;
		font-style: normal;
		font-weight: 400;
		line-height: 140%; /* 16.8px */
	}
	.checkin__time__container {
		display: flex;
		flex-direction: column;
		gap: 10px;
		width: 100%;
		margin-bottom: 300px;

		.info__text {
			display: flex;
			align-items: center;
			width: max-content;
			font-size: 12px;
			font-weight: 500;
			color: #666666;
			background-color: #f5f5f5f5;
			padding: 4px 6px;

			span {
				display: flex;
				align-items: center;
			}

			// span {
			// 	min-width: 16px;
			// 	min-height: 16px;
			// 	border-radius: 100%;
			// 	background-color: #3564e2;
			// 	margin-right: 6px;
			// }
			svg {
				margin-right: 6px;
			}
		}
	}

	.checkin-time {
		position: relative;
		border: 1px solid var(--brd-clr-grey-400);
		width: 100%;
		border-radius: 4px;
		cursor: pointer;

		.time {
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 16px 20px;

			span {
				display: flex;
				gap: 10px;

				p {
					color: var(--text-clr-grey-900);
					font-size: 16px;
					font-weight: 500;
					line-height: 24px;
				}
			}
		}

		.checkin-dropdown {
			position: absolute;
			display: flex;
			flex-direction: column;
			width: 100%;
			background-color: var(--bg-white);
			border-radius: 4px;
			border: 1px solid var(--brd-clr-grey-400);
			top: 65px;
			padding: 4px;

			z-index: 1;

			.options {
				color: var(--text-clr-grey-900);
				padding: 8px 16px;
				width: 100%;
				cursor: pointer;
				font-size: 14px;
				font-weight: 500;
				line-height: 21px;

				&:hover {
					background-color: #edf2ff;
					border-radius: 4px;
				}
			}
		}
	}
}

.guest-data-wrapper {
	display: flex;
	flex-direction: column;
	width: fit-content;
	gap: 10px;
	h3 {
		color: #111;
		font-family: Inter;
		font-size: 18px;
		font-style: normal;
		font-weight: 600;
		line-height: normal;
	}
	p {
		color: #555;
		font-family: Inter;
		font-size: 12px;
		font-style: normal;
		font-weight: 400;
		line-height: 140%; /* 16.8px */
	}
	.guest-selector-wrapper {
		display: flex;
		flex-direction: column;
		gap: 26px;
		margin-top: 32px;
		.guest-selector {
			display: flex;
			justify-content: space-between;
			align-items: center;
			.guest-title {
				display: flex;
				flex-direction: column;
				gap: 4px;

				b {
					color: #111;
					font-family: Inter;
					font-size: 16px;
					font-style: normal;
					font-weight: 500;
					line-height: normal;
				}
				span {
					color: #555;
					font-family: Inter;
					font-size: 12px;
					font-style: normal;
					font-weight: 400;
					line-height: normal;
				}
			}
			.guest-count {
				display: flex;
				align-items: center;
				justify-content: center;
				button {
					width: 34px;
					height: 34px;
					flex-shrink: 0;
					display: flex;
					align-items: center;
					justify-content: center;
					background: none;
					border-radius: 100%;
					border: 1px solid #c5c5c5;
					color: #111;
					font-family: Inter;
					font-size: 16px;
					font-style: normal;
					font-weight: 600;
					line-height: normal;
					vertical-align: middle;
					&:disabled {
						filter: opacity(0.3);
					}
				}
				span {
					text-align: center;
					display: block;
					width: 50px;
					color: #111;
					font-family: Inter;
					font-size: 14px;
					font-style: normal;
					font-weight: 500;
					line-height: normal;
				}
			}
		}
	}
}

.booking-popup {
	border-radius: 8px;
	border: 1px solid #d5dbe5;
	background: #fff;
	padding: 30px 24px;
	@media screen and (max-width: 1000px) {
		position: fixed;
		bottom: 0;
		left: 0;
		width: 100%;
	}
	.btn-wrapper {
		display: flex;
		justify-content: flex-end;
		align-items: center;
		padding-top: 20px;
		border-top: 1px solid #ddd;
		button {
			font-family: var(--ff-body);
			font-size: 16px;
			font-style: normal;
			font-weight: 600;
			line-height: normal;
			color: #335bad;
			text-decoration: underline;
			padding: 15px 77px;
			background: none;
			border: none;
			@media screen and (max-width: 800px) {
				flex: 1;
				padding: 15px 0;
			}
		}
		.blue-btn {
			color: white;
			border-radius: 4px;
			background: #335bad;
			text-decoration: none;
		}
	}
}
.calendar-wrapper {
	.calendar-top {
		margin: auto;
		text-align: center;
		color: #999;
		font-family: var(--ff-body);
		font-size: 20px;
		font-style: normal;
		font-weight: 500;
		line-height: 150%; /* 30px */
		text-decoration-line: underline;
	}
}

.guest-data-wrapper {
}
