.facebook_email_error {
  max-width: 400px;
  padding: 0.5rem 1rem;
  position: fixed;
  top: 10px;
  right: 10px;
  border-radius: 10px;
  background: red;

  p {
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.5;
    color: white;
  }
}