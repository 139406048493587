.phone__search__filters__container {
	width: 100%;
	display: flex;
	gap: 6px;
	overflow-x: scroll;
	padding: 0 16px 10px 16px;
	/* Hide scrollbar for IE, Edge and Firefox */
	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: none; /* Firefox */

	/* Hide scrollbar for Chrome, Safari and Opera */
	&::-webkit-scrollbar {
		display: none;
	}

	.box__active {
		border: 1px solid #3564e2 !important;
		background-color: rgba(53, 100, 226, 0.1);
	}

	.box {
		display: flex;
		align-items: center;
		gap: 4px;
		padding: 6px 8px;
		border-radius: 10px;
		border: 1px solid #dddddd;
		width: max-content;

		p {
			color: #333333;
			font-size: 12px;
			font-weight: 500;
			line-height: 17px;
			white-space: nowrap;

			svg {
				width: 8px;
				height: 8px;
				margin-left: 5px;
			}
		}

		.icon {
			display: flex;
			align-items: center;

			svg {
				width: 12px;
				height: 12px;
			}
		}

		.arrow {
			display: flex;
			align-items: center;
			svg {
				width: 8px;
				height: 4px;
			}
		}
	}
}
