@import "./global/style.scss";

// Inter
// @import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
// Lora
// @import url("https://fonts.googleapis.com/css2?family=Lora:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap");
// Lato
// @import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");
// Urbanist
// @import url("https://fonts.googleapis.com/css2?family=Urbanist:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
// Inria Serif
// @import url("https://fonts.googleapis.com/css2?family=Inria+Serif:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap");

@font-face {
	font-family: "giliamsregular";
	src: url("../public/fonts/giliams-regular-webfont.woff2") format("woff2"),
		url("../public/fonts/giliams-regular-webfont.woff") format("woff");
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "butler";
	src: url("../public/fonts/Butler.woff2") format("woff2"),
		url("../public/fonts/Butler.woff") format("woff");
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: Bold;
	src: url("../public/fonts/Druk-Wide-Medium-Regular.ttf");
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

:root {
	// responsive text
	--step-6: clamp(1.1rem, 0.36rem + 1.45vw, 2.4rem);
	--step--2: clamp(1.39rem, 0.96rem + 1.95vw, 2.4rem);
	--step--1: clamp(1.8rem, 1.1rem + 2.57vw, 3.4rem);
	--step-0: clamp(1.675rem, 1.26rem + 3.17vw, 3.75rem);
	--step-1: clamp(2.2rem, 1.38rem + 4.41vw, 4.4rem);
	--step-2: clamp(1rem, 1.62rem + 2.64vw, 5.86rem);
	--step-3: clamp(3.46rem, 1.83rem + 7.46vw, 7.32rem);
	--step-4: clamp(4.15rem, 2.04rem + 9.65vw, 9.15rem);
	--step-5: clamp(4.98rem, 2.25rem + 12.47vw, 11.44rem);

	// fonts
	// --ff-heading: "Inter", sans-serif; // L1
	--ff-heading: "butler", sans-serif; // L1
	// --ff-heading: 'giliamsregular', serif; // L1
	--ff-body: "Inter", sans-serif; // L2
	--ff-caption: "Inter", sans-serif; // L3
	// --ff-heading: "Lora", serif;
	// --ff-body-3: 'Lato', sans-serif;
	// --ff-body-4: "Lora", serif;
	// --ff-body-5: 'Urbanist', sans-serif;

	// colours
	--clr-white: #ffffff;
	--clr-black: #000000;
	//--clr-primary: #2368a2;
	--clr-primary: #2368a2;

	// button colour
	//--btn-clr-primary-500: #2368a2
	--btn-clr-primary-500: #2368a2;
	//--btn-clr-primary-400: #1b4aca;
	--btn-clr-primary-400: #2368a2;

	// text colour
	--text-clr-grey-900: #333333;
	--text-clr-grey-600: #666666;
	--text-clr-grey-500: #999999;
	--text-clr-grey-400: #dddddd;
	--text-clr-white-400: #f2f2f2;
	--text-clr-white: #ffffff;
	--text-clr-black: #000000;
	--text-clr-red: #fa4b4b;

	// border colour
	--brd-clr-grey-900: #333333;
	--brd-clr-grey-600: #666666;
	--brd-clr-grey-400: #dddddd;
	//--brd-clr-primary: #2368a2;
	--brd-clr-primary: #2368a2;

	// background colours
	--bg-white: #ffffff;
	--bg-black: #000000;
	--bg-main: #f2f2f2;
	--bg-clr-grey-900: #333333;
	--bg-clr-grey-600: #666666;
	--bg-clr-grey-400: #dddddd;
	//--bg-primary: #2368a2;
	--bg-primary: #2368a2;

	--bg-clr-red-400: rgba(255, 162, 162, 0.5);

	// gradient colour
	//--gradient-primary: linear-gradient(97.31deg, #609cff 1.48%, #6e61ff 99.05%);
	--gradient-primary: linear-gradient(97.31deg, #2368a2 1.48%, #2368a2 99.05%);
}

*,
::before,
::after {
	padding: 0;
	margin: 0;
	box-sizing: border-box;
	font-family: var(--ff-body);
	text-rendering: optimizeLegibility;
}

body {
	min-height: 100dvh;
	font-size: 100%;
	line-height: 1.5;
	font-family: var(--ff-body);
	overflow-x: hidden;
	// margin-bottom: 0.5rem;
	text-rendering: optimizeSpeed;
	scroll-behavior: smooth;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	@media screen and (max-width: 450px) {
		-webkit-tap-highlight-color: transparent;
	}
}

/* Make images easier to work with */
img {
	display: block;
	max-width: 100%;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
	font: inherit;
}

// Hide the default blue highlight on Chrome when
//  when a field is autofilled

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
	box-shadow: 0 0 0 30px #fff inset;
	-webkit-box-shadow: 0 0 0 30px #fff inset;
	-webkit-text-fill-color: #999999 !important;
}

button {
	&:hover {
		cursor: pointer;
	}
}

button:disabled {
	&:hover {
		cursor: not-allowed !important;
	}
}

.LandbotLivechat {
	z-index: 1001 !important;

	@media screen and (max-width: 1200px) {
		bottom: 120px !important;
	}
	@media screen and (max-width: 800px) {
		display: none;
	}
}

// @keyframes shake {
// 	0% {
// 		transform: translate(1px, 1px) rotate(0deg);
// 	}
// 	10% {
// 		transform: translate(-1px, -2px) rotate(-1deg);
// 	}
// 	20% {
// 		transform: translate(-3px, 0px) rotate(1deg);
// 	}
// 	30% {
// 		transform: translate(3px, 2px) rotate(0deg);
// 	}
// 	40% {
// 		transform: translate(1px, -1px) rotate(1deg);
// 	}
// 	50% {
// 		transform: translate(-1px, 2px) rotate(-1deg);
// 	}
// 	60% {
// 		transform: translate(-3px, 1px) rotate(0deg);
// 	}
// 	70% {
// 		transform: translate(3px, 1px) rotate(-1deg);
// 	}
// 	80% {
// 		transform: translate(-1px, -1px) rotate(1deg);
// 	}
// 	90% {
// 		transform: translate(1px, 2px) rotate(0deg);
// 	}
// 	100% {
// 		transform: translate(1px, -2px) rotate(-1deg);
// 	}
// }
@keyframes shake {
	0% {
		transform: translate(1px, 1px) rotate(0deg);
	}

	20% {
		transform: translate(-3px, 0px) rotate(1deg);
	}

	40% {
		transform: translate(1px, -1px) rotate(1deg);
	}

	60% {
		transform: translate(-3px, 1px) rotate(0deg);
	}

	80% {
		transform: translate(-1px, -1px) rotate(1deg);
	}

	100% {
		transform: translate(1px, -2px) rotate(-1deg);
	}
}

#chatbot-trigger {
	display: none;
	border: none;
	background: none;
	bottom: 115px;
	right: 15px;
	width: 80px;
	height: 80px;
	background-color: #000000;
	border-radius: 50%;
	transform: scale(0.8);
	@media screen and (max-width: 800px) {
		display: block;
	}
}

#close-chatbot-btn {
	position: fixed;
	top: 0px;
	right: 15px;
	z-index: 1001;
	display: none;
	border: none;
	background: none;
	cursor: pointer;
	font-size: 20px;
	padding: 15px;
}

.LandbotFullpage {
	z-index: 1000 !important;
}

@keyframes hightligther {
	0% {
		background-color: rgb(238, 238, 238);
	}
	100% {
		background-color: none;
	}
}

.not-visible {
	visibility: hidden;
	position: absolute;
}
.not-visible-ssr {
	visibility: hidden;
	position: absolute;
}
