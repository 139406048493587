.trip__details__container {
	width: 100%;
	display: flex;
	flex-direction: column;

	.wrapper {
		width: 100%;
		display: flex;
		flex-direction: column;
		gap: 24px;

		.btn__list__cont {
			display: flex;
			gap: 12px;
			overflow-x: scroll;

			&::-webkit-scrollbar {
				display: none;
			}

			/* Hide scrollbar for IE, Edge and Firefox */
			-ms-overflow-style: none; /* IE and Edge */
			scrollbar-width: none; /* Firefox */

			@media screen and (max-width: 767px) {
				gap: 8px;
			}

			.caps {
				display: flex;
				align-items: center;
				border: 1px solid #dddddd;
				padding: 8px 16px;
				color: #666666;
				font-size: 12px;
				font-weight: 500;
				line-height: 18px;
				border-radius: 30px;
				cursor: pointer;
				transition: all 0.3 ease-in-out;
				white-space: nowrap;
				min-width: max-content;

				img {
					width: 12px;
					height: 12px;
					margin-right: 4px;
				}
			}

			.caps__active {
				color: #2d66a1;

				background: rgba(45, 102, 161, 0.1);
				border: 0.88px solid #2d66a1;
			}
		}
	}

	.top {
		width: 100%;
		display: flex;
		flex-direction: column;
		gap: 6px;

		label {
			color: #999999;
			font-size: 14px;
			font-weight: 400;
			line-height: 21px;
			letter-spacing: 0em;
			text-align: left;

			@media screen and (max-width: 768px) {
				font-size: 14px;
				font-weight: 400;
				line-height: 21px;
				letter-spacing: 0em;
				text-align: left;
			}
		}

		span {
			position: relative;
			width: 100%;

			svg {
				position: absolute;
				left: 27px;
				top: 25px;
				height: 18px;
				width: 18px;

				@media screen and (max-width: 768px) {
					left: 17px;
					top: 18px;
					height: 16px;
					width: 16px;
				}
			}

			input {
				width: 100%;
				color: #333333;
				padding: 21px 27px 21px 55px;
				border-radius: 4px;
				font-size: 16px;
				font-weight: 500;
				line-height: 24px;
				border: 1px solid #dddddd;

				@media screen and (max-width: 768px) {
					padding: 14px 17px 14px 46px;
					font-size: 14px;
					font-weight: 500;
					line-height: 21px;
				}
			}
		}
	}

	.middle {
		padding: 18px;
		border: 1px solid #dddddd;
		border-radius: 10px;
		background-color: #fafafa;

		h4 {
			color: #333333;
			font-size: 18px;
			font-weight: 500;
			margin-bottom: 20px;
		}
	}

	.middle,
	.wrapper {
		display: flex;
		flex-direction: column;
		width: 100%;
		display: flex;

		.middle__top {
			display: flex;
			gap: 30px;

			@media screen and (max-width: 768px) {
				flex-direction: column;
			}
		}
		.middle__bottom {
			margin-top: 10px;
			display: flex;
			gap: 10px;

			input[type="checkbox"] {
				cursor: pointer;
			}

			label {
				color: #333333;
				font-size: 14px;
				font-weight: 500;
				line-height: 21px;
			}
		}

		.date-picker-wrapper {
			z-index: 0;

			//setting calendar to be full width for mobile view
			@media screen and (max-width: 449px) {
				position: relative !important;
				transform: scale(1);
				left: -10px;
			}

			@media screen and (max-width: 420px) {
				transform: scale(0.95);
				left: -20px;
			}

			@media screen and (max-width: 400px) {
				transform: scale(0.9);
				left: -32px;
			}

			@media screen and (max-width: 380px) {
				transform: scale(0.85);
				left: -40px;
			}

			@media screen and (max-width: 350px) {
				transform: scale(0.8);
				left: -50px;
			}

			button {
				border: none;
			}
		}

		@media screen and (max-width: 768px) {
			flex-direction: column;
		}

		.middle__left,
		.middle__right {
			display: flex;
			flex-direction: column;
			gap: 6px;
			width: 100%;

			label {
				color: #999999;
				font-size: 14px;
				font-weight: 500;
				line-height: 21px;

				@media screen and (max-width: 768px) {
					font-size: 10px;
					font-weight: 500;
					line-height: 15px;
				}
			}

			span {
				width: 100%;
				display: flex;
				justify-content: space-between;
				padding: 17px 26px;
				border: 1px solid #dddddd;
				border-radius: 4px;
				font-size: 16px;
				font-weight: 500;
				line-height: 24px;
				cursor: pointer;

				@media screen and (max-width: 768px) {
					padding: 14px 16px;
					font-size: 14px;
					font-weight: 500;
					line-height: 21px;
				}
			}
		}
	}

	.bottom {
		display: flex;
		flex-direction: column;
		gap: 6px;

		.search__villa {
			display: flex;
			flex-direction: column;
			gap: 6px;

			.small__box__container {
				display: flex;
				flex-wrap: wrap;
				gap: 8px;

				.small__box {
					padding: 8px 12px;
					border-radius: 4px;
					font-size: 12px;
					font-weight: 600;
					line-height: 18px;
					background: #edf2ff;
					cursor: pointer;

					svg {
						width: 10px;
						height: 10px;
						margin-left: 8px;
					}
				}
			}

			label {
				color: #999999;
				font-size: 14px;
				font-weight: 500;
				line-height: 21px;

				@media screen and (max-width: 768px) {
				}
			}

			.span {
				position: relative;

				svg {
					top: 20px;
					position: absolute;
					left: 20px;

					@media screen and (max-width: 768px) {
						width: 15px;
						height: 15px;
						top: 16px;
					}
				}

				input {
					width: 100%;
					padding: 17px 26px 17px 52px;
					border-radius: 4px;
					border: 1px solid #dddddd;
					color: #333333;
					font-size: 16px;
					font-weight: 400;
					line-height: 24px;
					letter-spacing: 0em;
					text-align: left;

					@media screen and (max-width: 768px) {
						padding: 13px 15px 13px 43px;
						font-size: 14px;
						font-weight: 500;
						line-height: 21px;
					}
				}
			}
		}

		label {
			color: #999999;
			font-size: 14px;
			font-weight: 400;
			line-height: 21px;
			letter-spacing: 0em;
			text-align: left;

			@media screen and (max-width: 768px) {
				font-size: 10px;
				font-weight: 500;
				line-height: 15px;
			}
		}
	}
}
