.personal__step__one__container {
	// max-width: 600px;
	margin: 0 auto;

	@media screen and (max-width: 768px) {
		max-width: 100%;
		padding: 0 16px;
		margin-bottom: 50px;
	}

	h1 {
		color: #333333;
		font-size: 24px;
		font-weight: 700;
		line-height: 36px;

		@media screen and (max-width: 768px) {
			font-size: 16px;
			font-weight: 700;
			line-height: 24px;
		}
	}

	.top {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		gap: 24px;
		// border-bottom: 1px solid #dddddd;
		padding-bottom: 30px;
		margin-bottom: 30px;

		@media screen and (max-width: 768px) {
			border-bottom: none;
			margin: 0;
			padding-bottom: 16px;
		}

		.book__change {
			display: flex;
			align-items: center;
			gap: 14px;

			.icon {
				display: flex;
				align-items: center;
				justify-content: center;
				width: 21px;
				height: 21px;
				border-radius: 100%;
				background-color: #666666;
			}

			p {
				color: #333333;
				font-size: 20px;
				font-weight: 500;
				line-height: 30px;

				@media screen and (max-width: 768px) {
					font-size: 16px;
					font-weight: 500;
					line-height: 24px;
				}
			}

			span {
				color: #2d66a1;
				font-size: 16px;
				font-weight: 600;
				line-height: 24px;
				cursor: pointer;
				text-decoration: underline;
				text-underline-offset: 4px;

				@media screen and (max-width: 768px) {
					font-size: 14px;
					font-weight: 500;
					line-height: 24px;
				}

				&:hover {
					text-decoration: underline;
					text-underline-offset: 3px;
				}
			}
		}
	}

	.middle {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		gap: 24px;
		padding-bottom: 30px;
		margin-bottom: 30px;
		border-bottom: 1px solid #dddddd;

		@media screen and (max-width: 768px) {
			margin-bottom: 0;
			padding-bottom: 24px;
		}

		.box__container {
			display: flex;
			gap: 32px;
			width: 100%;

			@media screen and (max-width: 768px) {
				gap: 11px;
			}

			.box {
				display: flex;
				gap: 10px;
				align-items: center;
				justify-content: center;
				width: 100%;
				border: 1px solid #dddddd;
				padding: 15px 34px;
				border-radius: 10px;
				cursor: pointer;
				transition: all 0.3s ease-in;

				@media screen and (max-width: 768px) {
					padding: 10px 14px;
					font-size: 12px;
					font-weight: 500;
					line-height: 18px;
					gap: 8px;
					width: max-content;

					svg {
						width: 10px;
						height: 10px;
						margin-right: 0 !important;
					}
				}

				svg {
					margin-right: 10px;
					fill: #666666;
				}
			}

			.box__active {
				border: 2px solid #2d66a1;
				background: rgba(89, 146, 205, 0.1);
			}
		}
	}

	.bottom {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		gap: 20px;
		padding-bottom: 30px;
		margin-bottom: 30px;
		// border-bottom: 1px solid #dddddd;

		@media screen and (max-width: 768px) {
			padding-top: 24px;
		}

		.left,
		.right,
		.bottom__middle {
			display: flex;
			flex-direction: column;
			gap: 6px;
			width: 100%;

			label {
				color: #666666;
				font-size: 14px;
				font-weight: 500;
				line-height: 21px;

				@media screen and (max-width: 768px) {
					font-size: 10px;
					font-weight: 500;
					line-height: 15px;
				}
			}

			input {
				padding: 17px 26px;
				width: 100%;
				border: 1px solid #dddddd;
				border-radius: 4px;
				color: #333333;
				font-size: 16px;
				font-weight: 500;
				line-height: 24px;

				@media screen and (max-width: 768px) {
					padding: 13px 20px;
					font-size: 14px;
					font-weight: 500;
					line-height: 21px;
				}
			}
		}

		.bottom__top,
		.bottom__bottom {
			display: flex;
			gap: 20px;
			width: 100%;

			@media screen and (max-width: 768px) {
				flex-direction: column;
			}
		}
	}
}
