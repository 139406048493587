.location-popup-wrapper {
	position: absolute;
	display: flex;
	top: 70px;
	left: 0;
	background: var(--bg-white);
	border: 1px solid #f2f2f2;
	box-sizing: border-box;
	box-shadow: 0px 0px 50px 4px rgba(221, 221, 221, 0.35);
	border-radius: 10px;
	padding: 20px 10px;
	max-height: 413px;
	min-width: 480px;
	overflow: hidden;

	@media screen and (max-width: 768px) {
		max-width: 580px;
	}

	::-webkit-scrollbar {
		width: 4px;
		border-radius: 2px;
	}

	::-webkit-scrollbar-thumb {
		background: #999999;
	}

	::-webkit-scrollbar-thumb:hover {
		background: #555;
	}

	.suggest-right {
		width: 50%;
		padding-left: 20px;
		overflow: hidden;
		// height: 100%;

		h2 {
			font-style: normal;
			font-weight: 600;
			font-size: 16px;
			line-height: 150%;
			color: var(--text-clr-grey-900);
		}

		.villa-suggest-wrapper {
			margin-top: 14px;
			height: calc(100% - 80px);
			overflow-y: auto;
			padding-right: 20px;
		}

		.villa-details {
			display: flex;
			width: 100%;
			overflow: hidden;
			border-radius: 8px;
			background: var(--bg-white);
			opacity: 0.99;
			border: 1px solid var(--brd-clr-grey-400);
			box-sizing: border-box;
			margin-bottom: 16px;
			justify-content: space-between;
			cursor: pointer;
			transition: 0.3s linear;

			&:hover {
				box-shadow: 0px 4px 8px rgba(153, 153, 153, 0.25);
			}

			.villa-details-container {
				padding: 16px;

				h3 {
					font-style: normal;
					font-weight: 500;
					font-size: 12px;
					line-height: 150%;
					color: var(--bg-clr-grey-600);
				}
			}

			img {
				object-fit: cover;
				height: auto;
				width: 30%;
			}
		}
	}

	.suggest-left {
		height: auto;
		width: 100%;
		overflow-y: auto;

		.home-suggest-wrapper {
			display: flex;
			align-items: center;
			padding: 12px;
			margin-right: 10px;
			cursor: pointer;
			transition: 0.3s linear;

			&:hover {
				background: #edf2ff;
				border-radius: 4px;
			}

			.home-suggest-img {
				width: 30px;
				height: 30px;
				border-radius: 4px;

				img {
					width: 100%;
					height: 100%;
					border-radius: inherit;
					object-fit: cover;
				}
			}

			.home-suggest-details {
				margin-left: 20px;
				width: 100%;

				span {
					width: 100%;
					display: flex;
					align-items: center;
					justify-content: space-between;
				}
			}

			h4 {
				font-style: normal;
				font-weight: 600;
				font-size: 14px;
				line-height: 150%;
				color: var(--text-clr-grey-900);
			}

			h5 {
				font-style: normal;
				font-weight: 500;
				font-size: 10px;
				line-height: 150%;
				color: var(--bg-clr-grey-600);
			}

			span {
				display: block;
				margin-left: auto;
				align-self: baseline;

				font-style: normal;
				font-weight: 500;
				font-size: 10px;
				line-height: 150%;
				text-align: right;
				color: var(--bg-clr-grey-600);
			}
		}
	}
}
