.booking-experience-wrapper {
	width: 100%;
	max-width: 33.75rem;
	display: flex;
	gap: 1rem;
	overflow-x: scroll;
	padding-bottom: 1.25rem;
	-webkit-overflow-scrolling: touch;
	scroll-behavior: smooth;
}

.booking-experience-wrapper::-webkit-scrollbar {
	height: 4.5px;
}

.booking-experience-wrapper::-webkit-scrollbar-track {
	background: #f1f1f1;
}

.booking-experience-wrapper::-webkit-scrollbar-thumb {
	background: #345088;
	border-radius: 4px;
}

.booking-experience-wrapper::-webkit-scrollbar-thumb:hover {
	background: #555;
}
