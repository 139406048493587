.booking-collapse {
	border-radius: 4px;
	border: 1px solid #d5dbe5;
	background: #fff;
	padding: 6px;
	.collapse-title {
		color: #111;
		font-family: Inter;
		font-size: 16px;
		font-style: normal;
		font-weight: 600;
		line-height: normal;
		display: flex;
		align-items: center;
		padding: 24px 20px;
		justify-content: space-between;
		cursor: pointer;
	}
}
