.info-icon {
	display: flex;
	align-items: center;
	position: relative;
	cursor: pointer;

	.info-div {
		color: #fff;
		position: absolute;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 4px;
		background-color: var(--bg-clr-grey-900);
		width: max-content;
		padding: 8px 10px;
		z-index: 2;
		top: 15px;
		left: -250px;
		font-size: 10px;
		font-style: normal;
		font-weight: 500;
		line-height: 18px;
		cursor: pointer;

		span {
			max-width: 200px;
		}

		@media screen and (max-width: 900px) {
			right: -80px;
		}
	}
}
