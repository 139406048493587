.forgetPassword {
	display: flex;
	justify-content: center;
	align-items: center;

	.forgetPassword-container {
		max-width: 450px;
		width: 100vw;
		z-index: 1000;
		background-color: var(--bg-white);
		border-radius: 10px;
		padding: 5px;

		@media screen and (max-width: 500px) {
			height: 100vh;
		}

		.close-icon {
			display: flex;
			padding: 10px;
			width: 100%;
			justify-content: flex-end;

			@media screen and (max-width: 500px) {
				justify-content: flex-start;
			}

			span {
				width: 20px;
				cursor: pointer;
			}
		}

		.forgetPassword__sub-container {
			display: flex;
			flex-direction: column;
			padding: 20px 40px 60px 40px;

			@media screen and (max-width: 500px) {
				padding: 20px 16px;
			}

			.forgetPassword__title {
				margin-bottom: 15px;

				p {
					font-size: 24px;
					font-weight: 700;
					color: var(--text-clr-grey-900);
				}
			}

			.forgetPassword__subtitle {
				width: 100%;
				margin-bottom: 30px;

				p {
					font-size: 14px;
					font-weight: 500;
					color: var(--bg-clr-grey-600);
					line-height: 25px;
				}
			}

			.forgetPassword__input {
				margin-bottom: 10px;
			}
		}
	}
}