.search__range__slider__container {
	width: 100%;

	.slider__range {
		width: 100%;
		z-index: 2;
		background-color: var(--bg-primary);
		height: 4px;
	}

	.slider__track,
	.slider__range {
		width: 100%;
		border-radius: 3px;
		height: 4px;
		position: absolute;
	}

	.slider__content {
		width: 100%;
		margin-top: 20px;

		.slider__container {
			position: relative;
			width: 100%;

			.slider__track {
				border-bottom: 3px solid #dddddd;
				width: 100%;
				z-index: 1;
			}
		}
	}

	.slider__value {
		width: 100%;
		display: flex;
		gap: 10px;

		.slider__left__value,
		.slider__right__value {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			gap: 4px;
			padding: 7px 0;
			padding-left: 10px;
			width: 50%;
			background: var(--bg-white);
			border: 1px solid var(--brd-clr-grey-400);
			border-radius: 4px;
			text-align: center;
			border-radius: 6px;

			input {
				width: 100%;
				color: #333333;
				font-size: 14px;
				font-weight: 500;
				line-height: 17px;
				letter-spacing: 0em;
				text-align: left;
			}

			label {
				color: #999999;
				font-size: 10px;
				font-weight: 500;
				line-height: 14px;
			}

			.val {
				display: flex;
				color: #333333;
				font-size: 12px;
				font-weight: 500;
				line-height: 17px;
				letter-spacing: 0em;
				text-align: left;
			}
		}
	}

	.thumb {
		width: 100%;
		pointer-events: none;
		position: absolute;
		height: 0;
		outline: none;
	}

	.thumb__zindex-3 {
		z-index: 3;
	}

	.thumb__zindex-4 {
		z-index: 4;
	}
}

/* Removing the default appearance */
.thumb,
.thumb::-webkit-slider-thumb {
	-webkit-appearance: none;
	-webkit-tap-highlight-color: transparent;
}

/* For Chrome browsers */
.thumb::-webkit-slider-thumb {
	background-color: var(--bg-white);
	border: 2px solid var(--brd-clr-primary);
	border-radius: 50%;
	cursor: pointer;
	height: 16px;
	width: 16px;
	margin-top: 4px;
	pointer-events: all;
	position: relative;
}

/* For Firefox browsers */
.thumb::-moz-range-thumb {
	background-color: #f1f5f7;
	border: none;
	border-radius: 50%;
	box-shadow: 0 0 1px 1px #ced4da;
	cursor: pointer;
	height: 18px;
	width: 18px;
	margin-top: 4px;
	pointer-events: all;
	position: relative;
}
