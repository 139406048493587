.creatingAccount {
	display: flex;
	align-items: center;
	justify-content: center;

	.creating__account {
		max-width: 400px;
		width: 100vw;
		height: 500px;
		background-color: var(--bg-primary);
		z-index: 1000;
		border-radius: 10px;

		@media screen and (max-width: 500px) {
			height: 100vh;
			background-color: var(--bg-white);
		}

		.close-icon {
			display: flex;
			padding: 10px;
			width: 100%;
			justify-content: flex-end;

			span {
				width: 20px;
				cursor: pointer;
			}
		}

		.creating__account-container {
			display: flex;
			align-items: center;
			flex-direction: column;
			justify-content: center;
			height: 100%;
			gap: 25px;

			.creating__account-text {
				text-align: center;
				height: 100px;
				width: 200px;

				p {

					color: #fff;
					font-size: 24px;
					font-style: normal;
					font-weight: 700;
					line-height: 36px;
					text-align: center;

					@media screen and (max-width: 500px) {
						color: var(--text-clr-grey-900);
					}
				}
			}
		}
	}
}