@import url(https://fonts.googleapis.com/css?family=Catamaran:100,200,300,regular,500,600,700,800,900);

.sidebar {
	position: fixed;
	padding: 24px 20px;
	overflow-y: auto;
	height: 100vh;
	background-color: var(--bg-white);
	z-index: 100;
	inset: 0;

	.logout {
		font-family: "Catamaran";
		font-style: normal;
		font-weight: 600;
		font-size: 16px;
		line-height: 150%;
		margin: 40px 0;
		color: #fa4b4b;
	}

	.nav__middle__phone {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;

		svg {
			position: absolute;
			right: 30px;
		}

		input {
			display: flex;
			width: inherit;
			padding: 13px 20px;
			border-radius: 100px;
			border: 1px solid #999999;
			caret-color: transparent;
			cursor: pointer;
		}
	}

	.sidebar__logo {
		flex: 1;
		display: flex;
		justify-content: space-between;
		align-items: center;

		.cursor-pointer {
			margin-left: -20px;
		}

		div {
			cursor: pointer;
		}
	}

	.sidebar__links {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		margin-top: 26px;
		gap: 1.8rem;
		width: 100%;
		margin-bottom: 100px;

		.side-links {
			display: flex;
			align-items: center;
			justify-content: space-between;
			color: var(--newui-2021-black-grays-dark-text, #333);
			font-family: Catamaran;
			font-size: 16px;
			font-style: normal;
			font-weight: 500;
			line-height: 150%;
			/* 24px */
			padding-bottom: 0px;
			width: 100%;
			gap: 10px;
			display: flex;
			align-items: center;
		}

		.login-btn {
			background: #2d66a1;
			border-radius: 100px;
			font-family: "Catamaran";
			font-style: normal;
			font-weight: 600;
			font-size: 14px;
			line-height: 150%;
			text-align: center;
			color: #ffffff;
			padding: 10px 0;
			width: 100%;
			border: 0;
			outline: 0;
		}

		.sidebar__link {
			width: 100%;
			cursor: pointer;

			.sidebar__link-text {
				.sidebar__text-icon {
					display: flex;
					flex-direction: column;
					border-bottom: 1px #dddddd solid;

					.sidebar-icon {
						display: flex;
						align-items: center;
						gap: 2rem;
						padding-bottom: 20px;

						a {
							text-decoration: none;

							font-size: 20px;
							font-style: normal;
							font-weight: 600;
							line-height: 30px;
							color: var(--text-clr-grey-900);
						}

						button {
							background-color: transparent;
							border: none;
							outline: none;

							text-decoration: none;

							font-size: 20px;
							font-style: normal;
							font-weight: 600;
							line-height: 30px;
							color: var(--text-clr-grey-900);
						}

						p {
							font-size: 20px;
							font-style: normal;
							font-weight: 600;
							line-height: 30px;
						}

						svg {
							height: 12px;
							width: 12px;
						}
					}

					.sidebar__text-sublink {
						display: flex;
						flex-direction: column;
						gap: 1rem;
						padding: 0 10px 20px 10px;

						.sublink {
							display: flex;
							align-items: center;
							gap: 1rem;
							color: var(--text-clr-grey-900);

							font-size: 16px;
							font-style: normal;
							font-weight: 500;
							line-height: 24px;
							letter-spacing: 0em;
							text-align: left;

							svg {
								height: 12px;
								width: 12px;
							}
						}

						.sub-sublink {
							display: flex;
							flex-direction: column;
							gap: 20px;
							padding-left: 20px;

							a {
								color: var(--bg-clr-grey-600);
								text-decoration: none;

								font-size: 16px;
								font-style: normal;
								font-weight: 500;
								line-height: 24px;
							}
						}
					}
				}
			}
		}

		.sidebar__link-button {
			button {
				display: flex;
				align-items: center;
				justify-content: center;
				border-radius: 4px;
				background: transparent;
				color: var(--text-clr-grey-900);
				border: none;
				outline: none;
				margin-right: 4rem;
				cursor: pointer;

				font-size: 20px;
				font-style: normal;
				font-weight: 600;
				line-height: 30px;
			}
		}

		.sidebar__link-button-signature {
			width: 100%;

			button {
				display: flex;
				align-items: center;
				justify-content: center;
				border-radius: 4px;
				background: #183e3f;
				cursor: pointer;
				width: 100%;
				height: 61px;
			}
		}
	}

	.collapse-wrapper {
		display: flex;
		flex-direction: column;
		width: 100%;

		::-webkit-scrollbar {
			display: none;
		}

		.collapse-header {
			font-family: "Catamaran";
			font-style: normal;
			font-weight: 500;
			font-size: 16px;
			line-height: 150%;
			color: #333333;
			display: flex;
			align-items: center;
			justify-content: space-between;

			span {
				gap: 10px;
				display: flex;
				align-items: center;
			}
		}

		.collapse-body {
			transition: height 0.3s ease-in-out, opacity 0.3s ease-in-out;
			overflow: hidden;
			opacity: 0;
			height: 0;
			margin-bottom: 30px;
		}

		.open {
			height: max-content;
			opacity: 1;
		}

		.close {
			height: 0;
			opacity: 0;
		}

		.collection-wrapper {
			overflow-x: scroll;
			display: grid;
			grid-template-rows: repeat(2, 50px);
			gap: 10px;
			grid-auto-flow: column;
			grid-auto-columns: 200px;
			margin-top: 24px;

			.col-card {
				font-family: "Catamaran";
				font-style: normal;
				font-weight: 500;
				font-size: 12px;
				line-height: 150%;
				color: #333333;
				align-items: center;
				display: flex;
				flex-shrink: 0;
				gap: 8px;

				img {
					width: 40px;
					height: 40px;
					object-fit: cover;
					border-radius: 6px;
				}
			}
		}

		.location {
			grid-auto-columns: 150px !important;
		}
	}
}

.nav-user-details-wrapper {
	width: 100%;

	.nav-user-details-content {
		width: 100%;
		display: flex;
		flex-direction: column;
		gap: 16px;

		.nav-user-details-top {
			display: flex;
			gap: 12px;
			align-items: flex-start;

			.nav-user-details-top-left {
				width: 48px;
				height: 48px;

				img {
					width: 100%;
					height: 100%;
					border-radius: 100%;
				}
			}

			.nav-user-details-top-right {
				p {
					color: #666;
					font-family: Catamaran;
					font-size: 12px;
					font-style: normal;
					font-weight: 400;
					line-height: 150%;
					/* 18px */
				}

				h3 {
					color: #333;
					font-family: Catamaran;
					font-size: 16px;
					font-style: normal;
					font-weight: 500;
					line-height: 150%;
					/* 24px */
				}
			}
		}

		.nav-user-details-bottom {
			display: flex;
			flex-direction: column;
			gap: 6px;

			p {
				color: #666;
				font-family: Catamaran;
				font-size: 12px;
				font-style: normal;
				font-weight: 500;
				line-height: 150%;
				/* 18px */
			}

			.nav-user-details-bottom-tier {
				width: max-content;
				padding: 4px 10px;
				border-radius: 16px;
				display: flex;
				align-items: center;
				gap: 8px;
				justify-content: center;

				p {
					&:nth-child(1) {
						width: 77px;
						height: 25px;
						border-radius: 16px;
						background: #f2f2f2;
						color: #333;
						font-family: Catamaran;
						font-size: 14px;
						font-style: normal;
						font-weight: 700;
						line-height: 150%;
						/* 21px */
						display: flex;
						justify-content: center;
						align-items: center;
						gap: 7.73px;
					}

					&:nth-child(2) {
						color: #fff;
						font-family: Catamaran;
						font-size: 12px;
						font-style: normal;
						font-weight: 600;
						line-height: 150%;
						/* 18px */
					}
				}
			}
		}
	}
}

.nav-destination-wrapper {
	margin-top: 10px;
	display: flex;
	flex-direction: column;
	gap: 6px;

	.nav-destinations-header {
		display: flex;
		flex-direction: column;
		gap: 6px;

		p {
			color: #333;
			font-family: Catamaran;
			font-size: 14px;
			font-style: normal;
			font-weight: 700;
			line-height: 150%;
			/* 19.5px */
			text-decoration-line: underline;
		}

		.nav-destinations-details {
			display: grid;
			grid-template-columns: repeat(2, 1fr);

			p {
				color: var(--newui-2021-black-grays-dark-text, #333);
				font-family: Catamaran;
				font-size: 14px;
				font-style: normal;
				font-weight: 500;
				line-height: 150%;
				/* 18px */
				text-decoration: none;
				cursor: pointer;
				transition: all 0.2s ease-in-out;

				&:hover {
					color: #000;
					font-size: bolder;
				}
			}
		}
	}
}

.nav-collection-wrapper {
	margin-top: 20px;
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	row-gap: 16px;

	.nav-collections-header {
		display: flex;
		flex-direction: column;
		gap: 6px;

		p {
			color: var(--newui-2021-black-grays-dark-text, #333);
			font-family: Catamaran;
			font-size: 12px;
			font-style: normal;
			font-weight: 500;
			line-height: 150%;
			/* 18px */
			display: flex;
			align-items: center;
			gap: 8px;
			text-transform: uppercase;

			span {
				width: 40px;
				height: 40px;
				border-radius: 6px;
			}
		}

		.nav-collections-details {
			display: flex;
			flex-direction: column;
			gap: 6px;

			p {
				color: var(--Gray-900, #111b29);
				font-family: Catamaran;
				font-size: 14px;
				font-style: normal;
				font-weight: 400;
				line-height: 150%;
				/* 18px */
				text-transform: none;
				cursor: pointer;
			}
		}
	}
}

.nav-ammenities-wrapper {
	margin-top: 20px;
	display: grid;
	grid-template-columns: repeat(1, 1fr);
	row-gap: 6px;

	.nav-ammenities-header {
		display: flex;
		flex-direction: column;
		gap: 6px;

		p {
			color: var(--newui-2021-black-grays-dark-text, #333);
			font-family: Catamaran;
			font-size: 14px;
			font-style: normal;
			font-weight: 500;
			line-height: 150%;
			/* 18px */
			display: flex;
			align-items: center;
			gap: 8px;
			cursor: pointer;
		}
	}
}

.nav-categories-wrapper {
	margin-top: 20px;
	display: grid;
	grid-template-columns: repeat(1, 1fr);
	row-gap: 12px;

	.nav-categories-header {
		display: flex;
		align-items: center;
		gap: 13px;
		cursor: pointer;

		img {
			width: 22px;
			height: 22px;
		}

		.nav-categories-header-text {
			display: flex;
			flex-direction: column;

			h2 {
				color: var(--Gray-900, #111B29);
				font-family: Catamaran;
				font-size: 14px;
				font-style: normal;
				font-weight: 400;
				line-height: 150%;
				/* 18px */
			}

			p {
				color: #555;
				font-family: Inter;
				font-size: 10px;
				font-style: italic;
				font-weight: 300;
				line-height: 120%;
				/* 7.2px */
			}
		}
	}
}