.upload-modal-wrapper {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
  z-index: 9999;

  .upload-modal-container {
    position: absolute;
    top: 50%;
    left: 50%;
    width: calc(90vw - 40px);
    max-width: 500px;
    transform: translate(-50%, -50%);
    background-color: var(--bg-white);
    padding: 20px;
    border-radius: 10px;

    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    color: #666d80;

    .processbar {
      height: 8px;
      background-color: #00529b;
      transition: 0.1s linear;
      border-radius: 4px;
    }

    .processbar-wrapper {
      background-color: #cee8ff;
      width: 100%;
      margin: 20px 0;
    }
  }
}