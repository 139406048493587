.autocomplete__container {
	background-color: #fffffd;
	border-radius: 10px;
	position: absolute;
	padding: 6px;
	border: 1px solid #dddddd;
	top: 70px;
	overflow-y: scroll;
	left: 0;
	right: 0;
	z-index: 10;
	height: max-content;
	max-height: 250px;

	@media screen and (max-width: 768px) {
		position: relative;
		top: 10px;
	}

	.autocomplete__result__container {
		display: flex;
		flex-direction: column;
		gap: 8px;

		.autocomplete__box {
			display: flex;
			align-items: center;
			gap: 30px;
			padding: 12px 16px;
			height: max-content;
			border-radius: 4px;
			cursor: pointer;

			p {
				font-size: 14px;
				font-weight: 500;
			}

			span {
				display: flex;
				align-items: center;
				justify-content: center;
				border-radius: 4px;
				width: max-content;

				svg {
					position: static !important;
				}
			}

			&:hover {
				background-color: #edf2ff;
			}
		}
	}
}
