.request__sub__success {
	margin-top: 60px;

	@media screen and (max-width: 768px) {
		margin-top: 40px;
	}
}

.have__query__issue__container {
	display: flex;
	flex-direction: column;
	gap: 24px;
	width: 100%;
	max-width: 600px;
	margin: 60px auto;

	@media screen and (max-width: 768px) {
		max-width: 100%;
		padding: 0 16px;
	}

	h2 {
		color: #333333;
		font-size: 24px;
		font-weight: 700;
		line-height: 36px;

		@media screen and (max-width: 768px) {
			font-size: 16px;
			font-weight: 700;
			line-height: 24px;
		}
	}

	.top__query {
		width: 100%;
	}

	.write__issue {
		display: flex;
		flex-direction: column;
		gap: 6px;
		width: 100%;

		label {
			color: #999999;
			font-size: 14px;
			font-weight: 500;
			line-height: 21px;
		}

		textarea {
			width: 100%;
			border: 1px solid #dddddd;
			font-size: 16px;
			font-weight: 500;
			line-height: 24px;
			border-radius: 4px;
			padding: 17px 25px;
			height: 123px;

			@media screen and (max-width: 768px) {
				width: 100%;
				height: 133px;
				font-size: 14px;
				font-weight: 500;
				line-height: 21px;
			}
		}
	}

	.bottom__btn {
		display: flex;
		justify-content: space-between;
		margin-top: 20px;

		.btn__continue {
			all: unset;
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 14px 29px;
			cursor: pointer;
			border-radius: 100px;
			background: #2d66a1;
			color: #ffffff;
			font-size: 14px;
			font-weight: 600;
			line-height: 21px;
			width: max-content;

			@media screen and (max-width: 768px) {
				padding: 10px 20px !important;
				font-size: 12px;
				font-weight: 600;
				line-height: 16px;
				white-space: nowrap;
			}
		}
	}
}
