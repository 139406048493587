@import url(https://fonts.googleapis.com/css?family=Inter:100,200,300,regular,500,600,700,800,900);

.footer-mob-wrapper {
	width: 100%;
	background: #080808;
	color: white;
	.footer-mob-container {
		width: 100%;
		max-width: 1280px;
		margin: 0 auto;
		.footer-mob-content {
			width: 100%;
			display: flex;
			flex-direction: column;
			gap: 30px;
			padding: 28px 24px 28px 27px;
			.footer-mob-brand {
				display: flex;
				flex-direction: column;
				margin-bottom: 20px;
				gap: 30px;
				.footer-mob-brand-left {
					display: flex;
					justify-content: space-between;
					align-items: center;
					.footer-mob-brand-logo {
						svg {
							width: 138px;
							height: 32.564px;
						}
					}
					p {
						width: 107px;
						color: rgba(255, 255, 255, 0.6);
						font-family: Inter;
						font-size: 10px;
						font-style: normal;
						font-weight: 500;
						line-height: 150%; /* 15px */
					}
				}
				.footer-mob-brand-right {
					.footer-mob-socials {
						display: flex;
						width: 178px;
						height: 16.12px;
						align-items: center;
						justify-content: space-between;
						p {
							color: white;
							display: flex;
							align-items: center;
							font-size: 1.2rem;
						}
					}
				}
			}
			.footer-mob-menu{
				display: flex;
				justify-content: space-between;
				.footer-mob-menu-col1{
					display: flex;
					flex-direction: column;
					gap: 11px;
				}
			}
			.footer-mob-column {
				display: flex;
				flex-direction: column;
				gap: 14px;
				> p {
					color: var(--newui-2021-black-grays-white, #fff);
					font-family: Inter;
					font-size: 13px;
					font-style: normal;
					font-weight: 500;
					line-height: 150%; /* 19.5px */
					letter-spacing: 0.52px;
					text-transform: uppercase;
				}
				.footer-mob-sub-column {
					display: grid;
					gap: 16px;
					a {
						text-decoration: none;
						p {
							color: rgba(255, 255, 255, 0.6);
							font-family: Inter;
							font-size: 13px;
							font-style: normal;
							font-weight: 400;
							line-height: 150%; /* 19.5px */
						}
					}
				}
			}
		}
	}
}
