.booking-wrapper {
	display: flex;
	margin: 0 auto;
	max-width: 1260px;
	width: 100vw;
	padding: 0 16px;
	padding-left: 0;
	padding-bottom: 20px;
	@media screen and (max-width: 1000px) {
		padding: 0;
		width: 100%;
	}
	--first-color: hsl(231, 72%, 72%);
	--first-color-alt: hsl(231, 93%, 77%);
	--text-color: hsl(231, 12%, 98%);
	.show-mob-only {
		display: none;
		@media screen and (max-width: 1000px) {
			display: block;
			.ask-help-wrapper {
				margin-top: 0;
				flex-direction: column;
				align-items: center;
				gap: 10px;
				span {
					font-size: 16px;
				}
				.send-msg-btn {
					border: 0 !important;
					border-radius: 4px;
					background: #345088;
					padding: 8px 20px;
					justify-content: space-between;
					align-items: center;
					font-size: 12px;
				}
			}
		}
	}
	.button__loader {
		position: relative;
		overflow: hidden;
		cursor: wait;
		color: transparent;
	}
	.button__loader::after {
		position: absolute;
		content: "Loading...";
		font-family: var(--ff-body);
		font-size: 16px;
		font-style: normal;
		font-weight: 600;
		line-height: normal;
		color: white;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
	.button__loader::before {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		width: 150%;
		height: 100%;
		background: repeating-linear-gradient(
			60deg,
			transparent,
			transparent 0.75rem,
			var(--first-color-alt) 0.75rem,
			var(--first-color-alt) 1.5rem
		);
		animation: load 1s infinite linear;
	}
	@keyframes load {
		0% {
			transform: translateX(0);
		}
		100% {
			transform: translateX(-1.75rem);
		}
	}
	.payment-bottom-bar {
		display: none;
		.terms-condition-wrapper {
			display: flex;
			// flex-direction: column;

			color: var(--text-clr-grey-900);
			font-size: 12px;
			font-style: normal;
			font-weight: 400;
			line-height: 18px;
			gap: 4px;
			align-items: center;
			margin-bottom: 10px;
			margin-top: 10px;
			@media screen and (max-width: 1000px) {
				margin: 0;
			}
			.box {
				display: flex;
				align-items: center;
				justify-content: center;
				cursor: pointer;
				transition: 0.3s linear;
				width: 15px;
				height: 15px;
				flex-shrink: 0;
				border: 1px solid rgba(52, 80, 136, 1);
				margin-right: 12px;
			}
			span {
				display: inline-block;
			}
		}
		.reward-wrapper {
			background: #fff;
			box-shadow: 0px -4px 20px 0px rgba(153, 153, 153, 0.2);
			padding: 16px 0;
			align-items: center;
			justify-content: center;
			color: #555;
			font-family: Inter;
			font-size: 16px;
			font-style: normal;
			font-weight: 400;
			line-height: normal;
			display: flex;
			position: relative;

			.box {
				display: flex;
				align-items: center;
				justify-content: center;
				cursor: pointer;
				transition: 0.3s linear;
				width: 24px;
				height: 24px;
				flex-shrink: 0;
				border: 2px solid rgba(52, 80, 136, 1);
				margin-right: 12px;
			}
			@media screen and (max-width: 1000px) {
				padding: 12px 0;
				width: 100%;
				align-items: center;
			}
		}
		.payment-bottom-wrapper {
			border: 1px solid rgba(245, 200, 114, 0.3);
			background: #f9efde;
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;
			z-index: 9;
			color: #555;
			font-family: Inter;
			font-size: 12px;
			font-style: normal;
			font-weight: 400;
			line-height: 150%; /* 18px */
			padding: 20px;
			padding-top: 8px;
			@media screen and (max-width: 1000px) {
				padding: 0 20px;
				align-items: center;
				justify-content: space-evenly;
				display: flex;
				flex-direction: column;
				height: 100%;
			}
			span:first-child {
				text-align: center;
			}
			button {
				margin-top: 8px;
				width: 100%;
				color: #fff;
				font-family: Inter;
				font-size: 14px;
				font-style: normal;
				font-weight: 500;
				line-height: normal;
				padding: 15px 0;
				border-radius: 4px;
				background: #345088;
				border: 0;
				outline: 0;
				display: flex;
				align-items: center;
				justify-content: center;
				gap: 10px;
				&:disabled {
					opacity: 0.5;
				}
				@media screen and (max-width: 1000px) {
					margin: 0;
				}
			}
		}
		@media screen and (max-width: 1000px) {
			display: flex;
			flex-direction: column;
			position: fixed;
			width: 100%;
			bottom: 0;
			left: 0;
			z-index: 10000;
			height: 110px;
		}
	}
	.booking-left {
		flex: 1.1;
		display: flex;
		flex-direction: column;
		gap: 24px;
		padding: 60px 0;
		padding-right: 80px;
		padding-bottom: 0;
		background-color: white;
		padding-left: 16px;
		@media screen and (max-width: 1000px) {
			flex: 1;
			width: 100%;
			padding: 0;
			gap: 0;
		}
	}
	.booking-right {
		flex: 0.9;
		background: #f9fafb;
		@media screen and (max-width: 1000px) {
			display: none;
		}
	}
	.booking-payment-mob {
		display: none;
		border-bottom: 5px solid rgba(213, 219, 229, 0.5);
		@media screen and (max-width: 1000px) {
			display: block;
			.payment-wrapper {
				padding: 20px;
				padding-bottom: 0;
			}
		}
	}
}
.booking-bottom-wrapper {
	.booking-left {
		flex: 0.6;
	}
}
