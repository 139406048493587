.input__form {
	position: relative;
	width: 100%;

	.input__input {
		position: relative;
		padding: 0 0 0 20px;
		width: 100%;
		border-radius: 4px;
		outline: none;
		border: 1px solid var(--brd-clr-grey-400);
		color: #4b4343;
		font-weight: 500;

		@media screen and (max-width: 500px) {
			padding: 18px;
			font-size: 14px;
		}

		&:focus {
			border: var(--clr-primary) 1px solid;
		}
		&:disabled {
			background-color: #f2f2f2;
		}
	}

	.input__code {
		position: absolute;
		color: var(--text-clr-grey-500);
		border-right: 1px #dddddd solid;
		padding: 0.4rem 0.4rem;
		left: 0.4rem;
		top: 0.6rem;

		font-size: 14px;
		font-style: normal;
		font-weight: 700;
		line-height: 21px;
	}

	.input__label {
		position: absolute;
		left: 0.8rem;
		top: 1rem;
		padding: 0 0.5rem;
		color: var(--text-clr-grey-500);
		cursor: text;
		transition: top 200ms ease-in, left 200ms ease-in, font-size 200ms ease-in;
		background-color: var(--bg-white);
		font-size: 14px;

		@media screen and (max-width: 500px) {
			top: 1rem;
		}
	}
}

.input__input:focus ~ .input__label,
.input__input:not(:placeholder-shown).input__input:not(:focus) ~ .input__label {
	top: -0.3rem;
	font-size: 10px;
	left: 0.8rem;
	color: var(--clr-primary);
}

.input__input[type="checkbox"] {
	top: 0.05rem;
	font-size: 12px;
}

.input__input[type="text"],
.input__input[type="email"],
.input__input[type="tel"],
.input__input[type="url"],
.input__input[type="number"] {
	color: var(--text-clr-grey-900);
	height: 48px;
	font-size: 14px;
	line-height: 21px;
}

/* Chrome, Safari, Edge, Opera */
.input__input[type="number"]::-webkit-outer-spin-button,
.input__input[type="number"]::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Firefox */
.input__input[type="number"] {
	-moz-appearance: textfield;
}

.input__input[type="password"] {
	color: var(--text-clr-grey-900);
	font-weight: bold;
	letter-spacing: 2px;
	font-size: 22px;
	height: 48px;
}
