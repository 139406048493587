.termsAndConditions__terms {
	width: 70%;
	margin: 0 auto;
	font-size: 10px;
	color: var(--bg-clr-grey-600);
	text-align: center;

	a {
		text-decoration: none;
		color: var(--clr-primary);
		cursor: pointer;

		&:hover {
			text-decoration: underline;
			text-underline-offset: 2px;
		}
	}
}