.universalURL_loader-container-page {
	width: 100%;
	max-width: 1216px;
	margin: auto;
	min-height: 100vh;
	background-color: #fefefe;
	margin: auto;
	padding: 0;
}

.universalURL_loader-container-header {
	width: 100%;
	min-height: 10vh;
	margin-bottom: 1.125rem;
	border-radius: 4px;
}

.universalURL_loader-container-image {
	width: 100%;
	min-height: 45vh;
	margin-bottom: 1.125rem;
	display: grid;
	gap: 16px;
	grid-template-columns: 2fr 1fr;
	grid-template-rows: 1fr 1fr;
	grid-template-areas:
		"mainImage secondImage"
		"mainImage thirdImage";
}

.universalURL_loader-img {
	width: 100%;
	height: 100%;
	place-self: stretch stretch;
	border-radius: 4px;
}

.universalURL_loader-container-two-column {
	width: 100%;
	min-height: 45vh;
	display: grid;
	gap: 16px;
	grid-template-columns: 3fr 1fr;
	grid-template-rows: 1fr;
	grid-template-areas: "content navigationPannel";
}

.universalURL_loader-container-content {
	width: 100%;
	grid-area: content;
	border-radius: 4px;
}

.universalURL_loader-container-side-pannel {
	grid-area: navigationPannel;
	border-radius: 4px;
}

.img-main {
	grid-area: mainImage;
}

.img-second {
	grid-area: secondImage;
}

.img-third {
	grid-area: thirdImage;
}

.skeleton-animation {
	animation: skeleton-loading 2s linear infinite alternate;
}

@keyframes skeleton-loading {
	0% {
		background-color: hsl(0, 0%, 95%);
	}

	100% {
		background-color: hsl(0, 0%, 80%);
	}
}