.phone__wrapper-box {
	width: 100%;
	background: white;
	max-width: 500px;
	border-radius: 10px;
	z-index: 1000;

	@media screen and (max-width: 768px) {
		height: auto;
		max-height: 100vh;
	}

	.phone__header-2 {
		padding: 1.5rem 2.5rem;
		padding-bottom: 0;
		border-bottom: #dddddd 1.5px solid;
		width: 100%;

		@media screen and (max-width: 768px) {
			padding: 1rem 1.25rem;
			padding-bottom: 0;
		}

		.phone__header--top {
			display: flex;
			justify-content: space-between;
			margin-bottom: 30px;
			position: relative;

			.phone__header-logo {
				width: 100%;
				position: relative;

				.phone__header-back-btn {
					all: unset;
					position: absolute;
					top: 50%;
					left: 0;
					transform: translate(0, -50%);
				}

				.phone__logo--container {
					width: 100%;
					height: 100%;
					display: flex;
					justify-content: center;
					align-items: center;
				}
			}

			.phone__wrapper-close {
				position: absolute;
				top: 0;
				right: 0;
				cursor: pointer;

				.svg-container {
					cursor: pointer;
				}
			}
		}

		.phone__header-title {
			position: relative;
			display: flex;
			gap: 1rem;

			@media screen and (max-width: 768px) {
				margin-top: 2rem;
			}

			span {
				padding: 0.25rem 1rem;
				cursor: pointer;

				p {
					color: var(--bg-clr-grey-600);
					font-size: 0.875rem;
				}
			}

			.active {
				border-bottom: 3px #2368a2 solid;

				.active-tab {
					color: var(--clr-primary);
				}
			}
		}
	}
}
