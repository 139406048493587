.other__details__container {
	.top {
		display: flex;
		flex-direction: column;
		gap: 24px;
		margin-bottom: 24px;

		.filter__wrapper {
			display: flex;
			flex-direction: column;
			gap: 24px;

			color: #999999;
			font-size: 14px;
			font-weight: 500;
			line-height: 21px;
		}

		.search__villa {
			display: flex;
			flex-direction: column;
			gap: 6px;

			.small__box__container {
				display: flex;
				flex-wrap: wrap;
				gap: 8px;

				.small__box {
					padding: 8px 12px;
					border-radius: 4px;
					font-size: 12px;
					font-weight: 600;
					line-height: 18px;
					background: #edf2ff;
					cursor: pointer;

					svg {
						width: 10px;
						height: 10px;
						margin-left: 8px;
					}
				}
			}

			label {
				color: #999999;
				font-size: 14px;
				font-weight: 500;
				line-height: 21px;

				@media screen and (max-width: 768px) {
				}
			}

			.span {
				position: relative;

				svg {
					top: 20px;
					position: absolute;
					left: 20px;

					@media screen and (max-width: 768px) {
						width: 15px;
						height: 15px;
						top: 16px;
					}
				}

				input {
					width: 100%;
					padding: 17px 26px 17px 52px;
					border-radius: 4px;
					border: 1px solid #dddddd;
					color: #333333;
					font-size: 16px;
					font-weight: 400;
					line-height: 24px;
					letter-spacing: 0em;
					text-align: left;

					@media screen and (max-width: 768px) {
						padding: 13px 15px 13px 43px;
						font-size: 14px;
						font-weight: 500;
						line-height: 21px;
					}
				}
			}
		}

		.fillter__container {
			display: grid;
			grid-template-columns: 1fr 1fr;
			column-gap: 100px;
			row-gap: 12px;

			@media screen and (max-width: 768px) {
				column-gap: 0px;
			}

			.label {
				display: flex;
				align-items: center;
			}

			label {
				width: 180px;
				font-weight: 600;
				font-size: 16px;
				line-height: 150%;
				color: var(--bg-clr-grey-600);

				@media screen and (max-width: 768px) {
					font-size: 12px;
					font-weight: 500;
					line-height: 18px;
				}
			}

			.checkbox {
				width: 20px;
				height: 20px;
				margin-right: 10px;
				cursor: pointer;

				input[type="checkbox"]:checked + .label {
					font-style: italic;
				}
			}
		}
	}

	.bottom {
		display: flex;
		flex-direction: column;
		gap: 30px;

		.range__filter {
			display: flex;
			flex-direction: column;
			gap: 18px;

			h3 {
				color: #333333;
				font-size: 16px;
				font-weight: 500;
				line-height: 24px;
			}
		}
	}

	.message__container {
		display: flex;
		flex-direction: column;
		gap: 24px;
		width: 100%;
		margin-top: 10px;

		.request {
			display: flex;
			flex-direction: column;
			gap: 6px;
			margin-top: 20px;

			label {
				color: #333333;
				font-size: 16px;
				font-weight: 500;
				line-height: 24px;
			}

			textarea {
				width: 100%;
				height: 123px;
				border-radius: 4px;
				border: 1px solid #dddddd;
				padding: 10px 16px;
			}
		}
	}
}
