.sort__popup__container {
	position: absolute;
	border: 1px solid #dddddd;
	background-color: #fff;
	padding: 8px;
	top: 42px;
	right: 0;
	width: 250px;
	border-radius: 4px;
	z-index: 1000;
	-webkit-box-shadow: 4px -2px 42px -17px rgba(0, 0, 0, 0.48);
	-moz-box-shadow: 4px -2px 42px -17px rgba(0, 0, 0, 0.48);
	box-shadow: 4px -2px 42px -17px rgba(0, 0, 0, 0.48);

	.sort__popup__middle {
		display: flex;
		flex-direction: column;
		gap: 8px;

		.text {
			padding: 4px 8px;
			cursor: pointer;
			font-weight: 500;
			font-size: 14px;
		}
	}
}
