.skeleton-card {
	width: 100%;
	max-width: 400px;
	height: 100%;
	min-width: 400px;
	min-height: 450px;

	@media screen and (max-width: 500px) {
		min-width: 292px;
		min-height: 434px;
	}

	.skeleton__top-card {
		background: #eee;
		height: 45%;
		width: 100%;
		border-radius: 4px 4px 0 0;
		animation: placeHolderShimmer 1s linear infinite forwards;
		background: linear-gradient(90deg, #eee 8%, #ddd 18%, #eee 33%);
		background-size: 800px 104px;
		position: relative;
		will-change: transform;

		@keyframes placeHolderShimmer {
			0% {
				-webkit-transform: translateZ(0);
				transform: translateZ(0);
				background-position: -468px 0;
			}
			to {
				-webkit-transform: translateZ(0);
				transform: translateZ(0);
				background-position: 468px 0;
			}
		}
	}

	.skeleton__middle-card {
		height: 40%;

		.skeleton__card-title {
			background: #eee;
			height: 14%;
			width: 80%;
			border-radius: 4px;
			margin-top: 10px;
			background: linear-gradient(90deg, #eee 8%, #ddd 18%, #eee 33%);
			background-size: 800px 104px;
			position: relative;
			animation: placeHolderShimmer 1s linear infinite forwards;
			will-change: transform;

			@keyframes placeHolderShimmer {
				0% {
					-webkit-transform: translateZ(0);
					transform: translateZ(0);
					background-position: -468px 0;
				}
				to {
					-webkit-transform: translateZ(0);
					transform: translateZ(0);
					background-position: 468px 0;
				}
			}
		}
		.skeleton__card-location {
			background: #eee;
			height: 14%;
			width: 50%;
			border-radius: 4px;
			margin-top: 10px;
			background: linear-gradient(90deg, #eee 8%, #ddd 18%, #eee 33%);
			background-size: 800px 104px;
			position: relative;
			animation: placeHolderShimmer 1s linear infinite forwards;
			will-change: transform;

			@keyframes placeHolderShimmer {
				0% {
					-webkit-transform: translateZ(0);
					transform: translateZ(0);
					background-position: -468px 0;
				}
				to {
					-webkit-transform: translateZ(0);
					transform: translateZ(0);
					background-position: 468px 0;
				}
			}
		}

		.skeleton__card-type {
			background: #eee;
			height: 14%;
			width: 30%;
			border-radius: 4px;
			margin-top: 10px;
			background: linear-gradient(90deg, #eee 8%, #ddd 18%, #eee 33%);
			background-size: 800px 104px;
			position: relative;
			animation: placeHolderShimmer 1s linear infinite forwards;
			will-change: transform;

			@keyframes placeHolderShimmer {
				0% {
					-webkit-transform: translateZ(0);
					transform: translateZ(0);
					background-position: -468px 0;
				}
				to {
					-webkit-transform: translateZ(0);
					transform: translateZ(0);
					background-position: 468px 0;
				}
			}
		}
		.skeleton__card-benefits {
			background: #eee;
			height: 14%;
			width: 60%;
			border-radius: 4px;
			margin-top: 10px;
			background: linear-gradient(90deg, #eee 8%, #ddd 18%, #eee 33%);
			background-size: 800px 104px;
			position: relative;
			animation: placeHolderShimmer 1s linear infinite forwards;
			will-change: transform;

			@keyframes placeHolderShimmer {
				0% {
					-webkit-transform: translateZ(0);
					transform: translateZ(0);
					background-position: -468px 0;
				}
				to {
					-webkit-transform: translateZ(0);
					transform: translateZ(0);
					background-position: 468px 0;
				}
			}
		}
		.skeleton__card-reviews {
			background: #eee;
			height: 14%;
			width: 50%;
			border-radius: 4px;
			margin-top: 10px;
			margin-bottom: 10px;
			background: linear-gradient(90deg, #eee 8%, #ddd 18%, #eee 33%);
			background-size: 800px 104px;
			position: relative;
			animation: placeHolderShimmer 1s linear infinite forwards;
			will-change: transform;

			@keyframes placeHolderShimmer {
				0% {
					-webkit-transform: translateZ(0);
					transform: translateZ(0);
					background-position: -468px 0;
				}
				to {
					-webkit-transform: translateZ(0);
					transform: translateZ(0);
					background-position: 468px 0;
				}
			}
		}
	}

	.skeleton__bottom-card {
		background: #eee;
		height: 20%;
		width: 100%;
		border-radius: 0 0 4px 4px;
		background: linear-gradient(90deg, #eee 8%, #ddd 18%, #eee 33%);
		background-size: 800px 104px;
		position: relative;
		animation: placeHolderShimmer 1s linear infinite forwards;
		will-change: transform;

		@keyframes placeHolderShimmer {
			0% {
				-webkit-transform: translateZ(0);
				transform: translateZ(0);
				background-position: -468px 0;
			}
			to {
				-webkit-transform: translateZ(0);
				transform: translateZ(0);
				background-position: 468px 0;
			}
		}
	}
}
