@import "../../../global/style.scss";

.checkbox-container {
	position: relative;

	input {
		-webkit-appearance: none;
		width: 20px;
		height: 20px;
		border: 2px solid var(--brd-clr-grey-400);
		border-radius: 2px;
		vertical-align: sub;
		// position: absolute;
		// top: 50%;
		// transform: translateY(-50%);
		left: 0;
		outline: none;
		transition: all 0.2s;
		cursor: pointer;

		&:checked {
			background-color: var(--bg-primary);
			border: none;
			// &:focus,
			// &:hover {
			// box-shadow: 0 0 0 3px #2368a2;
			// border-color: hsl(0, 0%, 40%);
			// }
		}

		&:checked {
			&:after {
				content: "";
				width: 100%;
				height: 100%;
				position: absolute;
				left: 0;
				top: 0;
				background-image: url("../../../assets/icons/svg/white-tick.svg");
				background-size: 16px;
				background-repeat: no-repeat;
				background-position: center;
			}
		}

		// &:focus,
		// &:hover {
		// box-shadow: 0 0 0 3px hsl(0, 0%, 92%);
		// border-color: hsl(0, 0%, 55%);
		// }
	}

	// label {
	// 	padding: 0.75rem 1rem 0.75rem calc(1.2rem * 2.25);
	// 	display: inline-block;
	// 	font-size: 16px;
	// 	width: 100%;
	// 	user-select: none;
	// 	cursor: pointer;
	// }
}
