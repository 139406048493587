@import "../../../global/style.scss";

.otp {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;

	.otp__container {
		width: 100%;
		max-width: 450px;
		z-index: 1000;
		background-color: var(--bg-white);
		border-radius: 10px;
		padding: 5px;

		.close-icon {
			display: flex;
			padding: 10px;
			width: 100%;
			justify-content: flex-end;

			span {
				width: 20px;
				cursor: pointer;
			}
		}

		.otp__sub-container {
			display: flex;
			flex-direction: column;
			justify-items: flex-start;
			padding: 20px 40px 60px 40px;

			@media screen and (max-width: 500px) {
				justify-content: flex-start;
				padding: 0.625rem 1rem;
				overflow-y: hidden;
			}

			.otp__error {
				color: red;
			}

			div {
				.otp__header {
					display: flex;
					flex-direction: column;
					gap: 10px;

					.otp__header-title {
						padding: 5px;
						text-align: center;

						@media screen and (max-width: 500px) {
							text-align: left;
						}

						p {
							font-size: 22px;
							font-weight: 700;
							color: var(--text-clr-grey-900);
						}
					}

					.otp__header-subtitle {
						padding: 5px;
						text-align: center;
						margin-bottom: 30px;

						@media screen and (max-width: 500px) {
							text-align: left;
						}

						p {
							font-size: 12px;
							font-weight: 500;
							color: var(--bg-clr-grey-600);
							line-height: 20px;

							span {
								margin-left: 5px;
								font-size: 12px;
								font-weight: 600;
								color: var(--text-clr-grey-900);
								line-height: 20px;
								text-decoration: underline;
								cursor: pointer;
							}
						}
					}
				}
			}

			.form__input {
				display: flex;
				justify-content: center;
				gap: 0.8rem;
				margin-bottom: 1.2rem;

				// @media screen and (max-width: 500px) {
				// 	justify-content: flex-start;
				// }

				.otp-box {
					display: flex;
					justify-content: flex-start;
					gap: 1rem;

					.otp-field {
						min-width: 60px;
						height: 48px;
						border-radius: 4px;
						outline: none;
						border: none;
						border: 1px solid var(--brd-clr-grey-400);


						font-weight: 400;
						font-size: 20px;
						text-align: center;

						&:focus-within {
							border: 1px solid var(--brd-clr-primary);
						}
					}
				}

				// .input__input {
				// 	input {
				// 		border-radius: 4px;
				// 		border: 1px #dddddd solid;
				// 		background-color: var(--bg-white);
				// 	}
				// }
			}

			.otp__resend {
				font-size: 12px;
				margin-bottom: 40px;
				text-align: center;
				color: var(--bg-clr-grey-600);

				p {
					span {
						font-size: 12px;
						color: var(--clr-primary);
						text-decoration: underline;
					}
				}
			}
		}
	}
}