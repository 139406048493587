.login__wrapper--page {
	padding: 0 40px;

	@media screen and (max-width: 768px) {
		padding: 0 20px;
	}

	.login__wrapper--page-2 {
		padding: 25px 0 25px 0;

		.login__space {
			height: 10px;

			@media screen and (max-width: 500px) {
				height: 16px;
			}
		}
	}
}

.login__input-hide {
	position: relative;

	.svg-hide {
		position: absolute;
		right: 10px;
		top: 14px;
		margin: auto 0;
		cursor: pointer;
	}
}

.login__line {
	height: 40px;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;

	@media screen and (max-width: 500px) {
		height: 66px;
	}

	hr {
		width: 80%;
		border: 0;
		height: 1px;
		background-color: var(--bg-clr-grey-400);
	}

	span {
		margin: 0 20px;
		color: #999999;
		font-size: 10px;
	}
}

.login__different-login {
	display: flex;
	justify-content: space-between;
	margin-bottom: 5px;
	gap: 12px;

	&>* {
		flex: 1;
	}

	.icon {
		display: flex;
		align-items: center;
		justify-content: center;
		height: 55px;
		border-radius: 4px;
		flex: 1;
		gap: 8px;
		border: 1px solid var(--brd-clr-grey-400);
		cursor: pointer;

		p {
			color: var(--bg-clr-grey-600);

			font-size: 14px;
			font-style: normal;
			font-weight: 500;
			line-height: 21px;

			@media screen and (max-width: 430px) {
				font-size: 12px;
				line-height: 18px;
			}
		}

		@media screen and (max-width: 430px) {
			flex-direction: column;
			height: 66px;
		}
	}
}