.request__submited__container {
	display: flex;
	flex-direction: column;
	gap: 16px;
	align-items: flex-start;
	justify-content: flex-start;
	width: 100%;
	max-width: 600px;
	margin: 0 auto;

	@media screen and (max-width: 768px) {
		max-width: 100%;
		padding: 0 16px;
	}

	h3 {
		color: #2d66a1;
		font-size: 24px;
		font-weight: 700;
		line-height: 36px;
		letter-spacing: 0em;
		text-align: left;

		@media screen and (max-width: 768px) {
			font-size: 16px;
			font-weight: 700;
			line-height: 24px;
		}
	}

	h4 {
		color: #333333;
		font-size: 20px;
		font-weight: 500;
		line-height: 30px;

		@media screen and (max-width: 768px) {
			font-size: 14px;
			font-weight: 500;
			line-height: 21px;
		}
	}

	p {
		color: #333333;
		font-size: 20px;
		font-weight: 500;
		line-height: 30px;

		@media screen and (max-width: 768px) {
			font-size: 14px;
			font-weight: 500;
			line-height: 21px;
		}
	}
}
