.filter__category__wrapper__budget {
	display: flex;
	flex-direction: column;
	gap: 20px;
	margin-top: 30px;
	width: 100%;

	.budget-text {
		margin-bottom: 20px;

		@media screen and (max-width: 1069px) {
			text-align: left;
			color: var(--text-clr-grey-900);
			font-size: 18px;
			margin-top: 10px;
			margin-bottom: 0px;
			font-weight: 700;
		}
	}

	button {
		all: unset;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 4px;
		border: 2px solid var(--brd-clr-primary);
		padding: 8px 0;
		cursor: pointer;

		color: var(--clr-primary);

		&:hover {
			color: var(--clr-white);
			background-color: var(--bg-primary);
		}
	}
}
