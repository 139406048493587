.addons-loader-wrapper {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
	&.sm {
		width: 20px;
		height: 20px;
		border: 3px solid #3498db;
		border-top: 3px solid transparent;
		border-radius: 50%;
		animation: spin 1s linear infinite;
	}
	&.md {
		width: 32px;
		height: 32px;
		border: 4px solid #3498db;
		border-top: 4px solid transparent;
		border-radius: 50%;
		animation: spin 1s linear infinite;
	}
	&.lg {
		width: 60px;
		height: 60px;
		border: 5px solid #3498db;
		border-top: 5px solid transparent;
		border-radius: 50%;
		animation: spin 1s linear infinite;
	}
	@keyframes spin {
		0% {
			transform: rotate(0deg);
		}
		100% {
			transform: rotate(360deg);
		}
	}
}
