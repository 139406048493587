.unsub-wrapper {
	display: flex;
	margin: 10vh auto;
	flex-direction: column;
	align-items: center;
	max-width: 90%;
	gap: 20px;
	.btn-wrapper {
		display: flex;
		gap: 20px;
		margin-top: 20px;
	}
	input {
		padding: 10px;
		border-radius: 4px;
	}
	button {
		display: flex;
		width: 172px;
		height: 48px;
		padding: 10px 26px;
		justify-content: center;
		align-items: center;
		gap: 10px;
		flex-shrink: 0;
		color: #fff;
		text-align: center;
		font-family: Inter;
		font-size: 14px;
		font-style: normal;
		font-weight: 500;
		line-height: 150%; /* 21px */
		border-radius: 6px;
		background: #2d66a1;
	}
}
